import React, { useEffect, Fragment, useState } from 'react'
import { connect } from 'react-redux';
import { Container, Row, Col, Card, Button, Table } from 'react-bootstrap';
import { getCategories, deleteCategoryAssoc, getCategoriesAdmin, onCreateOrUpdatePrice } from '../../Redux/categoryDuck'
import ModalCategoryAssoc from './ModalCategoryAssoc'
import './index.css'
import ItemTableCategory from './item.table.category';
import TemplateMenu from '../../template/menu.template'
import ModalCategoryPrice from './ModalCategoryPrice';
import ModalCategoryGlobal from './ModalCategoryGlobal';

const Categories = ({ getCategories, data, deleteCategoryAssoc, fetching, getCategoriesAdmin, fetching_admin, data_admin, fetching_list,
    onCreateOrUpdatePrice, fetching_price }) => {

    const [showModal, setShowModal] = useState(false);
    const [modal, setModal] = useState(false);
    const [modalCategory, setModalCategory] = useState(false);
    const [listCategory, setListCategory] = useState([]);
    const [listCategoryData, setListCategoryData] = useState([]);
    const [category, setCategory] = useState({});

    useEffect(() => {
        getCategories()
        getCategoriesAdmin()
    }, [])


    useEffect(() => {

        if (fetching_price === 2) {
            setModal(false)
            getCategories()

        }

    }, [fetching_price])


    useEffect(() => {

        if (fetching_admin === 2) {
            setListCategory(data_admin)
        }

    }, [fetching_admin])

    useEffect(() => {

        if (fetching_list === 2) {
            setListCategoryData(data)
        }

    }, [fetching_list])


    useEffect(() => {

        if (fetching === 2) {
            getCategories()
        }

    }, [fetching])

    const deleteCategoryForm = (item) => {

        if (window.confirm("¿Realmente Deseas Eliminar esta Categoria?")) {
            deleteCategoryAssoc(item)
        }
    }

    const editCategory = (item) => {

        setCategory(item)
        setModal(true)
    }

    const onSuccess = () => {
        setShowModal(false);
        getCategories()
    }
    const createUpdatePrice = (form) => {
        let _selected = category
        _selected.category_price = form

        onCreateOrUpdatePrice(_selected)
    }


    return <TemplateMenu fluid>
        <Row className='d-flex justify-content-center my-4'>
            <Col lg={2} md={4}>
                <h4 className="font-title pt-3">CATEGORÍAS</h4>
            </Col>

            {/* <Col lg={2} md={4} className="d-flex align-items-center">
                <button className='btn btn-warning btn-sm' onClick={() => setModalCategory(true)}>Crear Categoria Global</button>
            </Col> */}
            <Col lg={2} md={4} className="d-flex align-items-center">
                <button className='btn btn-info btn-sm' onClick={() => setShowModal(true)}>Asociar</button>
            </Col>
        </Row>
        <Row className='d-flex justify-content-center'>
            <Col lg={8}>

                <Table className="table-sm table-bordered">
                    <thead className='thead-dark'>
                        <tr>
                            <th>Título</th>
                            <th>Descripción</th>
                            <th>Orden</th>
                            <th>Image</th>
                            <th>Precio Parqueadero</th>
                            <th>Tipo</th>
                            <th>Estado</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {(listCategoryData.length) ? listCategoryData.map((row, key) => <ItemTableCategory
                            item={row} key={key} onEdit={() => editCategory(row)} onDelete={deleteCategoryForm}
                        />) : null}
                    </tbody>
                </Table>
            </Col>
        </Row>
        <ModalCategoryAssoc showModal={showModal} onHide={() => setShowModal(false)} onSuccess={() => onSuccess()} listCategory={listCategory} />
        <ModalCategoryPrice show={modal} onHide={() => setModal(false)} createUpdatePrice={createUpdatePrice} category={category} />
        <ModalCategoryGlobal show={modalCategory} />
    </TemplateMenu>
}

const mapsState = (props) => {
    let { category } = props

    return {
        data: category.data,
        fetching: category.fetching_delete,
        fetching_admin: category.fetching_admin,
        fetching_list: category.fetching,
        fetching_price: category.fetching_price,
        data_admin: category.data_admin,
    }
}

export default connect(mapsState, { getCategories, deleteCategoryAssoc, getCategoriesAdmin, onCreateOrUpdatePrice })(Categories);