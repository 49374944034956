import React, { useState } from 'react'
import { Card, Col, Row } from 'react-bootstrap';

const ResolutionAvaiablesPage = ({ formBusiness, getListResolution, resolutionEnable, updateResolution }) => {
    const { ResponseList } = resolutionEnable
    const { NumberRangeResponse } = ResponseList || null

    const [form, setForm] = useState({
        "type_document_id": 1,
        "prefix": "",
        "resolution": "18764088175955",
        "resolution_date": "2025-02-01",
        "technical_key": "75ae18cbc4e2c13cd102a35e311f1e90ebdef50a64313261348c0fbd4e7dcdb2",
        "from": 0,
        "to": 0,
        "generated_to_date": 0,
        "date_from": "2025-02-01",
        "date_to": "2027-02-01"
    })

    const updateResolutionPre = () => {
        let _form = {
            ...form,
            "prefix": NumberRangeResponse.Prefix,
            "resolution": NumberRangeResponse.ResolutionNumber,
            "resolution_date": NumberRangeResponse.ResolutionDate,
            "technical_key": NumberRangeResponse.TechnicalKey,
            "from": NumberRangeResponse.FromNumber,
            "to": NumberRangeResponse.ToNumber,
            "date_from": NumberRangeResponse.ValidDateFrom,
            "date_to": NumberRangeResponse.ValidDateTo,
        }
        setForm(_form);
        updateResolution(_form)
    }

    return (
        <Row className='d-flex justify-content-center'>
            <Col lg={5}>
                <Card>
                    <Card.Header>
                        <Row>
                            <Col><Card.Title>Resolución disponible</Card.Title></Col>
                            <Col className='d-flex justify-content-end'>
                                {formBusiness.api_token !== '' &&
                                    <button className='btn btn-sm btn-info' onClick={getListResolution}>Buscar Disponibles</button>
                                }
                            </Col>
                        </Row>
                    </Card.Header>
                    <Card.Body>

                        <Row>
                            <Col>
                                Estado
                            </Col>
                            <Col>
                                <div className='text-success'>{resolutionEnable.OperationDescription}</div>
                            </Col>
                        </Row>
                        {NumberRangeResponse !== undefined &&
                            <>
                                <Row>
                                    <Col>
                                        Número de Resolución
                                    </Col>
                                    <Col>
                                        {NumberRangeResponse.ResolutionNumber}
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        Fecha de Resolución
                                    </Col>
                                    <Col>
                                        {NumberRangeResponse.ResolutionDate}
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg={6}>
                                        Llave Tecnica
                                    </Col>
                                    <Col lg={6}>
                                        <p>{NumberRangeResponse.TechnicalKey}</p>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        Fecha valida desde
                                    </Col>
                                    <Col>
                                        {NumberRangeResponse.ValidDateFrom}
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        Fecha valida hasta
                                    </Col>
                                    <Col>
                                        {NumberRangeResponse.ValidDateTo}
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        Prefijo
                                    </Col>
                                    <Col>
                                        {NumberRangeResponse.Prefix}
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        Desde
                                    </Col>
                                    <Col>
                                        {NumberRangeResponse.FromNumber}
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        Hasta
                                    </Col>
                                    <Col>
                                        {NumberRangeResponse.ToNumber}
                                    </Col>
                                </Row>
                                <Row className='mt-3'>
                                    <Col>
                                        {formBusiness.api_token !== '' &&
                                            <button className='btn btn-sm btn-success form-control' onClick={() => updateResolutionPre(form)}>Asignar Resolución</button>
                                        }
                                    </Col>
                                </Row>
                            </>}

                    </Card.Body>
                </Card>
            </Col>
        </Row >
    )
}

export default ResolutionAvaiablesPage;