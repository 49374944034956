import React from 'react'
import { Table } from 'react-bootstrap';
import ItemTableFE from './item.table.fe';
import { formatNumber } from '../../utils';

const ServicesFe = ({ listFiltered, handleCheck }) => {
    return (

        <Table className='table table-sm table-bordered'>
            <thead className='thead-dark'>
                <tr>
                    <th>Orden</th>
                    <th>Subtotal</th>
                    <th>Desc</th>
                    <th>Imp</th>
                    <th>Total</th>
                    <th>Marcar</th>
                </tr>
            </thead>
            <tbody>
                {listFiltered.length === 0 ? <tr><td colSpan={6} className='text-center'>Sin Datos</td></tr> :
                    listFiltered.map((a, b) => <ItemTableFE item={a} key={b} onSelectItem={handleCheck} />)}
            </tbody>
            <tfoot>
                <tr>
                    <th colSpan={4}>TOTAL</th>
                    <th className='text-right'>{formatNumber(listFiltered.reduce((a, b) => a + parseFloat(b.total), 0))}</th>
                </tr>
            </tfoot>
        </Table>
    )
}

export default ServicesFe;