import { Col, Row } from "react-bootstrap";
import { formatNumber } from "../../utils";
import moment from "moment";
import { MdClose } from "react-icons/md";

const ItemTable = ({ item, onDeleteItem, index }) => {
    return (
        <tr>
            <td>{index}</td>
            <td>
                <Row>
                    <Col><b>Orden de {item.type}</b></Col>
                    <Col>{item.id}</Col>
                </Row>
            </td>
            <td className='text-center' align="middle">{formatNumber(parseFloat(item.subtotal) + parseFloat(item.total_product))}</td>
            <td className='text-right'>{formatNumber(item.discount)}</td>
            <td className='text-right'>{formatNumber(item.tax)}</td>
            <td className='text-right'>{formatNumber(item.total)}</td>
            <td className='text-right'>
                <MdClose className="cursor-pointer" onClick={() => onDeleteItem(item)} />
            </td>
        </tr>
    )
}

export default ItemTable;