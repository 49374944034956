import React, { useEffect, useState } from 'react'
import { Card, Col, Row, Table } from 'react-bootstrap';
import { connect } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import TemplateMenu from '../../template/menu.template'
import { getPurchaseById, restartPurchase, onEditPurchases } from '../../Redux/purchaseDuck'
import { onCreateInventory } from '../../Redux/inventoryDuck'
import ItemPurchaseEdit from './item.purchase.edit';
import moment from 'moment';
import { formatNumber } from '../../utils';

const EditPurchasePage = ({ getPurchaseById, row, fetching_by_id, onCreateInventory, fetching_create, restartPurchase, onEditPurchases, fetching_create_purchase }) => {
    const { id } = useParams();
    const [data, setData] = useState({ supplier: {} })
    const [list, setList] = useState([])
    const [status, setStatus] = useState(0)
    const history = useHistory();

    useEffect(() => {
        getPurchaseById(id)
    }, [id])


    useEffect(() => {
        if (fetching_by_id === 2) {
            setData(row)
            setList(row.purchase_product)
        }
    }, [fetching_by_id])

    useEffect(() => {
        if (fetching_create === 2) {
            restartPurchase()

        }
    }, [fetching_create])

    useEffect(() => {
        if (fetching_create_purchase === 2) {

            history.goBack()
        }
    }, [fetching_create_purchase])


    const onAddInventory = () => {
        let _data = data
        _data.list_products = list
        onCreateInventory(_data)
    }


    const onUpdateNotify = () => {
        let cont = 0;

        list.map(l => {
            if (parseInt(l.amount) !== parseInt(l.request_amount)) {
                cont++;
            }
        })

        if (cont !== 0) {
            setStatus(2)
        } else {
            setStatus(3)
        }

    }


    return (
        <TemplateMenu>
            <Row className='mt-3'>
                <Col>
                    <button className='btn btn-sm btn-secondary' onClick={() => history.goBack()}>Volver</button>
                </Col>
            </Row>
            <Row className='d-flex justify-content-center'>
                <Col lg={8}>
                    <Row>
                        <Col>
                            <Card>
                                <Card.Header>
                                    <Row>
                                        <Col><h4>COMPRA <b>#{data.id}</b></h4></Col>
                                        <Col className='d-flex justify-content-end'>
                                        </Col>
                                    </Row>

                                </Card.Header>
                                <Card.Body>
                                    <Row>
                                        <Col>
                                            <Row>
                                                <Col>Estado</Col>
                                                <Col>{data.status_text}</Col>
                                            </Row>
                                            <Row>
                                                <Col>Fecha de Registro</Col>
                                                <Col>{moment(data.registration_date).format("YYYY-MM-DD")}</Col>
                                            </Row>
                                            <Row>
                                                <Col>Proveedor</Col>
                                                <Col>{data.supplier.business}</Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>

                    <Row className='mt-2'>
                        <Col>
                            <Card>
                                <Card.Header>
                                    Productos Solicitados {status === 3 ? <span className='text-success'>Entrega Completa</span> : status === 2 && <span className='text-warning'>Entrega Parcial</span>}
                                </Card.Header>
                                <Card.Body>
                                    <Row>
                                        <Col>
                                            <Table className='table-sm table-bordered'>
                                                <thead className='thead-dark'>
                                                    <tr>
                                                        <th>Producto</th>
                                                        <th>Medida</th>
                                                        {/* <th>Costo</th> */}
                                                        <th>Cantidad Solicitada</th>
                                                        <th>Cantidad Recibida</th>
                                                        <th>Costo Unitario</th>
                                                        <th>Subtotal</th>
                                                        <th></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {list.map(p => <ItemPurchaseEdit item={p} key={p.id} onUpdateNotify={onUpdateNotify} />)}
                                                </tbody>
                                                <tfoot>
                                                    <tr>
                                                        <td colSpan={2}>TOTAL</td>
                                                        <td align='right'>{list.reduce((a, b) => a + parseFloat(b.request_amount), 0)}</td>
                                                        <td align='right'>{list.reduce((a, b) => a + parseFloat(b.amount), 0)}</td>
                                                        <td align='right'></td>
                                                        <td align='right'>{formatNumber(list.reduce((a, b) => a + parseFloat(b.amount) * parseFloat(b.cost), 0))}</td>
                                                    </tr>
                                                </tfoot>
                                            </Table>
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>

                </Col>
            </Row>
        </TemplateMenu>
    )
}

const mapsState = (props) => {
    let { purchase, inventory } = props
    return {
        row: purchase.row,
        fetching_by_id: purchase.fetching_by_id,
        fetching_create_purchase: purchase.fetching_create,
        fetching_create: inventory.fetching_create,
    }
}

export default connect(mapsState, { getPurchaseById, onCreateInventory, restartPurchase, onEditPurchases })(EditPurchasePage);