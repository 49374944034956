import React, { useState, useEffect } from 'react'
import { Modal, Container, Row, Col, Button, Form, Card } from 'react-bootstrap';
import InputField from '../Components/Widgets/InputField';
import { connect } from 'react-redux';
import { createCategoryProduct, onUpdateCategoryProduct } from '../../Redux/categoryProductDuck'

const initState = { name: '', priority: '', status: true, type_category: 'STORE' };

const ModalCategoryProduct = ({ show, onHide, onSuccess, errors, fetching_create, createCategoryProduct, formEdit,
    onUpdateCategoryProduct, size }) => {

    initState.priority = size
    const [form, setForm] = useState(initState)
    const [image, setImage] = useState('')

    useEffect(() => {
        if (Object.keys(formEdit).length > 0) {
            setForm(formEdit)
        }

    }, [formEdit])

    useEffect(() => {
        if (fetching_create === 2) {
            setForm(initState)
            onSuccess()
        }
    }, [fetching_create])


    const updateInput = (e) => {

        setForm({
            ...form,
            [e.target.name]: e.target.value
        })
    }

    const updateCheck = (e) => {

        setForm({
            ...form,
            [e.target.name]: e.target.checked
        })
    }

    const onSubmit = () => {
        let _form = form
        _form.image = image;

        if (form.id !== undefined) {
            onUpdateCategoryProduct(form)
        } else {
            createCategoryProduct(_form)
        }

    }

    const inputFile = (e) => {

        let file = e.target.files[0];
        setImage(file);

        let reader = new FileReader();
        reader.onload = () => {
            if (reader.readyState === 2) {
                setForm({ ...form, photo: reader.result })
            }
        }
        reader.readAsDataURL(file)
    }

    const preHide = () => {
        setForm(initState)
        onHide();
    }

    const updateTypeCategory = (type) => {
        console.log('type', type);

        setForm({
            ...form,
            "type_category": type
        })
    }

    return (
        <Modal centered show={show} onHide={preHide} size='md'>
            <Modal.Header closeButton>
                <Modal.Title>Categoria</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Container fluid>

                    <Row>
                        <Col>
                            <Row>
                                <Col>
                                    <div className="form-group">
                                        <label>Titulo Categoría</label>
                                        <InputField
                                            placeholder="Titulo de la Categoría"
                                            value={form.name || ''}
                                            name="name"
                                            onChange={updateInput}
                                            error={errors.name}
                                        />
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <div className="form-group">
                                        <label>Orden</label>
                                        <InputField
                                            type="number"
                                            placeholder="Orden"
                                            value={form.priority || ''}
                                            name="priority"
                                            onChange={updateInput}
                                            error={errors.priority}
                                        />
                                    </div>
                                </Col>
                                <Col>
                                    <div className="form-group">
                                        <label>Estado</label>
                                        <input
                                            type="checkbox"
                                            className="form-control"
                                            checked={form.status || false}
                                            name="status"
                                            onChange={updateCheck}
                                        />
                                    </div>
                                </Col>

                            </Row>
                            <Row>
                                <Col>
                                    <Card className={form.type_category === 'STORE' ? 'bg-success' : ''}
                                        onClick={() => updateTypeCategory("STORE")}>
                                        <Card.Body className='cursor-pointer'>
                                            <Card.Title className='text-center'>Tienda</Card.Title>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col>
                                    <Card className={form.type_category === 'REPLACEMENT' ? 'bg-success' : ''}
                                        onClick={() => updateTypeCategory("REPLACEMENT")}>
                                        <Card.Body className='cursor-pointer'>
                                            <Card.Title className='text-center'>Repuesto</Card.Title>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row>
                            {/* <Row>
                                <Col>
                                    <Row>

                                        <Col>
                                            <div className="form-group">
                                                <label>Foto</label>
                                                <input
                                                    type="file"
                                                    className="form-control"
                                                    checked={form.photo || false}
                                                    name="photo"
                                                    onChange={inputFile}
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className="justify-content-center text-center">
                                            <div className="form-group">
                                                <label htmlFor="chooseimage" className="uploadfile">
                                                    {form.photo !== '' ? (<img src={form.photo} className="img-fluid" />) : <img src={chooseimage} />}

                                                </label>
                                                <input type="file" name="photo" className="form-control" onChange={inputFile} id="chooseimage" />
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>

                            </Row> */}
                        </Col>
                    </Row>

                </Container>
            </Modal.Body>
            <Modal.Footer className="justify-content-center">
                <Row>
                    <Col>
                        <button className='btn btn-sm btn-danger' onClick={preHide}>Cerrar</button>
                    </Col>
                    <Col>
                        <button className='btn btn-sm btn-success' onClick={onSubmit}>Guardar</button>
                    </Col>
                </Row>
            </Modal.Footer>
        </Modal>
    )
}

const mapsState = (props) => {
    let { category_product } = props
    return {
        errors: category_product.errors,
        fetching_create: category_product.fetching_create,
        fetching_list: category_product.fetching_list
    }
}

export default connect(mapsState, { createCategoryProduct, onUpdateCategoryProduct })(ModalCategoryProduct);