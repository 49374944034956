import React from 'react'
import { Card, Col, Row } from 'react-bootstrap';

const EnvironmentInfoPage = ({ formBusiness, storeEnvironment, formEnvironment, updateInputEnvironment, listTypeEnvironment }) => {
    return (
        <Row className='d-flex justify-content-center'>
            <Col lg={4}>
                <Card>
                    <Card.Header>
                        <Row>
                            <Col><Card.Title>Cambio de Entorno</Card.Title></Col>
                            <Col className='d-flex justify-content-end'>
                                {formBusiness.api_token !== '' &&
                                    <button className='btn btn-sm btn-success' onClick={storeEnvironment}>Guardar</button>
                                }
                            </Col>
                        </Row>
                    </Card.Header>
                    <Card.Body>
                        <Row>
                            <Col>
                                <div className="form-group">
                                    <label>Tipo Entorno</label>
                                    <select className='form-control' value={formEnvironment.type_environment_id}
                                        name='type_environment_id' onChange={updateInputEnvironment}>
                                        <option value="0">Seleccione</option>
                                        {listTypeEnvironment.map(d => <option value={d.id} key={d.id}>{d.name}</option>)}
                                    </select>
                                </div>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
            </Col>
        </Row>
    )
}

export default EnvironmentInfoPage;