import React from 'react'
import { Col, Modal, Row } from 'react-bootstrap';

const ModalPlate = ({ show, plate }) => {
    return (
        <Modal show={show}>
            <Modal.Body>
                <Modal.Title>Finalizado</Modal.Title>
                <Row>
                    <Col className='text-center'>
                        <span className='text-plate'>{plate}</span>
                    </Col>
                </Row>
            </Modal.Body>
        </Modal>

    )
}

export default ModalPlate;