import React, { useEffect, useState } from 'react'
import { Col, Form, Modal, Row } from 'react-bootstrap';
import InputField from '../Components/Widgets/InputField';

const ModalParkingPrice = ({ show, onHide, onCreate, onEdit, listType, formEdit, list_current }) => {

    const [form, setForm] = useState({
        category_id: '', price: '', parking_type_id: 0, is_recurrent: false, minutes: '',
        price_minute: '', price_type: false, additional_price_id: 0
    })

    useEffect(() => {
        setForm(formEdit)
    }, [formEdit])

    const updateInput = (e) => {
        let _form = {
            ...form,
            [e.target.name]: e.target.type === 'checkbox' ? e.target.checked : e.target.value
        }

        if (e.target.name === 'price') {
            let parking_type = listType.find(d => d.id === parseInt(form.parking_type_id))

            let price_number = e.target.value

            if (parking_type) {
                price_number = price_number / parking_type.minutes
            }

            _form["price_minute"] = price_number;
        }

        setForm(_form)
    }

    const onSubmit = () => {
        if (form.id === undefined) {
            onCreate(form)
        } else {
            onEdit(form)
        }
    }

    console.log('list_current', list_current);


    return (
        <Modal show={show} onHide={onHide}>
            <Modal.Header closeButton>
                <Modal.Title>Precio</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row className='my-2'>
                    <Col>
                        <div className="form-group">
                            <label>TIPO DE TARIFA</label>
                            <select className='form-control' name='parking_type_id' value={form.parking_type_id}
                                onChange={updateInput}>
                                <option value={0}>Seleccione</option>
                                {listType.map((a, b) => <option value={a.id} key={b}>{a.description}</option>)}
                            </select>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div className="form-group">
                            <label>Precio</label>
                            <InputField
                                type="number"
                                placeholder="Precio"
                                value={form.price}
                                name="price"
                                onChange={updateInput}
                            // error={errors.price}
                            />
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div className="form-group">
                            <label>Precio por Minuto</label>
                            <InputField
                                type="number"
                                placeholder="Precio por minuto"
                                value={form.price_minute}
                                name="price_minute"
                                readOnly={true}
                                onChange={updateInput}
                            // error={errors.price}
                            />
                        </div>
                    </Col>
                    <Col>
                        <div className="form-group">
                            <label>¿Es recurrente?</label>
                            <input type='checkbox' name="is_recurrent" className='form-control'
                                checked={form.is_recurrent}
                                onChange={updateInput} />
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div className="form-group">
                            <label>Cobrar </label>
                            <Form.Check
                                type="switch"
                                id="price_type"
                                checked={form.price_type}
                                className='form-control'
                                name="price_type"
                                label={`${form.price_type ? 'por fragmento' : 'Tarifa Completa'}`}
                                onChange={updateInput}
                            />
                        </div>
                    </Col>
                    <Col>
                        <div className="form-group">
                            <label>Cobro Adicional </label>
                            <select className='form-control' name='additional_price_id' value={form.additional_price_id}
                                onChange={updateInput}>
                                <option value={0}>Seleccione</option>
                                {list_current.map((a, b) => <option value={a.parking_type.id} key={b}>{a.parking_type.description}</option>)}
                            </select>
                        </div>
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <Row>
                    <Col><button className='btn btn-sm btn-success' onClick={onSubmit}>Guardar</button></Col>
                </Row>
            </Modal.Footer>
        </Modal>
    )
}

export default ModalParkingPrice;