import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux';
import TemplateMenu from '../../template/menu.template'
import { getPermissionByUser } from '../../Redux/userDuck'
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { Col, Row } from 'react-bootstrap';

const UserPermissionPage = ({ getPermissionByUser, fetching_permission, permissions }) => {

    let { id } = useParams()
    const [list, setList] = useState([])

    useEffect(() => {
        getPermissionByUser(id)
    }, [id])

    useEffect(() => {
        if (fetching_permission === 2) {
            setList(permissions)
        }
    }, [fetching_permission])

    return (
        <TemplateMenu>
            <Row>
                <Col>
                    <Row>
                        <Col>Menú</Col>
                    </Row>
                    <Row>
                        <Col>
                            <ul className='list-unstyled'>
                                {list.filter(d => d.module === 1 && d.parent_id === 0).map((d, key) =>
                                    <li key={key}>
                                        <Row>
                                            <Col className='d-flex align-items-center'>
                                                <input type='checkbox' className='mx-2' checked={d.user_id === null ? false : true} /> {d.title}
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col>
                                                <ul className='list-unstyled ml-5'>
                                                    {list.filter(node => node.parent_id === d.id).map((row, k) => <li key={k}>
                                                        <Row>
                                                            <Col className='d-flex align-items-center'>
                                                                <input type='checkbox' className='mx-2' checked={row.user_id === null ? false : true} /> {row.title}
                                                            </Col>
                                                        </Row>
                                                    </li>)}
                                                </ul>
                                            </Col>
                                        </Row>
                                    </li>
                                )}
                            </ul>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </TemplateMenu>
    )
}

const mapsState = (props) => {
    let { user } = props
    return {
        fetching_permission: user.fetching_permission,
        permissions: user.permissions,
    }
}
export default connect(mapsState, { getPermissionByUser })(UserPermissionPage);