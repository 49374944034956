import React, { useEffect, useState } from 'react'
import { Col, Row, Table } from 'react-bootstrap';
import { connect } from 'react-redux';
import TemplateMenu from '../../template/menu.template'
import { getTurns, onCloseTurn, onReOpenTurn, getTurnActive } from '../../Redux/turnDuck'
import ItemTableTurn from './item.table.turn';
import ModalPhotoTurnPage from './moda.photo.turn';
import ModalCreateTurn from './create.turn';


const TurnPage = ({ getTurns, fetching_list, data, onCloseTurn, onReOpenTurn, fetching_close, getTurnActive }) => {

    const [list, setList] = useState([])
    const [show, setShow] = useState(false)
    const [image, setImage] = useState('')
    const [modal, setModal] = useState(false)
    const [hasTurn, setHasTurn] = useState(false)

    useEffect(() => {
        getTurns()
    }, [])

    useEffect(() => {
        if (fetching_close === 2) {
            getTurns()
            getTurnActive()
        }
    }, [fetching_close])



    useEffect(() => {
        if (fetching_list === 2) {
            let _data = data.filter(d => d.status_id === 1)
            setHasTurn(_data.length === 0 ? true : false)
            setList(data)
        }
    }, [fetching_list])

    const onFinishTurnForm = (item) => {
        if (window.confirm("¿Deseas Finalizar el Turno?")) {
            onCloseTurn(item.id)
        }
    }
    const onReOpenTurnForm = (item) => {
        if (window.confirm("¿Deseas ReAbrir el Turno?")) {
            onReOpenTurn(item.id)
        }
    }

    const onSuccess = () => {
        setModal(false)
        getTurns()
    }

    const onShowPhotos = (img) => {
        setImage(img)
        setShow(true)
    }

    return (
        <TemplateMenu>
            <Row className='mt-2'>
                <Col lg={2}>Registro de Turnos</Col>
                <Col>
                    {hasTurn && <button className='btn btn-sm btn-info' onClick={() => setModal(true)}>Abrir Turno</button>}

                </Col>
            </Row>
            <Row className='mt-3'>
                <Col>
                    <Table className='table table-sm table-bordered'>
                        <thead className='thead-dark'>
                            <tr>
                                <th>Usuario</th>
                                <th>Fecha de Registro</th>
                                <th>Fecha Fin</th>
                                <th>Monto Apertura</th>
                                <th>Porcentaje Ajustado</th>
                                <th>Screen</th>
                                <th>Estado</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {list.map((d, k) => <ItemTableTurn key={k} item={d}
                                onFinishTurn={() => onFinishTurnForm(d)}
                                onReOpenTurn={() => onReOpenTurnForm(d)}

                                onShowPhotos={onShowPhotos} />)}
                        </tbody>
                    </Table>
                </Col>
            </Row>

            <ModalPhotoTurnPage show={show} image={image} onHide={() => setShow(false)} />
            <ModalCreateTurn show={modal} onHide={() => setModal(false)} onSucces={onSuccess} />
        </TemplateMenu>
    )
}

const mapsState = (props) => {
    let { turn } = props
    return {
        fetching_list: turn.fetching_list,
        fetching_close: turn.fetching_close,
        fetching_reopen: turn.fetching_reopen,
        data: turn.data
    }
}


export default connect(mapsState, { getTurns, onCloseTurn, onReOpenTurn, getTurnActive })(TurnPage);