import React, { useEffect, useRef, useState } from 'react'
import TemplateMenu from '../../../template/menu.template'
import { connect } from 'react-redux';
import { Col, Row, Tab, Table, Tabs } from 'react-bootstrap';
import { formatNumber } from '../../../utils';
import { DownloadTableExcel } from 'react-export-table-to-excel';
import { RiFileExcel2Fill } from 'react-icons/ri';
import { toogleAlert } from '../../../Redux/alertDuck'
import { getTurns } from '../../../Redux/turnDuck';
import { getReviewExpense } from '../../../Redux/reportDuck'

import moment from 'moment';
import ReviewExpenseList from './review.expense.list';
import ReviewTotalExpense from './review.total.expense';
import ReviewPayrollList from './review.payroll.list';

const ReviewExpensePage = ({ toogleAlert, getTurns, fetching_list_turn, data_turn, getReviewExpense, fetching, data }) => {
    const table = useRef(null)
    const [listData, setLisData] = useState([]);
    const [listDataPayroll, setLisDataPayroll] = useState([]);
    const [listTurn, setListTurn] = useState([]);
    const [totalExpense, setTotalExpense] = useState(0);
    const [totalPayroll, setTotalPayroll] = useState(0);
    const [form, setForm] = useState({ turn_id: 0, date_init: moment().format("YYYY-MM-01"), date_end: moment().format("YYYY-MM-DD") })
    const [graph, setGraph] = useState({
        labels: [],
        datasets: [
            {
                data: [],
                backgroundColor: []
            }
        ]
    });
    useEffect(() => {
        toogleAlert(true)
        let _form = form
        let { date_init, date_end } = JSON.parse(localStorage.getItem("date"))
        _form.date_init = date_init
        _form.date_end = date_end
        setForm(_form)
        getTurns()
    }, [])

    useEffect(() => {
        if (fetching_list_turn === 2) {
            setListTurn(data_turn)
            toogleAlert(false)
        }
    }, [fetching_list_turn])

    useEffect(() => {
        if (fetching === 2) {
            let labels = ["GASTOS OPERATIVOS", "SALARIOS"], data_graph = [data.total_expense, data.total_payroll]
            setTotalExpense(data.total_expense)
            setTotalPayroll(data.total_payroll)
            setGraph(
                {
                    labels,
                    datasets: [
                        {
                            label: 'CANTIDAD PRODUCTOS',
                            data: data_graph,
                            backgroundColor: [
                                'rgba(75, 192, 192, 0.2)',
                                'rgba(54, 162, 235, 0.2)',
                                'rgba(255, 206, 86, 0.2)',
                                'rgba(255, 99, 132, 0.2)',
                            ],
                            borderColor: [
                                'rgba(75, 192, 192, 1)',
                                'rgba(54, 162, 235, 1)',
                                'rgba(255, 206, 86, 1)',
                                'rgba(255, 99, 132, 1)',
                            ],
                            borderWidth: 1,
                        }
                    ],
                });
            setLisData(data.results)
            setLisDataPayroll(data.results_payroll)
            toogleAlert(false)
        }
    }, [fetching])

    const updateField = (e) => {
        setForm({
            ...form,
            [e.target.name]: e.target.value
        })
    }


    const onSearch = () => {
        toogleAlert(true)
        getReviewExpense(form)
    }

    return (
        <TemplateMenu>
            <Row className='mt-3'>
                <Col lg={3}>
                    <div className="form-group">
                        <label>Turnos</label>
                        <select className='form-control' name='turn_id' value={form.turn_id} onChange={updateField}>
                            <option value={0}>POR RANGO DE FECHA</option>
                            {listTurn.map((d, k) => <option key={k} value={d.id}>{d.user.name} - ({d.id}) | {d.date_start} | {d.date_end}</option>)}
                        </select>
                    </div>
                </Col>
                {parseInt(form.turn_id) === 0 && <>
                    <Col lg={2}>
                        <div className="form-group">
                            <label>Fecha de Inicio</label>
                            <input
                                type="date"
                                className="form-control"
                                value={form.date_init}
                                name="date_init"
                                onChange={updateField}
                            />
                        </div>
                    </Col>
                    <Col lg={2}>
                        <div className="form-group">
                            <label>Fecha Fin</label>
                            <input
                                type="date"
                                className="form-control"
                                value={form.date_end}
                                name="date_end"
                                onChange={updateField}
                            />
                        </div>
                    </Col>
                </>}
                <Col lg={2} className='d-flex align-items-center'>
                    <button className='btn btn-sm btn-info' onClick={onSearch}>Buscar</button>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Tabs defaultActiveKey="review" id="uncontrolled-tab-example">
                        <Tab eventKey="review" title="Totales">
                            <ReviewTotalExpense graph={graph} total_expense={totalExpense} total_payroll={totalPayroll} />
                        </Tab>
                        <Tab eventKey="list" title="Listado gastos operativos">
                            <ReviewExpenseList listData={listData} />
                        </Tab>
                        <Tab eventKey="list_payroll" title="Listado Nomina">
                            <ReviewPayrollList listData={listDataPayroll} />
                        </Tab>
                    </Tabs>

                </Col>
            </Row>
        </TemplateMenu>

    )
}

const mapsState = (props) => {
    let { turn, report } = props

    return {
        fetching_list_turn: turn.fetching_list,
        data_turn: turn.data,
        fetching: report.fetching_bank,
        data: report.data_bank,
    }
}
export default connect(mapsState, { toogleAlert, getTurns, getReviewExpense })(ReviewExpensePage);