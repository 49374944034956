import React from 'react'
import { Card, Col, Row } from 'react-bootstrap';
import InputField from '../../Components/Widgets/InputField';

const ResolutionNCPage = ({ formBusiness, storeResolutionNC, resolutionNC, listTypeDocument, updateInputResolutionNC
}) => {
    return (
        <Row className='d-flex justify-content-center'>
            <Col lg={4}>
                <Card>
                    <Card.Header>
                        <Row>
                            <Col><Card.Title>Resolución</Card.Title></Col>
                            <Col className='d-flex justify-content-end'>
                                {formBusiness.api_token !== '' &&
                                    <button className='btn btn-sm btn-success' onClick={storeResolutionNC}>Guardar</button>
                                }
                            </Col>
                        </Row>
                    </Card.Header>
                    <Card.Body>
                        <Row>
                            <Col>
                                <div className="form-group">
                                    <label>Tipo de Documento</label>
                                    <select className='form-control'
                                        name='type_document_id'
                                        value={resolutionNC.type_document_id}
                                        onChange={updateInputResolutionNC}>
                                        <option value={0}>Seleccione</option>
                                        {listTypeDocument.map((a, b) => <option key={a.id} value={a.id}>{a.name}</option>)}
                                    </select>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <div className="form-group">
                                    <label>Prefijo</label>
                                    <InputField
                                        placeholder="Prefijo"
                                        value={resolutionNC.prefix || ''}
                                        name="prefix"
                                        onChange={updateInputResolutionNC}
                                    />
                                </div>
                            </Col>
                            <Col>
                                <div className="form-group">
                                    <label>Desde</label>
                                    <InputField
                                        type={"number"}
                                        placeholder="Desde"
                                        value={resolutionNC.from || ''}
                                        name="from"
                                        onChange={updateInputResolutionNC}
                                    />
                                </div>
                            </Col>
                            <Col>
                                <div className="form-group">
                                    <label>Hasta</label>
                                    <InputField
                                        type={"number"}
                                        placeholder="Hasta"
                                        value={resolutionNC.to || ''}
                                        name="to"
                                        onChange={updateInputResolutionNC}
                                    />
                                </div>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
            </Col>
        </Row>
    )
}

export default ResolutionNCPage;