import React, { useEffect } from 'react'
import { Card, Col, Container, Row } from 'react-bootstrap';
import { formatNumber } from '../../../utils';
import { Bar } from 'react-chartjs-2';


const options = {
    plugins: {
        legend: {
            display: true, // Muestra la leyenda
            position: 'top'
        },
        datalabels: {
            formatter: (value, context) => {
                const total = context.dataset.data.reduce((acc, val) => acc + val, 0);
                const percentage = ((value / total) * 100).toFixed(1) + '%';
                return percentage; // Muestra el porcentaje en cada sector
            },
            color: '#5b5b5c', // Color del texto
            font: {
                size: 16,
                weight: 'bold'
            }
        }
    }
};

const ReviewProductTotal = ({ total_cart, total_additional, total_inputs, quantity_cart,
    quantity_additional, quantity_input, graph, graphTotal }) => {

    return (
        <Container fluid>
            <Row className='mt-2'>
                <Col lg={4}>
                    <Row>
                        <Col>
                            {graph.labels.length > 0 ? (
                                <Bar data={graph} options={options} />
                            ) : (
                                <p>Cargando datos...</p>
                            )}
                        </Col>
                    </Row>
                    {/* <Row>
                        <Col>
                            {graphTotal.labels.length > 0 ? (
                                <Bar data={graphTotal} />
                            ) : (
                                <p>Cargando datos...</p>
                            )}
                        </Col>
                    </Row> */}

                </Col>
                <Col>
                    <Row>
                        <Col>
                            <Card>
                                <Card.Body>
                                    <Card.Title>TOTAL TIENDA</Card.Title>
                                    <Row>
                                        <Col>
                                            <Card>
                                                <Card.Body>
                                                    <Card.Title>CANTIDAD</Card.Title>
                                                    <Row>
                                                        <Col>{quantity_cart}</Col>
                                                    </Row>
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                        <Col>
                                            <Card>
                                                <Card.Body>
                                                    <Card.Title>TOTAL</Card.Title>
                                                    <Row>
                                                        <Col className='text-right'>{formatNumber(total_cart)}</Col>
                                                    </Row>
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                    <Row className='my-3'>
                        <Col>
                            <Card>
                                <Card.Body>
                                    <Card.Title>TOTAL ADICIONALES</Card.Title>
                                    <Row>
                                        <Col>
                                            <Card>
                                                <Card.Body>
                                                    <Card.Title>CANTIDAD</Card.Title>
                                                    <Row>
                                                        <Col>{quantity_additional}</Col>
                                                    </Row>
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                        <Col>
                                            <Card>
                                                <Card.Body>
                                                    <Card.Title>TOTAL</Card.Title>
                                                    <Row>
                                                        <Col className='text-right'>{formatNumber(total_additional)}</Col>
                                                    </Row>
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Card>
                                <Card.Body>
                                    <Card.Title>TOTAL INSUMOS</Card.Title>
                                    <Row>
                                        <Col>
                                            <Card>
                                                <Card.Body>
                                                    <Card.Title>CANTIDAD</Card.Title>
                                                    <Row>
                                                        <Col>{quantity_input}</Col>
                                                    </Row>
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                        <Col>
                                            <Card>
                                                <Card.Body>
                                                    <Card.Title>TOTAL</Card.Title>
                                                    <Row>
                                                        <Col className='text-right'>{formatNumber(total_inputs)}</Col>
                                                    </Row>
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Container>
    )
}

export default ReviewProductTotal;