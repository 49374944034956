import React from 'react'
import { Col, Row, Table } from 'react-bootstrap';

const ListServiceFE = ({ listServices, onChange }) => {
    return (
        <Row>
            <Col>
                <Table className='table table-sm table-bordered'>
                    <thead className='thead-dark'>
                        <tr>
                            <th>Categoria</th>
                            <th>Servicio</th>
                            <th>Precio</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {listServices.map((a, b) => {
                            let { service_price } = a
                            return service_price.map((value, k) =>
                                <tr key={k}>
                                    <td>{value.category.title}</td>
                                    <td>{a.name}</td>
                                    <td align='right'>{value.price_formated}</td>
                                    <td>
                                        <input type='checkbox' className='form-control' onChange={(e) => onChange(e, a, value)} />
                                    </td>
                                </tr>)
                        })}

                    </tbody>
                </Table>
            </Col>
        </Row>

    )
}

export default ListServiceFE;