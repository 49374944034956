import React, { useState } from 'react'
import { Col, Container, Modal, Row, Table } from 'react-bootstrap';
import InputField from '../Components/Widgets/InputField';
import moment from 'moment';

const ModalExpense = ({ title, show, onSuccess, listExpense, onHide, errors, listPayment, list_operators, listTurn }) => {

    let { date_init, date_end } = JSON.parse(localStorage.getItem("date"))
    const [form, setForm] = useState({
        registration_date: moment().format("YYYY-MM-DD"), parameter_id: 0, description: '',
        value: '', worker_id: 0, cashier_id: 0, method_payment_id: 0
    })
    const [showWorker, setShowWorker] = useState(false)

    const updateInput = (e) => {
        setForm({
            ...form,
            [e.target.name]: e.target.value
        })

        if (e.target.name === 'parameter_id') {
            setShowWorker(parseInt(e.target.value) === 48 ? true : false)
        }
    }

    const onSuccessPre = () => {
        let _form = form
        onSuccess(_form)
    }

    return (
        <Modal show={show} onHide={onHide}>
            <Modal.Header closeButton>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Container>
                    <Row>
                        <Col>
                            <div className="form-group">
                                <label>Turno</label>
                                <select className='form-control' name='cashier_id' value={form.cashier_id} onChange={updateInput}>
                                    <option value={0}>Seleccione un Turno</option>
                                    {listTurn.map((d, k) => <option key={k} value={d.id}>{d.date_start} ({d.user.name}) {`-> ${d.status_text}`}</option>)}
                                </select>
                                {errors.cashier_id !== undefined && (<label className="error-label">{errors.cashier_id}</label>)}

                            </div>
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            <div className="form-group">
                                <label>Fecha</label>
                                <InputField
                                    type="date"
                                    value={form.registration_date}
                                    name="registration_date"
                                    min={date_init}
                                    max={date_end}
                                    onChange={updateInput}
                                    error={errors.registration_date}
                                />
                            </div>
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            <div className="form-group">
                                <label>Concepto</label>
                                <select className='form-control' name="parameter_id" onChange={updateInput}>
                                    <option value={0}>Seleccione</option>
                                    {listExpense.map((v, k) => <option value={v.id} key={k}>{v.description}</option>)}
                                </select>
                                {errors.parameter_id !== undefined && (<label className="error-label">{errors.parameter_id}</label>)}
                            </div>
                        </Col>
                    </Row>
                    {showWorker &&
                        <Row>
                            <Col>
                                <div className="form-group">
                                    <label>Trabajador</label>
                                    <select className='form-control' name="worker_id" onChange={updateInput}>
                                        <option value={0}>Seleccione</option>
                                        {list_operators.map((v, k) => <option value={v.id} key={k}>{v.name}</option>)}
                                    </select>
                                    {errors.worker_id !== undefined && (<label className="error-label">{errors.worker_id}</label>)}
                                </div>
                            </Col>
                        </Row>}

                    <Row>
                        <Col>
                            <div className="form-group">
                                <label>Descripción</label>
                                <InputField
                                    placeholder={"Descripción"}
                                    value={form.description}
                                    name="description"
                                    onChange={updateInput}
                                    error={errors.description}
                                />
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <div className="form-group">
                                <label>Método de Pago</label>
                                <select className='form-control' name="method_payment_id" onChange={updateInput}>
                                    <option value={0}>Seleccione</option>
                                    {listPayment.map((v, k) => <option value={v.id} key={k}>{v.description}</option>)}
                                </select>
                                {errors.method_payment_id !== undefined && (<label className="error-label">{errors.method_payment_id}</label>)}

                            </div>
                        </Col>
                        <Col>
                            <div className="form-group">
                                <label>Valor</label>
                                <InputField
                                    type={"number"}
                                    placeholder={"Valor"}
                                    value={form.value}
                                    name="value"
                                    onChange={updateInput}
                                    error={errors.value}
                                />
                            </div>
                        </Col>
                    </Row>
                </Container>

            </Modal.Body>
            <Modal.Footer>
                <Container>
                    <Row>
                        <Col>
                            <button className='btn btn-sm btn-success form-control' onClick={onSuccessPre}>Guardar Gasto</button>
                        </Col>

                    </Row>
                </Container>
            </Modal.Footer>
        </Modal>
    )
}


export default ModalExpense;