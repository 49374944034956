import React, { useState } from 'react'
import { Col, Container, Modal, Row, Table } from 'react-bootstrap';
import InputField from '../Components/Widgets/InputField';
import moment from 'moment';

const ModalExpenseAdmin = ({ show, onSuccess, listExpense, onHide, errors, listPayment, list_operators }) => {

    let { date_init, date_end } = JSON.parse(localStorage.getItem("date"))
    const [form, setForm] = useState({
        registration_date: moment().format("YYYY-MM-DD"),
        parameter_id: 0, description: '',
        amount: '', worker_id: 0, cashier_id: 0,
        payment_method_id: 0
    })

    const updateInput = (e) => {
        setForm({
            ...form,
            [e.target.name]: e.target.value
        })
    }

    const onSuccessPre = () => {
        let _form = form
        onSuccess(_form)
    }

    return (
        <Modal show={show} onHide={onHide}>
            <Modal.Header closeButton>
                <Modal.Title>Gasto Administrativo</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Container>
                    <Row>
                        <Col>
                            <div className="form-group">
                                <label>Fecha</label>
                                <InputField
                                    type="date"
                                    value={form.registration_date}
                                    name="registration_date"
                                    min={date_init}
                                    max={date_end}
                                    onChange={updateInput}
                                    error={errors.registration_date}
                                />
                            </div>
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            <div className="form-group">
                                <label>Concepto</label>
                                <select className='form-control' name="parameter_id" onChange={updateInput}>
                                    <option value={0}>Seleccione</option>
                                    {listExpense.map((v, k) => <option value={v.id} key={k}>{v.description}</option>)}
                                </select>
                                {errors.parameter_id !== undefined && (<label className="error-label">{errors.parameter_id}</label>)}
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <div className="form-group">
                                <label>Descripción</label>
                                <InputField
                                    placeholder={"Descripción"}
                                    value={form.description}
                                    name="description"
                                    onChange={updateInput}
                                    error={errors.description}
                                />
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <div className="form-group">
                                <label>Método de Pago</label>
                                <select className='form-control' name="payment_method_id" onChange={updateInput}>
                                    <option value={0}>Seleccione</option>
                                    {listPayment.map((v, k) => <option value={v.id} key={k}>{v.description}</option>)}
                                </select>
                                {errors.payment_method_id !== undefined && (<label className="error-label">{errors.payment_method_id}</label>)}

                            </div>
                        </Col>
                        <Col>
                            <div className="form-group">
                                <label>Valor</label>
                                <InputField
                                    type={"number"}
                                    placeholder={"Valor"}
                                    value={form.amount}
                                    name="amount"
                                    onChange={updateInput}
                                    error={errors.amount}
                                />
                            </div>
                        </Col>
                    </Row>
                </Container>

            </Modal.Body>
            <Modal.Footer>
                <Container>
                    <Row>
                        <Col>
                            <button className='btn btn-sm btn-success form-control' onClick={onSuccessPre}>Guardar Gasto</button>
                        </Col>

                    </Row>
                </Container>
            </Modal.Footer>
        </Modal>
    )
}


export default ModalExpenseAdmin;