import React, { useEffect, useRef, useState } from 'react'
import TemplateMenu from '../../template/menu.template'
import { connect } from 'react-redux';
import { getTurns } from '../../Redux/turnDuck';
import { toogleAlert } from '../../Redux/alertDuck'
import { Col, Row, Table } from 'react-bootstrap';
import { getReviewServices } from '../../Redux/reportDuck'
import moment from 'moment';
import { formatNumber } from '../../utils';
import { DownloadTableExcel } from 'react-export-table-to-excel';
import { RiFileExcel2Fill } from 'react-icons/ri';


const ReviewServices = ({ getTurns, fetching_list_turn, data_turn, toogleAlert, getReviewServices, fetching, data }) => {

    const [listTurn, setListTurn] = useState([])
    const [listData, setListData] = useState([])
    const [form, setForm] = useState({ turn_id: 0, date_init: moment().format("YYYY-MM-01"), date_end: moment().format("YYYY-MM-DD") })
    const table = useRef(null);

    useEffect(() => {
        toogleAlert(true)
        getTurns()
    }, [])

    useEffect(() => {
        if (fetching_list_turn === 2) {
            toogleAlert(false)
            setListTurn(data_turn)
        }
    }, [fetching_list_turn])

    useEffect(() => {
        if (fetching === 2) {
            toogleAlert(false)
            setListData(data.results)
        }
    }, [fetching])

    const updateField = (e) => {
        setForm({
            ...form,
            [e.target.name]: e.target.value
        })
    }

    const onSearch = () => {
        toogleAlert(true)
        getReviewServices(form)
    }

    return (
        <TemplateMenu>
            <Row className='mt-3'>
                <Col lg={3}>
                    <div className="form-group">
                        <label>Turnos</label>
                        <select className='form-control' name='turn_id' value={form.turn_id} onChange={updateField}>
                            <option value={0}>POR RANGO DE FECHA</option>
                            {listTurn.map((d, k) => <option key={k} value={d.id}>{d.user.name} - ({d.id}) | {d.date_start} | {d.date_end}</option>)}
                        </select>
                    </div>
                </Col>
                {parseInt(form.turn_id) === 0 && <>
                    <Col lg={2}>
                        <div className="form-group">
                            <label>Fecha de Inicio</label>
                            <input
                                type="date"
                                className="form-control"
                                value={form.date_init}
                                name="date_init"
                                onChange={updateField}
                            />
                        </div>
                    </Col>
                    <Col lg={2}>
                        <div className="form-group">
                            <label>Fecha Fin</label>
                            <input
                                type="date"
                                className="form-control"
                                value={form.date_end}
                                name="date_end"
                                onChange={updateField}
                            />
                        </div>
                    </Col>
                </>}
                <Col lg={2} className='d-flex align-items-center'>
                    <button className='btn btn-sm btn-info' onClick={onSearch}>Buscar</button>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Table className='table table-sm table-bordered table-hover table-striped' ref={table}>
                        <thead className='thead-dark'>
                            <tr>
                                <th colSpan={6} className="text-center">
                                    <span className='px-4'>Reporte de Venta por Servicios</span>
                                    <DownloadTableExcel
                                        className="px-5"
                                        filename="total_servicios"
                                        sheet="orders"
                                        currentTableRef={table.current}>
                                        <RiFileExcel2Fill color='green' className='cursor-pointer' />
                                    </DownloadTableExcel>
                                </th>
                            </tr>
                            <tr>
                                <th>Categoria</th>
                                <th>Servicio</th>
                                <th>Cantidad</th>
                                <th>Subtotal</th>
                                <th>Descuento</th>
                                <th>Total</th>
                            </tr>
                        </thead>
                        <tbody>
                            {listData.length === 0 ? <tr><td colSpan={5} align='center'>Sin datos</td></tr> :
                                listData.map((row, i) => <tr key={i}>
                                    <td>{row.category}</td>
                                    <td>{row.service}</td>
                                    <td align='right'>{row.quantity}</td>
                                    <td align='right'>{formatNumber(row.subtotal)}</td>
                                    <td align='right'>{formatNumber(row.discount)}</td>
                                    <td align='right'>{formatNumber(row.total)}</td>
                                </tr>)}
                        </tbody>
                        <tfoot>
                            <tr>
                                <th colSpan={2}>TOTAL</th>
                                <th className='text-right'>{formatNumber(listData.reduce((a, b) => a + parseFloat(b.quantity), 0))}</th>
                                <th className='text-right'>{formatNumber(listData.reduce((a, b) => a + parseFloat(b.subtotal), 0))}</th>
                                <th className='text-right'>{formatNumber(listData.reduce((a, b) => a + parseFloat(b.discount), 0))}</th>
                                <th className='text-right'>{formatNumber(listData.reduce((a, b) => a + parseFloat(b.total), 0))}</th>
                            </tr>
                        </tfoot>
                    </Table>
                </Col>
            </Row>
        </TemplateMenu>

    )
}

const mapsState = (props) => {
    let { turn, report } = props

    return {
        fetching_list_turn: turn.fetching_list,
        data_turn: turn.data,
        fetching: report.fetching,
        data: report.data,
    }
}
export default connect(mapsState, { getTurns, toogleAlert, getReviewServices })(ReviewServices);