
import Axios from 'axios'

import { getHeaders } from '../utils'

const API_URL = process.env.REACT_APP_API_URL
const API_URL_FE = process.env.REACT_APP_API_URL_FE

let initialState = {
    fetching_company: 0,
    fetching_company_store: 0,
    fetching_create: 0,
    fetching_data_create: 0,
    fetching_create_invoice: 0,
    fetching_create_resolution: 0,
    fetching_invoice: 0,
    fetching_order: 0,
    fetching_pair: 0,
    fetching_fe: 0,
    fetching_resolution: 0,
    fetching_by_id: 0,
    fetching_nc: 0,
    fetching_pdf: 0,
    data_company: {},
    data_fe: [],
    data_pdf: '',
    data_create: [],
    data_invoice: [],
    data_order: [],
    data_nc: [],
    row: {},
    errors: {},
    data_resolution: {},
    resolution_enable: {}
}


const COMPANY = "COMPANY"
const COMPANY_SUCCESS = "COMPANY_SUCCESS"
const COMPANY_STORE = "COMPANY_STORE"
const COMPANY_STORE_SUCCESS = "COMPANY_STORE_SUCCESS"
const FE_DATA = "FE_DATA"
const FE_DATA_SUCCESS = "FE_DATA_SUCCESS"
const FE_DATA_CREATE = "FE_DATA_CREATE"
const FE_DATA_CREATE_SUCCESS = "FE_DATA_CREATE_SUCCESS"
const LIST_INVOICE = "LIST_INVOICE"
const LIST_INVOICE_SUCCESS = "LIST_INVOICE_SUCCESS"
const LIST_ORDER = "LIST_ORDER"
const LIST_ORDER_SUCCESS = "LIST_ORDER_SUCCESS"
const CREATE_INVOICE = "CREATE_INVOICE"
const CREATE_INVOICE_SUCCESS = "CREATE_INVOICE_SUCCESS"
const CREATE_INVOICE_ERROR = "CREATE_INVOICE_ERROR"
const CREATE_RESOLUTION = "CREATE_RESOLUTION"
const CREATE_RESOLUTION_SUCCESS = "CREATE_RESOLUTION_SUCCESS"
const PAIR_RESOLUTION = "PAIR_RESOLUTION"
const PAIR_RESOLUTION_SUCCESS = "PAIR_RESOLUTION_SUCCESS"
const LIST_RESOLUTION = "LIST_RESOLUTION"
const LIST_RESOLUTION_SUCCESS = "LIST_RESOLUTION_SUCCESS"
const CREATE_PDF = "CREATE_PDF"
const CREATE_PDF_SUCCESS = "CREATE_PDF_SUCCESS"
const FE_BY_ID = "FE_BY_ID"
const FE_BY_ID_SUCCESS = "FE_BY_ID_SUCCESS"
const CREATE_NC = "CREATE_NC"
const CREATE_NC_SUCCESS = "CREATE_NC_SUCCESS"


export default function reducer(state = initialState, action) {
    switch (action.type) {
        case COMPANY:
            return { ...state, fetching_company: 1, }
        case COMPANY_SUCCESS:
            return { ...state, fetching_company: 2, data_company: action.payload }
        case FE_DATA:
            return { ...state, fetching_fe: 1 }
        case FE_DATA_SUCCESS:
            return { ...state, fetching_fe: 2, data_fe: action.payload }
        case COMPANY_STORE:
            return { ...state, fetching_company_store: 1 }
        case COMPANY_STORE_SUCCESS:
            return { ...state, fetching_company_store: 2 }
        case FE_DATA_CREATE:
            return { ...state, fetching_data_create: 1 }
        case FE_DATA_CREATE_SUCCESS:
            return { ...state, fetching_data_create: 2, data_create: action.payload }
        case LIST_INVOICE:
            return { ...state, fetching_invoice: 1 }
        case LIST_INVOICE_SUCCESS:
            return { ...state, fetching_invoice: 2, fetching_create_invoice: 0, data_invoice: action.payload }
        case LIST_ORDER:
            return { ...state, fetching_order: 1 }
        case LIST_ORDER_SUCCESS:
            return { ...state, fetching_order: 2, data_order: action.payload }
        case CREATE_INVOICE:
            return { ...state, fetching_create_invoice: 1 }
        case CREATE_INVOICE_SUCCESS:
            return { ...state, fetching_create_invoice: 2, errors: {}, data_order: [] }
        case CREATE_INVOICE_ERROR:
            return { ...state, fetching_create_invoice: 3, errors: action.payload }
        case CREATE_RESOLUTION:
            return { ...state, fetching_create_resolution: 1 }
        case CREATE_RESOLUTION_SUCCESS:
            return { ...state, fetching_create_resolution: 2, data_resolution: action.payload }
        case PAIR_RESOLUTION:
            return { ...state, fetching_pair: 1 }
        case PAIR_RESOLUTION_SUCCESS:
            return { ...state, fetching_pair: 2 }
        case LIST_RESOLUTION:
            return { ...state, fetching_resolution: 1 }
        case LIST_RESOLUTION_SUCCESS:
            return { ...state, fetching_resolution: 2, resolution_enable: action.payload }
        case CREATE_PDF:
            return { ...state, fetching_pdf: 1 }
        case CREATE_PDF_SUCCESS:
            return { ...state, fetching_pdf: 2, data_pdf: action.payload }
        case FE_BY_ID:
            return { ...state, fetching_by_id: 1 }
        case FE_BY_ID_SUCCESS:
            return { ...state, fetching_by_id: 2, row: action.payload }
        case CREATE_NC:
            return { ...state, fetching_nc: 1 }
        case CREATE_NC_SUCCESS:
            return { ...state, fetching_nc: 2, data_nc: action.payload }
        default:
            return state;
    }
}

export let storeCompany = (form) => (dispatch, getState) => {
    dispatch({
        type: COMPANY_STORE
    })

    if (getState().user.loggedIn) {

        let url = `${API_URL_FE}/config/${form.identification_number}/${form.dv}`

        console.log('url', url);


        Axios.post(url, form,
            {
                headers: {
                    // "Authorization": `Bearer ${form.api_token}`,
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                }
            }).then(resp => {

                console.log('resp::', resp);

                dispatch({
                    type: COMPANY_STORE_SUCCESS,
                });

            }).catch(function (error) {
                console.log(error);
            });
    } else {
        console.log("no Ingreso");
    }
}

export let storeLogo = (form, api_token) => (dispatch, getState) => {
    dispatch({
        type: COMPANY_STORE
    })

    if (getState().user.loggedIn) {

        let url = `${API_URL_FE}/config/logo`


        Axios.put(url, form,
            {
                headers: {
                    "Authorization": `Bearer ${api_token}`,
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                }
            }).then(resp => {

                console.log('resp::', resp);

                dispatch({
                    type: COMPANY_STORE_SUCCESS,
                });

            }).catch(function (error) {
                console.log(error);
            });
    } else {
        console.log("no Ingreso");
    }
}

export let storeEnvironment = (form, api_token) => (dispatch, getState) => {
    dispatch({
        type: COMPANY_STORE
    })

    if (getState().user.loggedIn) {

        let url = `${API_URL_FE}/config/environment`

        Axios.put(url, form,
            {
                headers: {
                    "Authorization": `Bearer ${api_token}`,
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                }
            }).then(resp => {

                console.log('resp::', resp);

                dispatch({
                    type: COMPANY_STORE_SUCCESS,
                });

            }).catch(function (error) {
                console.log(error);
            });
    } else {
        console.log("no Ingreso");
    }
}

export let storeSoftware = (form, api_token) => (dispatch, getState) => {
    dispatch({
        type: COMPANY_STORE
    })

    if (getState().user.loggedIn) {

        let url = `${API_URL_FE}/config/software`
        console.log('url', url);

        const headers = {
            "Authorization": `Bearer ${api_token}`,
            "Content-Type": "application/json",
            "Accept": "application/json",
        }

        Axios.put(url, form,
            {
                headers
            }).then(resp => {
                dispatch({
                    type: COMPANY_STORE_SUCCESS,
                });

            }).catch(function (error) {
                console.log(error);
            });
    } else {
        console.log("no Ingreso");
    }
}

export let storeCertificate = (form, api_token) => (dispatch, getState) => {
    dispatch({
        type: COMPANY_STORE
    })

    if (getState().user.loggedIn) {

        let url = `${API_URL_FE}/config/certificate`
        console.log('url', url);

        Axios.put(url, form,
            {
                headers: {
                    "Authorization": `Bearer ${api_token}`,
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                }
            }).then(resp => {
                dispatch({
                    type: COMPANY_STORE_SUCCESS,
                });

            }).catch(function (error) {
                console.log(error);
            });
    } else {
        console.log("no Ingreso");
    }
}

export let storeResolution = (form, api_token) => (dispatch, getState) => {
    dispatch({
        type: CREATE_RESOLUTION
    })

    if (getState().user.loggedIn) {

        let url = `${API_URL_FE}/config/resolution`
        console.log('url', url);


        Axios.put(url, form,
            {
                headers: {
                    "Authorization": `Bearer ${api_token}`,
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                }
            }).then(resp => {
                dispatch({
                    type: CREATE_RESOLUTION_SUCCESS,
                    payload: resp.data.resolution
                });
            }).catch(function (error) {
                console.log(error);
            });
    } else {
        console.log("no Ingreso");
    }
}

export let pairResolution = (form, company) => (dispatch, getState) => {
    dispatch({
        type: PAIR_RESOLUTION
    })

    if (getState().user.loggedIn) {
        let url = `${API_URL}/api/user-pair`

        form.token_api = company.api_token

        Axios.post(url, form, {
            headers: getHeaders()
        }).then(resp => {
            dispatch({
                type: PAIR_RESOLUTION_SUCCESS,
                payload: resp.data.resolution
            });
        }).catch(function (error) {
            console.log(error);
        });
    } else {
        console.log("no Ingreso");
    }
}

export let getCompany = () => (dispatch, getState) => {
    dispatch({
        type: COMPANY
    })

    if (getState().user.loggedIn) {

        let url = `${API_URL}/api/fe-company`

        Axios.get(url, {
            headers: getHeaders()
        }).then(resp => {
            dispatch({
                type: COMPANY_SUCCESS,
                payload: resp.data
            });

        }).catch(function (error) {
            console.log(error);
        });
    } else {
        console.log("no Ingreso");
    }
}
export let getResolutionAvailable = (body, api_token) => (dispatch, getState) => {
    dispatch({
        type: LIST_RESOLUTION
    })

    if (getState().user.loggedIn) {
        let url = `${API_URL_FE}/numbering-range`
        console.log('url', url);
        Axios.post(url, body,
            {
                headers: {
                    "Authorization": `Bearer ${api_token}`,
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                }
            }).then(resp => {
                dispatch({
                    type: LIST_RESOLUTION_SUCCESS,
                    payload: resp.data.ResponseDian.Envelope.Body.GetNumberingRangeResponse.GetNumberingRangeResult
                });

            }).catch(function (error) {
                console.log(error);
            });

    } else {
        console.log("no Ingreso");
    }
}

export let getFEData = () => (dispatch, getState) => {
    dispatch({
        type: FE_DATA
    })

    if (getState().user.loggedIn) {

        let url = `${API_URL}/api/fe-data`

        Axios.get(url, {
            headers: getHeaders()
        }).then(resp => {
            dispatch({
                type: FE_DATA_SUCCESS,
                payload: resp.data
            });

        }).catch(function (error) {
            console.log(error);
        });
    } else {
        console.log("no Ingreso");
    }
}

export let onCreateInvoice = (form, api_token) => (dispatch, getState) => {
    dispatch({
        type: CREATE_INVOICE
    })

    if (getState().user.loggedIn) {

        let url = `${API_URL}/api/fe`
        console.log('url', url);


        Axios.post(url, form,
            {
                headers: getHeaders()
            }).then(resp => {
                console.log('resp', resp);
                dispatch({
                    type: CREATE_INVOICE_SUCCESS
                })

            }).catch(function (error) {
                let err = []
                let { status } = error.response
                console.log('error.response', error.response);

                if (Array.isArray(error.response.data.response.ErrorMessage.string)) {
                    err = error.response.data.response.ErrorMessage.string
                } else {
                    err = [error.response.data.response.ErrorMessage.string]
                }


                dispatch({
                    type: CREATE_INVOICE_ERROR,
                    payload: err
                })
            });
    } else {
        console.log("no Ingreso");
    }
}

export let onCreateInvoiceManual = (form) => (dispatch, getState) => {
    dispatch({
        type: CREATE_INVOICE
    })

    if (getState().user.loggedIn) {

        let url = `${API_URL}/api/fe-manual`
        console.log('url', url);

        Axios.post(url, form,
            {
                headers: getHeaders()
            }).then(resp => {
                console.log('resp', resp);
                dispatch({
                    type: CREATE_INVOICE_SUCCESS
                })

            }).catch(function (error) {
                let err = []
                let { status } = error.response
                console.log('error.response', error.response);

                if (status === 400) {
                    err = {}
                } else {
                    if (Array.isArray(error.response.data.response.ErrorMessage.string)) {
                        err = error.response.data.response.ErrorMessage.string
                    } else {
                        err = [error.response.data.response.ErrorMessage.string]
                    }
                }



                dispatch({
                    type: CREATE_INVOICE_ERROR,
                    payload: err
                })
            });
    } else {
        console.log("no Ingreso");
    }
}

export let getListInvoice = (form, api_token) => (dispatch, getState) => {
    dispatch({
        type: LIST_INVOICE
    })

    if (getState().user.loggedIn) {

        let url = `${API_URL}/api/fe`

        Axios.get(url,
            {
                headers: getHeaders()
            }).then(resp => {
                dispatch({
                    type: LIST_INVOICE_SUCCESS,
                    payload: resp.data.results
                });

            }).catch(function (error) {
                console.log(error);
            });
    } else {
        console.log("no Ingreso");
    }
}


export let getDataCreate = (orders) => (dispatch, getState) => {
    dispatch({
        type: FE_DATA_CREATE
    })

    if (getState().user.loggedIn) {

        let url = `${API_URL}/api/fe-create-data`

        Axios.get(url, {
            headers: getHeaders()
        }).then(resp => {
            dispatch({
                type: FE_DATA_CREATE_SUCCESS,
                payload: resp.data
            });

        }).catch(function (error) {
            console.log(error);
        });
    } else {
        console.log("no Ingreso");
    }
}

export let getListOrders = (form) => (dispatch, getState) => {
    dispatch({
        type: LIST_ORDER
    })

    if (getState().user.loggedIn) {

        let url = `${API_URL}/api/fe-order?client_id=${form.client_id}&date_init=${form.date_init}&date_end=${form.date_end}&plate=${form.plate}&order_id=${form.order_id}`

        Axios.get(url,
            {
                headers: getHeaders()
            }).then(resp => {
                dispatch({
                    type: LIST_ORDER_SUCCESS,
                    payload: resp.data
                });

            }).catch(function (error) {
                console.log(error);
            });
    } else {
        console.log("no Ingreso");
    }
}

export let onCreatePdfFE = (url) => (dispatch, getState) => {
    dispatch({
        type: CREATE_PDF
    })

    if (getState().user.loggedIn) {


        Axios.get(url,
            {
                headers: getHeaders()
            }).then(resp => {
                dispatch({
                    type: CREATE_PDF_SUCCESS,
                    payload: resp.data.results
                });

            }).catch(function (error) {
                console.log(error);
            });
    } else {
        console.log("no Ingreso");
    }
}

export let storeResolutionNC = (form, api_token) => (dispatch, getState) => {
    dispatch({
        type: COMPANY_STORE
    })

    if (getState().user.loggedIn) {

        let url = `${API_URL_FE}/config/resolution`

        Axios.put(url, form,
            {
                headers: {
                    "Authorization": `Bearer ${api_token}`,
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                }
            }).then(resp => {
                dispatch({
                    type: COMPANY_STORE_SUCCESS,
                });

            }).catch(function (error) {
                console.log(error);
            });
    } else {
        console.log("no Ingreso");
    }
}

export let getInvoiceById = (id) => (dispatch, getState) => {
    dispatch({
        type: FE_BY_ID
    })

    if (getState().user.loggedIn) {
        let url = `${API_URL}/api/fe/${id}`

        Axios.get(url, {
            headers: getHeaders()
        }).then(resp => {
            dispatch({
                type: FE_BY_ID_SUCCESS,
                payload: resp.data
            });
        }).catch(function (error) {
            console.log(error);
        });
    } else {
        console.log("no Ingreso");
    }
}

export let createNC = (form, api_token) => (dispatch, getState) => {
    dispatch({
        type: CREATE_NC
    })

    if (getState().user.loggedIn) {

        let url = `${API_URL}/api/fe-credit-note`

        Axios.post(url, form, {
            headers: getHeaders()
        }).then(resp => {
            console.log('resp.data', resp.data);

            dispatch({
                type: CREATE_NC_SUCCESS,
                payload: resp.data
            });

        }).catch(function (error) {
            console.log(error);
        });
    } else {
        console.log("no Ingreso");
    }
}