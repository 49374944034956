import React, { useEffect, useRef, useState } from 'react'
import TemplateMenu from '../../template/menu.template'
import { connect } from 'react-redux';
import { Col, Row, Table } from 'react-bootstrap';
import { formatNumber } from '../../utils';
import { DownloadTableExcel } from 'react-export-table-to-excel';
import { RiFileExcel2Fill } from 'react-icons/ri';
import { toogleAlert } from '../../Redux/alertDuck'
import { getTurns } from '../../Redux/turnDuck';
import { getReviewOtherInput } from '../../Redux/reportDuck'
import moment from 'moment';

const ReviewOtherInputPage = ({ toogleAlert, getTurns, fetching_list_turn, data_turn, getReviewOtherInput, fetching, data }) => {
    const table = useRef(null)
    const [listData, setLisData] = useState([]);
    const [listTurn, setListTurn] = useState([]);
    const [form, setForm] = useState({ turn_id: 0, date_init: moment().format("YYYY-MM-01"), date_end: moment().format("YYYY-MM-DD") })

    useEffect(() => {
        toogleAlert(true)
        let _form = form
        let { date_init, date_end } = JSON.parse(localStorage.getItem("date"))
        _form.date_init = date_init
        _form.date_end = date_end
        setForm(_form)
        getTurns()
    }, [])

    useEffect(() => {
        if (fetching_list_turn === 2) {
            setListTurn(data_turn)
            toogleAlert(false)
        }
    }, [fetching_list_turn])

    useEffect(() => {
        if (fetching === 2) {
            setLisData(data)
            toogleAlert(false)
        }
    }, [fetching])

    const updateField = (e) => {
        setForm({
            ...form,
            [e.target.name]: e.target.value
        })
    }


    const onSearch = () => {
        toogleAlert(true)
        getReviewOtherInput(form)
    }

    return (
        <TemplateMenu>
            <Row className='mt-3'>
                <Col lg={3}>
                    <div className="form-group">
                        <label>Turnos</label>
                        <select className='form-control' name='turn_id' value={form.turn_id} onChange={updateField}>
                            <option value={0}>POR RANGO DE FECHA</option>
                            {listTurn.map((d, k) => <option key={k} value={d.id}>{d.user.name} - ({d.id}) | {d.date_start} | {d.date_end}</option>)}
                        </select>
                    </div>
                </Col>
                {parseInt(form.turn_id) === 0 && <>
                    <Col lg={2}>
                        <div className="form-group">
                            <label>Fecha de Inicio</label>
                            <input
                                type="date"
                                className="form-control"
                                value={form.date_init}
                                name="date_init"
                                onChange={updateField}
                            />
                        </div>
                    </Col>
                    <Col lg={2}>
                        <div className="form-group">
                            <label>Fecha Fin</label>
                            <input
                                type="date"
                                className="form-control"
                                value={form.date_end}
                                name="date_end"
                                onChange={updateField}
                            />
                        </div>
                    </Col>
                </>}
                <Col lg={2} className='d-flex align-items-center'>
                    <button className='btn btn-sm btn-info' onClick={onSearch}>Buscar</button>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Table className='table table-sm table-bordered table-hover table-striped' ref={table}>
                        <thead className='thead-dark'>
                            <tr>
                                <th colSpan={6} className="text-center">
                                    <span className='px-4'>Reporte de otros ingresos</span>
                                    <DownloadTableExcel
                                        className="px-5"
                                        filename="total_parqueos"
                                        sheet="orders"
                                        currentTableRef={table.current}>
                                        <RiFileExcel2Fill color='green' className='cursor-pointer' />
                                    </DownloadTableExcel>
                                </th>
                            </tr>
                            <tr>
                                <th>Fecha</th>
                                <th>Descripción</th>
                                <th>Método de pago</th>
                                <th>Total</th>
                            </tr>
                        </thead>
                        <tbody>
                            {listData.length === 0 ? <tr><td colSpan={5} align='center'>Sin datos</td></tr> :
                                listData.map((row, i) => <tr key={i}>
                                    <td>{moment(row.registration_date).format("YYYY-MM-DD hh:mm A")}</td>
                                    <td>{row.description}</td>
                                    <td>{row.payment_method}</td>
                                    <td align='right'>{formatNumber(row.value)}</td>
                                </tr>)}
                        </tbody>
                        <tfoot>
                            <tr>
                                <th colSpan={3}>TOTAL</th>
                                <th className='text-right'>{formatNumber(listData.reduce((a, b) => a + parseFloat(b.value), 0))}</th>
                            </tr>
                        </tfoot>
                    </Table>
                </Col>
            </Row>
        </TemplateMenu>

    )
}

const mapsState = (props) => {
    let { turn, report } = props

    return {
        fetching_list_turn: turn.fetching_list,
        data_turn: turn.data,
        fetching: report.fetching_bank,
        data: report.data_bank,
    }
}
export default connect(mapsState, { toogleAlert, getTurns, getReviewOtherInput })(ReviewOtherInputPage);