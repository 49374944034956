import React, { useRef } from 'react'
import { Table } from 'react-bootstrap';
import { DownloadTableExcel } from 'react-export-table-to-excel';
import { RiFileExcel2Fill } from 'react-icons/ri';
import { formatNumber } from '../../../utils';

const ReviewExpenseList = ({ listData }) => {
    const table = useRef(null)
    return (
        <Table className='table table-sm table-bordered table-hover table-striped' ref={table}>
            <thead className='thead-dark'>
                <tr>
                    <th colSpan={6} className="text-center">
                        <span className='px-4'>Reporte de Gastos</span>
                        <DownloadTableExcel
                            className="px-5"
                            filename="total_servicios"
                            sheet="orders"
                            currentTableRef={table.current}>
                            <RiFileExcel2Fill color='green' className='cursor-pointer' />
                        </DownloadTableExcel>
                    </th>
                </tr>
                <tr>
                    <th>Fecha</th>
                    <th>Categoria</th>
                    <th>Descripción</th>
                    <th>Asignado a</th>
                    <th>Método de pago</th>
                    <th>Total</th>
                </tr>
            </thead>
            <tbody>
                {listData.length === 0 ? <tr><td colSpan={5} align='center'>Sin datos</td></tr> :
                    listData.map((row, i) => <tr key={i}>
                        <td>{row.registration_date}</td>
                        <td>{row.category}</td>
                        <td>{row.description}</td>
                        <td>{row.worker}</td>
                        <td>{row.payment_method}</td>
                        <td align='right'>{formatNumber(row.value)}</td>
                    </tr>)}
            </tbody>
            <tfoot>
                <tr>
                    <th colSpan={5}>TOTAL</th>
                    <th className='text-right'>{formatNumber(listData.reduce((a, b) => a + parseFloat(b.value), 0))}</th>
                </tr>
            </tfoot>
        </Table>
    )
}

export default ReviewExpenseList;