import React, { useEffect, useRef, useState } from 'react'
import { Col, Row, Tab, Table, Tabs } from 'react-bootstrap';
import { connect } from 'react-redux';
import TemplateMenu from '../../template/menu.template'
import { getListCost, getListAdmin, onCreateExpense, onDeleteExpense, onEditExpense } from '../../Redux/expenseDuck';
import { getParameterBySegment } from '../../Redux/parameterDuck';
import { getTurns } from '../../Redux/turnDuck';
import { getOperators } from '../../Redux/userDuck';
import OperationCostPage from './operation.cost.page';
import OperationCostAdminPage from './operation.cost.admin.page';
import CostAdminPayrollPage from './cost.admin.payroll.page';

const ExpensePage = ({ getListCost, getParameterBySegment, fetching_segment, data_segment,
    segment, turn, getTurns, fetching_list_turn, data_turn, getOperators, fetching_operator, data_operator }) => {
    const [listExpense, setListExpense] = useState([])
    const [listPayment, setListPayment] = useState([])
    const [form, setForm] = useState({ is_admin: false })

    const [listTurn, setListTurn] = useState([])
    const [listOperator, setListOperator] = useState([])
    const [selected, setSelected] = useState('expense')

    useEffect(() => {

        if (fetching_list_turn === 2) {
            setListTurn(data_turn)
        }
    }, [fetching_list_turn])

    useEffect(() => {
        if (fetching_operator === 2) {
            setListOperator(data_operator.filter(d => d.is_payroll))
        }
    }, [fetching_operator])

    useEffect(() => {
        if (fetching_segment === 2) {
            if (segment === 'box') {
                setListExpense(data_segment);
                getParameterBySegment("payment")
            }

            if (segment === 'payment') {
                setListPayment(data_segment)
            }
        }
    }, [fetching_segment])

    useEffect(() => {
        // let { date_init, date_end } = JSON.parse(localStorage.getItem("date"))
        // let _form = {
        //     ...form
        // }
        // _form.date_init = date_init
        // _form.date_end = date_end
        // setForm(_form)
        // getListCost(_form)
        getParameterBySegment("box")
        getTurns()
        getOperators()
    }, [])

    return (
        <TemplateMenu>
            <Row>
                <Col lg={3} className="d-flex align-items-center"><h4>Gastos</h4></Col>
            </Row>
            <Row className='my-2'>
                <Col>
                    <Tabs
                        defaultActiveKey="expense"
                        activeKey={selected}
                        onSelect={(val) => {

                            console.log('val', val);

                            if (val === 'expense') {

                            }
                            if (val === 'expense_admin') {

                            }
                            if (val === 'expense_payroll') {

                            }

                            // let _form = {
                            //     ...form
                            // }

                            // if (val === 'expense') {
                            //     _form.is_admin = val === 'expense' ? false : true
                            //     setForm(_form)
                            //     getListCost(_form)
                            // } else {
                            //     getListAdmin(_form)
                            // }

                            setSelected(val)

                        }}>
                        <Tab eventKey="expense" title="Gastos Operativos">
                            <OperationCostPage
                                listPayment={listPayment}
                                listExpense={listExpense}
                                turn={turn}
                                listTurn={listTurn}
                                errors={{}} />
                        </Tab>
                        <Tab eventKey="expense_payroll" title="Gastos Operativos nomina">
                            <CostAdminPayrollPage
                                listPayment={listPayment}
                                listExpense={listExpense}
                                turn={turn}
                                listTurn={listTurn}
                                errors={{}} />
                        </Tab>
                        <Tab eventKey="expense_admin" title="Gastos Administrativos">
                            <OperationCostAdminPage
                                listPayment={listPayment}
                                listExpense={listExpense}
                                turn={turn}
                                listTurn={listTurn}
                                errors={{}} />
                        </Tab>
                    </Tabs>
                </Col>

            </Row>


        </TemplateMenu >
    )
}

const mapsState = (props) => {
    let { parameter, turn, user, expense } = props

    return {
        fetching_segment: parameter.fetching_segment,
        data_segment: parameter.data_segment,
        segment: parameter.segment,
        turn: turn.row,
        fetching_list_turn: turn.fetching_list,
        data_turn: turn.data,
        fetching_operator: user.fetching_operator,
        data_operator: user.data_operator,
        fetching_list_expense: expense.fetching_list
    }
}

export default connect(mapsState, {
    getListCost, getParameterBySegment, onCreateExpense, onDeleteExpense,
    onEditExpense, getTurns, getOperators
})(ExpensePage);