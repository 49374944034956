import React, { useEffect, useState } from 'react'
import TemplateMenu from '../../template/menu.template'
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { getInvoiceById, createNC } from '../../Redux/fetDuck'
import { toogleLoader } from '../../Redux/alertDuck'
import { connect } from 'react-redux';
import { Card, Col, Row } from 'react-bootstrap';


const CreditNotePage = ({ getInvoiceById, fetching_by_id, row, createNC, fetching_nc,
    data_nc, toogleLoader }) => {

    const { id } = useParams();
    const [form, setForm] = useState({})
    const [nc, setNc] = useState({
        "id": '',
        "discrepancyresponsecode": 2,
        "discrepancyresponsedescription": "",
        "notes": "",
        "type_document_id": 4,
        "number": ""
    })
    const [pdf, setPdf] = useState('')
    const [dataNC, setDataNC] = useState({})

    const history = useHistory();

    useEffect(() => {
        if (fetching_by_id === 2) {
            setForm(row.documennt)
            setPdf(`https://fe.carwashcol.com//storage/${row.document.identification_number}/${row.document.pdf}?embedded=true&#toolbar=0&navpanes=0&scrollbar=0`)
            setNc({
                ...nc,
                "number": row.number,
                "id": row.document.id,
            })

        }
    }, [fetching_by_id])

    useEffect(() => {
        if (fetching_nc === 2) {
            let { ResponseDian } = data_nc
            let { Envelope } = ResponseDian
            let { Body } = Envelope
            let { SendBillSyncResponse } = Body
            let { SendBillSyncResult } = SendBillSyncResponse
            let { IsValid } = SendBillSyncResult
            if (IsValid === "true") {
                toogleLoader(false)
                history.goBack();
            }

            setDataNC(data_nc)
        }
    }, [fetching_nc])

    useEffect(() => {
        getInvoiceById(id)
    }, [id])

    const updateInput = (e) => {
        setNc({
            ...nc,
            [e.target.name]: e.target.value
        })
    }

    const onCreate = () => {
        toogleLoader(true)
        createNC(nc)
    }


    return (
        <TemplateMenu>
            <Row className="min-vh-100">
                <Col>
                    <Card>
                        <Card.Body>
                            <Card.Title>Nota Credito #{nc.number}</Card.Title>
                            <Row>
                                <Col>
                                    <div className="form-group">
                                        <label>Notas de Discrepancia</label>
                                        <textarea className='form-control'
                                            placeholder='Notas de discrepancia'
                                            name='discrepancyresponsedescription'
                                            onChange={updateInput}
                                            value={nc.discrepancyresponsedescription}>
                                        </textarea>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <div className="form-group">
                                        <label>Notas</label>
                                        <textarea className='form-control'
                                            name='notes'
                                            onChange={updateInput}
                                            placeholder='Notas' value={nc.notes}></textarea>

                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <button className='btn btn-sm btn-info' onClick={onCreate}>Crear</button>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
                <Col lg={6} className="h-100">
                    <iframe src={pdf}
                        style={{ width: "100%", height: "100vh", border: "none" }}>

                    </iframe>
                </Col>
            </Row>

        </TemplateMenu >
    )
}

const mapsState = (props) => {
    const { fe } = props

    return {
        fetching_by_id: fe.fetching_by_id,
        row: fe.row,
        data_nc: fe.data_nc,
        fetching_nc: fe.fetching_nc
    }
}

export default connect(mapsState, { getInvoiceById, createNC, toogleLoader })(CreditNotePage);