import React, { useEffect, useState } from 'react'
import { Card, Col, ListGroup, Row } from 'react-bootstrap';
import moment from "moment";

const ItemVdsActive = ({ item }) => {
    const [forceRender, setForceRender] = useState(0); // Solo para forzar actualización

    const [elapsedTime, setElapsedTime] = useState("0:00 min");

    useEffect(() => {
        const calculateTime = () => {
            if (!item.created_at) return;

            const createdAt = moment(item.created_at);
            const now = moment();
            const duration = moment.duration(now.diff(createdAt));

            const hours = Math.floor(duration.asHours());
            const minutes = duration.minutes().toString().padStart(2, "0");
            const seconds = duration.seconds().toString().padStart(2, "0");

            let text = `${seconds} seg`

            if (minutes > 0) {
                text = `${minutes} min ${text}`
            }

            if (hours > 0) {
                text = `${hours} horas ${text}`
            }

            setElapsedTime(text);
            setForceRender((prev) => prev + 1);
        };

        calculateTime();
        const interval = setInterval(calculateTime, 1000);

        return () => clearInterval(interval);
    }, [item.created_at]);

    return (
        <Card className='my-1 border'>
            <Card.Body className='p-2'>
                <Row>
                    <Col className='text-right'>{elapsedTime}</Col>
                </Row>
                <Row className='pt-2'>
                    <Col>
                        <Row>
                            <Col><b>{item.plate}</b></Col>
                        </Row>
                        <Row>
                            <Col>{moment(item.created_at).format("YYYY-MM-DD hh:mm A")}</Col>
                        </Row>
                        <Row>
                            <Col>{item.client}</Col>
                        </Row>
                    </Col>
                    <Col>
                        <Row>
                            <Col className='text-center'><b>Servicios</b></Col>
                        </Row>
                        <Row>
                            <Col>
                                <ListGroup variant="flush">

                                    {item.details.map((element, b) => <ListGroup.Item key={b}>{element.name}</ListGroup.Item>)}
                                </ListGroup>
                            </Col>
                        </Row>

                    </Col>
                </Row>

            </Card.Body>
        </Card >
    )
}

export default ItemVdsActive;