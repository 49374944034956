import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react'
import { Col, Container, Row, Table } from 'react-bootstrap';
import { connect } from 'react-redux';
import ModalExpense from './modal.expense';
import { getListCost, onCreateExpense, onDeleteExpense, onEditExpense } from '../../Redux/expenseDuck';
import ModalPhoto from './modal.photo';
import { DownloadTableExcel } from 'react-export-table-to-excel';
import { RiFileExcel2Fill } from 'react-icons/ri';
import ItemTableExpense from './item.table.expense';
import { formatNumber } from '../../utils';

const OperationCostPage = ({ listPayment, fetching_list, data, errors, listTurn, listExpense, fetching_create, getListCost, listOperator,
    onCreateExpense, turn, onEditExpense, onDeleteExpense, fetching_delete
}) => {
    const [form, setForm] = useState({
        date_init: moment().format("YYYY-MM-01"), date_end: moment().format("YYYY-MM-DD"), status_id: 3,
        method_payment_id: 0, concept_id: 0, deleted: false, turn_id: "0", is_admin: false
    })
    const [list, setList] = useState([])

    const [modal, setModal] = useState(false)
    const [moreFilter, setMoreFilter] = useState(false)
    const [modalPhoto, setModalPhoto] = useState(false)
    const [photo, setPhoto] = useState(null)
    const tableRef = useRef(null);

    useEffect(() => {
        let { date_init, date_end } = JSON.parse(localStorage.getItem("date"))
        let _form = {
            ...form,
            "date_init": date_init,
            "date_end": date_end,
        }
        setForm(_form)
        getListCost(form)
    }, [])

    useEffect(() => {
        if (fetching_create === 2) {
            setModal(false)
            getListCost(form)
        }
    }, [fetching_create])

    useEffect(() => {
        if (fetching_delete === 2) {
            setModal(false)
            getListCost(form)
        }
    }, [fetching_delete])

    useEffect(() => {

        if (fetching_list === 2) {
            setList(data);
        }
    }, [fetching_list])

    const updateField = (e) => {

        if (e.target.type === 'checkbox') {
            setForm({
                ...form,
                [e.target.name]: e.target.checked
            })
        } else {
            setForm({
                ...form,
                [e.target.name]: e.target.value
            })
        }
    }

    const onDelete = (item) => {
        if (window.confirm("¿Realmente deseas Borrar Este Registro?")) {
            onDeleteExpense(item.id)
        }
    }

    const onFilter = () => {
        getListCost(form)
    }

    const onShowPhoto = (photo) => {
        setModalPhoto(true)
        setPhoto(photo)
    }


    return (
        <Container fluid className='mt-4'>
            <Row className='my-4'>
                <Col>
                    <button className='btn btn-sm btn-info' onClick={() => setModal(true)}>Agregar Gasto Operativo</button>
                </Col>
            </Row>
            <Row>
                <Col lg={3}>
                    <div className="form-group">
                        <label>Turnos</label>
                        <select className='form-control' name='turn_id' value={form.turn_id} onChange={updateField}>
                            <option value={0}>Rango de fechas</option>
                            {listTurn.map((d, k) => <option key={k} value={d.id}>{d.user.name} | {d.date_start} | {d.date_end}</option>)}
                        </select>
                    </div>
                </Col>
                {form.turn_id === "0" &&
                    <>
                        <Col>
                            <div className="form-group">
                                <label>Fecha de Inicio</label>
                                <input
                                    type="date"
                                    className="form-control"
                                    value={form.date_init}
                                    name="date_init"
                                    onChange={updateField}
                                />
                            </div>
                        </Col>
                        <Col>
                            <div className="form-group">
                                <label>Fecha Fin</label>
                                <input
                                    type="date"
                                    className="form-control"
                                    value={form.date_end}
                                    name="date_end"
                                    onChange={updateField}
                                />
                            </div>
                        </Col>
                    </>}

                <Col lg={2}>
                    <div className="form-group">
                        <label>Más Filtros</label>
                        <input
                            type="checkbox"
                            className="form-control"
                            checked={moreFilter}
                            onChange={(e) => setMoreFilter(e.target.checked)}
                        />
                    </div>
                </Col>

                <Col lg={2} className="d-flex align-items-center">
                    <button className='btn btn-sm btn-warning' onClick={onFilter}>Filtrar</button>
                </Col>
            </Row>
            {moreFilter && <Row>
                <Col lg={3}>
                    <div className="form-group">
                        <label>Método de Pago</label>
                        <select className='form-control' name="method_payment_id" onChange={updateField}>
                            <option value={0}>Todos</option>
                            {listPayment.map((v, k) => <option value={v.id} key={k}>{v.description}</option>)}
                        </select>
                        {errors.method_payment_id !== undefined && (<label className="error-label">{errors.method_payment_id}</label>)}

                    </div>
                </Col>
                <Col lg={2}>
                    <div className="form-group">
                        <label>Concepto</label>
                        <select className='form-control' name="concept_id" onChange={updateField}>
                            <option value={0}>Todos</option>
                            {listExpense.map((v, k) => <option value={v.id} key={k}>{v.description}</option>)}
                        </select>
                        {errors.method_payment_id !== undefined && (<label className="error-label">{errors.method_payment_id}</label>)}

                    </div>
                </Col>
                <Col lg={2}>
                    <div className="form-group">
                        <label>Borrados?</label>
                        <input
                            type="checkbox"
                            className="form-control"
                            value={form.deleted}
                            name="deleted"
                            onChange={updateField}
                        />
                    </div>
                </Col>
            </Row>}

            <Row className='d-flex justify-content-center'>
                <Col lg={12}>
                    <Row>
                        <Col>
                            <DownloadTableExcel
                                filename="gastos"
                                sheet="gastos"
                                currentTableRef={tableRef.current}>
                                <RiFileExcel2Fill color='green' size={30} className='cursor-pointer' />
                            </DownloadTableExcel>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Table className='table-sm table-bordered table-hover' ref={tableRef} responsive>
                                <thead className='thead-dark'>
                                    <tr>
                                        <th>Fecha</th>
                                        <th>Concepto</th>
                                        <th>Descripción</th>
                                        <th>Método de Pago</th>
                                        <th>Soporte</th>
                                        <th className='text-center'>Valor</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {(list.length > 0) ? list.map((l, k) => <ItemTableExpense key={k} item={l}
                                        onDelete={onDelete}
                                        onEdit={onEditExpense}
                                        onShowPhoto={onShowPhoto} />) : <tr><td colSpan={6}>No hay registros</td></tr>}
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <td colSpan={5}><b>TOTAL</b></td>
                                        <td align='right'><b>{formatNumber(list.reduce((a, b) => a + parseFloat(b.value), 0))}</b></td>
                                    </tr>
                                </tfoot>
                            </Table>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <ModalPhoto show={modalPhoto} photo={photo} onHide={() => setModalPhoto(false)} />
            <ModalExpense
                listTurn={listTurn}
                show={modal}
                listExpense={listExpense}
                listPayment={listPayment}
                list_operators={listOperator}
                onSuccess={onCreateExpense}
                errors={errors}
                onHide={() => setModal(false)}
                turn={turn}
                title="Gasto operativo"
            />
        </Container>
    )
}

const mapsState = (props) => {
    let { expense } = props

    return {
        fetching_list: expense.fetching_list,
        data: expense.data,
        fetching_create: expense.fetching_create,
        fetching_delete: expense.fetching_delete,
        errors: expense.errors,

    }
}

export default connect(mapsState, { onCreateExpense, getListCost, onEditExpense, onDeleteExpense })(OperationCostPage);