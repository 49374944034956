import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter } from 'react-router-dom'

import { Provider } from 'react-redux'
import generateStore from './Redux/store'
import { SocketProvider } from './context/SocketContext';
import { Buffer } from 'buffer'
window.Buffer = window.Buffer || Buffer

let store = generateStore()

let WithRouter = () => <BrowserRouter>
    <App />
</BrowserRouter>

let WithStore = () => (<Provider store={store} >
    <SocketProvider>
        <WithRouter />
    </SocketProvider>


</Provider>)


ReactDOM.render(<WithStore />, document.getElementById('root'));
serviceWorker.unregister();