import React, { useEffect, useState } from 'react'
import { Col, FormControl, InputGroup, Modal, Row } from 'react-bootstrap';
import InputField from '../Components/Widgets/InputField';
import { onEditClient, onCreateClient } from '../../Redux/clientDuck'
import { toogleLoader } from '../../Redux/alertDuck'
import { connect } from 'react-redux';

const initState = {
    name: '', document: '', dv: '', phone: '', address: '',
    type_document_identification_id: 0, type_liability_id: 0, municipality_id: 0,
    type_regime_id: 0, type_organization_id: 0, email: '', id: 0
}

const ModalClientFe = ({ show, client, onHide, listDepartment, listTypeLiabilites,
    listRegimes, listTypeOrganizations, listTypeDocumentIdentification, onUpdateMunicipalities,
    listMunicipalies, onCancel, onEditClient, fetching_create, row, onSuccess, toogleLoader, errors, onCreateClient }) => {

    const [formClient, setFormClient] = useState(initState)


    useEffect(() => {
        if (client === '') {
            setFormClient(initState)
        } else {
            setFormClient(client)
        }

    }, [client])

    useEffect(() => {
        if (fetching_create === 2) {
            setFormClient(initState)
            toogleLoader(false)
            onSuccess(row)
        }

        if (fetching_create === 3) {
            toogleLoader(false)
            console.log('errores', errors);
        }
    }, [fetching_create])

    const updateInputClient = (e) => {
        let _input = {
            ...formClient,
            [e.target.name]: e.target.value
        }

        if (e.target.name === 'document' && e.target.value > 0) {
            _input.dv = calculateDV(e.target.value)

        }
        setFormClient(_input)
    }

    const calculateDV = (myNit) => {
        let vpri,
            x,
            y,
            z;

        // Se limpia el Nit
        myNit = myNit.replace(/\s/g, ""); // Espacios
        myNit = myNit.replace(/,/g, ""); // Comas
        myNit = myNit.replace(/\./g, ""); // Puntos
        myNit = myNit.replace(/-/g, ""); // Guiones

        // Se valida el nit
        if (isNaN(myNit)) {
            console.log("El nit/cédula '" + myNit + "' no es válido(a).");
            return "";
        };

        // Procedimiento
        vpri = new Array(16);
        z = myNit.length;

        vpri[1] = 3;
        vpri[2] = 7;
        vpri[3] = 13;
        vpri[4] = 17;
        vpri[5] = 19;
        vpri[6] = 23;
        vpri[7] = 29;
        vpri[8] = 37;
        vpri[9] = 41;
        vpri[10] = 43;
        vpri[11] = 47;
        vpri[12] = 53;
        vpri[13] = 59;
        vpri[14] = 67;
        vpri[15] = 71;

        x = 0;
        y = 0;
        for (var i = 0; i < z; i++) {
            y = (myNit.substr(i, 1));
            // console.log ( y + "x" + vpri[z-i] + ":" ) ;

            x += (y * vpri[z - i]);
            // console.log ( x ) ;    
        }

        y = x % 11;
        // console.log ( y ) ;

        return (y > 1) ? 11 - y : y;
    };

    const updateDepartment = (e) => {
        let department = listDepartment.find(d => d.id === parseInt(e.target.value))
        onUpdateMunicipalities(department.municipalities)
    }

    const onSubmit = () => {
        // toogleLoader(true)
        if (formClient.id === 0) {
            onCreateClient(formClient)
        } else {
            onEditClient(formClient);
        }
    }


    return (
        <Modal show={show} onHide={onHide} size={"lg"}>
            <Modal.Body>
                <Modal.Title>Cliente</Modal.Title>
                <Row>
                    <Col lg={4}>
                        <div className="form-group">
                            <label>Nombre *</label>
                            <InputField
                                placeholder="Nombre"
                                value={formClient.name || ''}
                                name="name"
                                onChange={updateInputClient}
                                error={errors.name}
                            />
                        </div>
                    </Col>
                    <Col lg={4}>
                        <div className="form-group">
                            <label>Tipo de Documento *</label>
                            <select className='form-control' name="type_document_identification_id"
                                value={formClient.type_document_identification_id || 0}
                                onChange={updateInputClient}>
                                <option value={0}>Seleccione</option>
                                {listTypeDocumentIdentification.map((a, b) => <option key={b} value={a.id}>{a.name}</option>)}
                            </select>
                            {errors.type_document_identification_id !== undefined ? (<label className="error-label">{errors.type_document_identification_id}</label>) : null}
                        </div>
                    </Col>
                    <Col lg={4}>
                        <div className="form-group">
                            <label>Documento *</label>
                            <InputGroup className="mb-3">
                                <FormControl
                                    name='document'
                                    onChange={updateInputClient}
                                    value={formClient.document}
                                    placeholder="Username"
                                    aria-label="Username"
                                    aria-describedby="basic-addon1"
                                />
                                <InputGroup.Prepend>
                                    <InputGroup.Text id="basic-addon1">{formClient.dv}</InputGroup.Text>
                                </InputGroup.Prepend>
                            </InputGroup>
                            {errors.document !== undefined ? (<label className="error-label">{errors.document}</label>) : null}

                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col lg={4}>
                        <div className="form-group">
                            <label>Telefono * (57)</label>
                            <InputField
                                type={"number"}
                                placeholder="Telefono"
                                value={formClient.phone || ''}
                                name="phone"
                                onChange={updateInputClient}
                                error={errors.phone}
                            />
                        </div>
                    </Col>
                    <Col lg={4}>
                        <div className="form-group">
                            <label>Correo *</label>
                            <InputField
                                type={"email"}
                                placeholder="Email"
                                value={formClient.email || ''}
                                name="email"
                                onChange={updateInputClient}
                                error={errors.email}
                            />
                        </div>
                    </Col>

                    <Col lg={4}>
                        <div className="form-group">
                            <label>Dirección</label>
                            <InputField
                                placeholder="Dirección"
                                value={formClient.address || ''}
                                name="address"
                                onChange={updateInputClient}
                                error={errors.address}
                            />
                        </div>
                    </Col>

                </Row>
                <Row>
                    <Col lg={4}>
                        <div className="form-group">
                            <label >Regimen</label>
                            <select className='form-control' name="type_regime_id"
                                value={formClient.type_regime_id || 0}
                                onChange={updateInputClient}>
                                <option value={0}>Seleccione</option>
                                {listRegimes.map((a, b) => <option key={b} value={a.id}>{a.name}</option>)}
                            </select>
                            {errors.type_regime_id !== undefined ? (<label className="error-label">{errors.type_regime_id}</label>) : null}

                        </div>
                    </Col>
                    <Col lg={4}>
                        <div className="form-group">
                            <label>Responsabilidad</label>
                            <select className='form-control' name="type_liability_id"
                                value={formClient.type_liability_id || 0}
                                onChange={updateInputClient}>
                                <option value={0}>Seleccione</option>
                                {listTypeLiabilites.map((a, b) => <option key={b} value={a.id}>{a.name}</option>)}
                            </select>
                            {errors.type_liability_id !== undefined ? (<label className="error-label">{errors.type_liability_id}</label>) : null}

                        </div>
                    </Col>
                    <Col lg={4}>
                        <div className="form-group">
                            <label>Tipo de Organización</label>
                            <select className='form-control' name="type_organization_id"
                                value={formClient.type_organization_id || 0}
                                onChange={updateInputClient}>
                                <option value={0}>Seleccione</option>
                                {listTypeOrganizations.map((a, b) => <option key={b} value={a.id}>{a.name}</option>)}
                            </select>
                            {errors.type_organization_id !== undefined ? (<label className="error-label">{errors.type_organization_id}</label>) : null}

                        </div>
                    </Col>

                </Row>
                <Row>

                    <Col lg={4}>
                        <div className="form-group">
                            <label>Departamento</label>
                            <select className='form-control' name='department_id'
                                value={formClient.department_id}
                                onChange={updateDepartment}>
                                <option value={0}>Seleccione</option>
                                {listDepartment.map((a, b) => <option key={b} value={a.id}>{a.name}</option>)}
                            </select>
                        </div>
                    </Col>
                    <Col lg={4}>
                        <div className="form-group">
                            <label>Municipio</label>
                            <select className='form-control' name="municipality_id"
                                value={formClient.municipality_id || 0}
                                onChange={updateInputClient}>
                                <option value={0}>Seleccione</option>
                                {listMunicipalies.map((a, b) => <option key={b} value={a.id}>{a.name}</option>)}
                            </select>
                            {errors.municipality_id !== undefined ? (<label className="error-label">{errors.municipality_id}</label>) : null}
                        </div>
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <Row>
                    <Col>
                        <button className='btn btn-sm btn-secondary mx-2' onClick={onCancel}>Cancelar</button>
                        <button className='btn btn-sm btn-info' onClick={onSubmit}>Guardar</button>
                    </Col>
                </Row>
            </Modal.Footer>
        </Modal>
    )
}
const mapsState = (props) => {
    let { client } = props
    return {
        fetching_create: client.fetching_create,
        row: client.row,
        errors: client.errors,
    }
}

export default connect(mapsState, { onEditClient, toogleLoader, onCreateClient })(ModalClientFe);