import React, { useRef } from 'react'
import { Col, Row, Table } from 'react-bootstrap';
import { DownloadTableExcel } from 'react-export-table-to-excel';
import { RiFileExcel2Fill } from 'react-icons/ri';
import { formatNumber } from '../../../utils';

const ReviewProductInput = ({ listData }) => {
    const table = useRef(null);

    return (
        <Row>
            <Col>
                <Table className='table table-sm table-bordered table-hover table-striped' ref={table}>
                    <thead className='thead-dark'>
                        <tr>
                            <th colSpan={6} className="text-center">
                                <span className='px-4'>Reporte de Venta por Productos</span>
                                <DownloadTableExcel
                                    className="px-5"
                                    filename="total_servicios"
                                    sheet="orders"
                                    currentTableRef={table.current}>
                                    <RiFileExcel2Fill color='green' className='cursor-pointer' />
                                </DownloadTableExcel>
                            </th>
                        </tr>
                        <tr>
                            <th>Categoria</th>
                            <th>Producto</th>
                            <th>Cantidad</th>
                            <th>Total</th>
                        </tr>
                    </thead>
                    <tbody>
                        {listData.length === 0 ? <tr><td colSpan={5} align='center'>Sin datos</td></tr> :
                            listData.map((row, i) => <tr key={i}>
                                <td>{row.category}</td>
                                <td>{row.product}</td>
                                <td align='right'>{row.quantity}</td>
                                <td align='right'>{formatNumber(row.total)}</td>
                            </tr>)}
                    </tbody>
                    <tfoot>
                        <tr>
                            <th colSpan={2}>TOTAL</th>
                            <th className='text-right'>{formatNumber(listData.reduce((a, b) => a + parseFloat(b.quantity), 0))}</th>
                            <th className='text-right'>{formatNumber(listData.reduce((a, b) => a + parseFloat(b.total), 0))}</th>
                        </tr>
                    </tfoot>
                </Table>
            </Col>
        </Row>
    )
}

export default ReviewProductInput;