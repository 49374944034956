import React from 'react';
import modify from '../../Images/modify.svg'
import moment from 'moment';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';


const RowTableUser = ({ item, onEdit }) => {
  let { branch_office, business, rol } = item;
  let color = item.status === true ? '' : 'table-secondary'

  return (
    <tr className={`${color}`}>
      <td>{moment(item.created_at).format("YYYY-MM-DD")}</td>
      <td>{item.name}</td>
      <td>{item.email}</td>
      <td>{item.phone}</td>
      <td>{item.document}</td>
      <td>{business.business}</td>
      <td>{(branch_office !== null) ? branch_office.description : ''}</td>
      <td>{rol.title}</td>
      <td>{item.percent}</td>
      <td>{item.status_text}</td>
      <td>{item.is_payroll ? 'Por Nomina' : 'Informal'}</td>
      <td className="text-center">
        <button className='btn btn-sm btn-info' onClick={() => onEdit(item)}>Editar</button>
        <Link className='btn btn-sm btn-danger mx-2' to={`/usuarios-permisos/${item.id}`}>Permisos</Link>
      </td>
    </tr>
  )
}
export default RowTableUser;