import React, { useEffect, useState } from 'react'
import TemplateMenu from '../../template/menu.template'
import { Col, Row, Tab, Tabs } from 'react-bootstrap';
import { connect } from 'react-redux';
import {
    getCompany, getFEData, storeCompany, storeSoftware, storeCertificate, storeResolution,
    pairResolution, storeEnvironment, getResolutionAvailable, storeResolutionNC, storeLogo
} from '../../Redux/fetDuck'
import { toogleLoader } from '../../Redux/alertDuck'
import BusinessInfoPage from './tabs/business.info';
import SoftwareInfoPage from './tabs/software.info';
import CertifcateInfoPage from './tabs/certificate.info';
import ResolutionInfoPage from './tabs/resolution.info';
import EnvironmentInfoPage from './tabs/environment.info';
import ResolutionAvaiablesPage from './tabs/resolution.available';
import ResolutionNCPage from './tabs/resolution.nc';
import { useToasts } from 'react-toast-notifications';
import LogoInfoPage from './tabs/logo.info';

const FECongigurationPage = ({ getCompany, fetching_company, data_company, getFEData, fetching_fe, data_fe,
    storeCompany, toogleLoader, fetching_company_store, storeSoftware, storeCertificate, storeResolution, fetching_create_resolution,
    data_resolution, pairResolution, fetching_pair, storeEnvironment, getResolutionAvailable, fetching_resolution,
    resolution_enable, storeResolutionNC, storeLogo
}) => {
    const [formBusiness, setFormBusiness] = useState({
        business_name: '', identification_number: '', dv: '',
        type_document_identification_id: 0, address: '', phone: '',
        merchant_registration: '0000000-00', email: '', api_token: ''
    })
    const [formSoftware, setFormSoftware] = useState({ id: '', pin: '' })

    const [formCert, setFormCert] = useState({ password: '', certificate: '' })
    const [formResolution, setFormResolution] = useState({
        type_document_id: '', prefix: '', resolution: '',
        resolution_date: '', technical_key: '', from: '', to: '', generated_to_date: '',
        date_from: '', date_to: ''
    })

    const [formEnvironment, setFormEnvironment] = useState({
        type_environment_id: 1,
        payroll_type_environment_id: 2,
        eqdocs_type_environment_id: 2
    })

    const [listTypeDocumentIdentification, setListTypeDocumentIdentification] = useState([])
    const [listTypeDocument, setListTypeDocument] = useState([])
    const [listTypeOrganization, setListTypeOrganization] = useState([])
    const [listTypeRegimes, setListTypeRegimes] = useState([])
    const [listTypeLiabilities, setListTypeLiabilities] = useState([])
    const [listDepartments, setListDepartment] = useState([])
    const [listMunicipalities, setListMunicipalities] = useState([])
    const [listTypeEnvironment, setListTypeEnvironment] = useState([])
    const [listResolution, setListResolution] = useState([])
    const [base64, setBase64] = useState("");
    const [resolutionSelected, setResolutionSelected] = useState(0);
    const [resolutionEnable, setResolutionEnable] = useState({ ResponseList: { ResolutionNumber: { NumberRangeResponse: { ResolutionNumber: '' } } } });
    const [resolutionNC, setResolutionNC] = useState({ type_document_id: 1 });
    const [image, setImage] = useState('');
    const [formImage, setFormImage] = useState({ image: '' });
    const { addToast } = useToasts();

    useEffect(() => {
        if (fetching_company === 2) {
            let { company, software, certificate, resolution, x2, resolutions, resolution_nc } = data_company

            if (software !== undefined) {
                setFormSoftware(software)
            }

            if (resolutions !== undefined) {
                setListResolution(resolutions)

                if (x2.resolution_id !== null) {
                    setResolutionSelected(x2.resolution_id)
                    let res = resolutions.find(d => parseInt(d.id) === parseInt(x2.resolution_id))
                    if (res) {
                        setFormResolution(res)
                    }
                }
            }

            if (resolution_nc) {
                setResolutionNC(resolution_nc)
            }

            if (certificate !== undefined) {
                setFormCert(certificate)
            }
            if (resolution !== undefined) {
                setFormResolution(resolution)
            }

            if (company !== undefined) {
                setFormBusiness(company)

                setFormEnvironment({
                    ...formEnvironment,
                    "type_environment_id": company.type_environment_id
                })

                let department = listDepartments.filter(d => d.id === company.department_id)

                if (department.length > 0) {
                    let { municipalities } = department[0]
                    setListMunicipalities(municipalities)
                }
            } else {
                setFormBusiness({
                    ...formBusiness,
                    "identification_number": x2.document,
                    "dv": x2.dv
                })
            }

            toogleLoader(false)
        }
    }, [fetching_company])

    useEffect(() => {
        if (fetching_fe === 2) {
            getCompany()
            let { type_document_identifications, type_organizations, type_regimes, type_liabilities,
                departments, type_documents, type_environments
            } = data_fe
            setListTypeDocumentIdentification(type_document_identifications)
            setListTypeOrganization(type_organizations)
            setListTypeRegimes(type_regimes)
            setListTypeLiabilities(type_liabilities)
            setListDepartment(departments)
            setListTypeDocument(type_documents)
            setListTypeEnvironment(type_environments)
        }
    }, [fetching_fe])

    useEffect(() => {
        if (fetching_resolution === 2) {
            toogleLoader(false)
            setResolutionEnable(resolution_enable)
        }
    }, [fetching_resolution])

    useEffect(() => {
        if (fetching_create_resolution === 2) {
            pairResolution(data_resolution, formBusiness)
        }
    }, [fetching_create_resolution])

    useEffect(() => {
        if (fetching_pair === 2) {
            toogleLoader(false)
        }
    }, [fetching_pair])

    useEffect(() => {
        if (fetching_company_store === 2) {
            toogleLoader(false)
            addToast('Guardado', { appearance: 'success' })

        }
    }, [fetching_company_store])

    useEffect(() => {
        toogleLoader(true)
        getFEData()
    }, [])

    const updateInputCert = (e) => {
        setFormCert({
            ...formCert,
            [e.target.name]: e.target.value
        })
    }
    const updateInputResolution = (e) => {
        setFormResolution({
            ...formResolution,
            [e.target.name]: e.target.value
        })
    }

    const storeBusiness = () => {
        toogleLoader(true)
        storeCompany(formBusiness)
    }

    const storeSoftwareForm = () => {
        toogleLoader(true)
        storeSoftware(formSoftware, formBusiness.api_token)
    }

    const updateInputSoftware = (e) => {
        setFormSoftware({
            ...formSoftware,
            [e.target.name]: e.target.value
        })
    }

    const updateInputEnvironment = (e) => {
        setFormEnvironment({
            ...formEnvironment,
            [e.target.name]: e.target.value
        })
    }

    const storeCertForm = () => {
        toogleLoader(true)
        let form = formCert
        form.certificate = base64
        storeCertificate(formCert, formBusiness.api_token)
    }

    const storeResolutionForm = () => {
        toogleLoader(true)
        storeResolution(formResolution, formBusiness.api_token)
    }

    const uploadFile = async (e) => {
        const file = e.target.files[0];
        if (file) {
            convertToBase64(file);
        }
    }

    const updateInputBusiness = (e) => {
        setFormBusiness({
            ...formBusiness,
            [e.target.name]: e.target.value
        })

        if (e.target.name === 'department_id') {
            let department = listDepartments.filter(d => d.id === parseInt(e.target.value))
            let { municipalities } = department[0]
            setListMunicipalities(municipalities)
        }
    }

    const convertToBase64 = (file) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            setBase64(reader.result.replace('data:application/x-pkcs12;base64,', ''));
        };
        reader.onerror = (error) => {
            console.error("Error al convertir a Base64:", error);
        };
    };

    const onChangeResolution = (e) => {
        setResolutionSelected(e.target.value)
        let resol = listResolution.find(d => parseInt(d.id) === parseInt(e.target.value))
        setFormResolution(resol);
    }

    const storeEnvironmentForm = () => {
        storeEnvironment(formEnvironment, formBusiness.api_token)
    }

    const getListResolution = () => {
        toogleLoader(true)
        let body = { IDSoftware: formSoftware.id }
        getResolutionAvailable(body, formBusiness.api_token)
    }

    const updateResolution = (form) => {
        toogleLoader(true)
        storeResolution(form, formBusiness.api_token)
    }

    const updateInputResolutionNC = (e) => {
        setResolutionNC({
            ...resolutionNC,
            [e.target.name]: e.target.value
        })
    }

    const storeResolutionNCForm = () => {
        let body = {
            "type_document_id": resolutionNC.type_document_id,
            "from": resolutionNC.from,
            "to": resolutionNC.to,
            "prefix": resolutionNC.prefix
        }
        toogleLoader(true)

        storeResolutionNC(body, formBusiness.api_token)
    }

    const updateImage = (e) => {
        setFormImage({ ...formImage, image: e.target.files[0] });


        let file = e.target.files[0];
        let reader = new FileReader();
        reader.onload = () => {
            if (reader.readyState === 2) {
                setImage(reader.result)
            }
        };
        reader.readAsDataURL(file);
    }

    const storeLogoForm = () => {
        let form = { logo: image.replace("data:image/png;base64,", '').replace('data:image/jpeg;base64,', '') }
        storeLogo(form, formBusiness.api_token)
    }

    return (
        <TemplateMenu>
            <Row className='my-3'>
                <Col>Configuraciones</Col>
            </Row>
            <Tabs defaultActiveKey="home" id="uncontrolled-tab-example">
                <Tab eventKey="home" title="Paso 1 - Empresa">
                    <BusinessInfoPage
                        listDepartments={listDepartments}
                        listTypeDocumentIdentification={listTypeDocumentIdentification}
                        listTypeOrganization={listTypeOrganization}
                        listTypeLiabilities={listTypeLiabilities}
                        listTypeRegimes={listTypeRegimes}
                        storeBusiness={storeBusiness}
                        formBusiness={formBusiness}
                        updateInputBusiness={updateInputBusiness}
                        listMunicipalities={listMunicipalities}
                    />
                </Tab>
                <Tab eventKey="software" title="Paso 2 - Software">
                    <SoftwareInfoPage
                        storeSoftwareForm={storeSoftwareForm}
                        formBusiness={formBusiness}
                        formSoftware={formSoftware}
                        updateInputSoftware={updateInputSoftware}
                    />
                </Tab>
                <Tab eventKey="certi" title="Paso 3 - Certificado">
                    <CertifcateInfoPage
                        formBusiness={formBusiness}
                        formCert={formCert}
                        updateInputCert={updateInputCert}
                        uploadFile={uploadFile}
                        base64={base64}
                        storeCertForm={storeCertForm}
                    />
                </Tab>
                <Tab eventKey="resolution" title="Paso 4 - Resolución">
                    <ResolutionInfoPage
                        formBusiness={formBusiness}
                        storeResolutionForm={storeResolutionForm}
                        resolutionSelected={resolutionSelected}
                        onChangeResolution={onChangeResolution}
                        listResolution={listResolution}
                        formResolution={formResolution}
                        listTypeDocument={listTypeDocument}
                        updateInputResolution={updateInputResolution}
                    />
                </Tab>
                <Tab eventKey="environment" title="Paso 5 - Cambio de Entorno">
                    <EnvironmentInfoPage
                        formBusiness={formBusiness}
                        formEnvironment={formEnvironment}
                        storeEnvironment={storeEnvironmentForm}
                        updateInputEnvironment={updateInputEnvironment}
                        listTypeEnvironment={listTypeEnvironment}
                    />
                </Tab>
                <Tab eventKey="list_resolutions" title="Paso 6 - Resoluciones disponibles">
                    <ResolutionAvaiablesPage
                        formBusiness={formBusiness}
                        getListResolution={getListResolution}
                        resolutionEnable={resolutionEnable}
                        updateResolution={updateResolution}
                    />
                </Tab>
                <Tab eventKey="resolution_nc" title="Paso 7 - Resolucion Nota Crédito">
                    <ResolutionNCPage
                        formBusiness={formBusiness}
                        resolutionNC={resolutionNC}
                        listTypeDocument={listTypeDocument}
                        updateInputResolutionNC={updateInputResolutionNC}
                        storeResolutionNC={storeResolutionNCForm}
                    />
                </Tab>
                <Tab eventKey="logo" title="Paso 8 - Agrega tu Logo">
                    <LogoInfoPage formBusiness={formBusiness} image={image} updateImage={updateImage} storeLogo={storeLogoForm} />
                </Tab>
            </Tabs>
        </TemplateMenu>
    )
}
const mapsState = (props) => {
    let { fe } = props

    return {
        fetching_company: fe.fetching_company,
        data_company: fe.data_company,
        fetching_fe: fe.fetching_fe,
        data_fe: fe.data_fe,
        fetching_company_store: fe.fetching_company_store,
        fetching_create_resolution: fe.fetching_create_resolution,
        data_resolution: fe.data_resolution,
        fetching_pair: fe.fetching_pair,
        fetching_resolution: fe.fetching_resolution,
        resolution_enable: fe.resolution_enable,
    }
}
export default connect(mapsState, {
    getCompany, getFEData, storeCompany, toogleLoader, storeSoftware,
    storeCertificate, storeResolution, pairResolution, storeEnvironment, getResolutionAvailable,
    storeResolutionNC, storeLogo
})(FECongigurationPage);