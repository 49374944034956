import React, { useEffect, useState } from 'react'
import { Row, Col, Card, Container } from 'react-bootstrap';
import { connect } from 'react-redux';
import axios from 'axios';

import { getMenu } from '../../Redux/menuDuck'
import { getReview } from '../../Redux/reportDuck'
import { getCategoryProduct } from '../../Redux/categoryDuck'
import { getTurnActive } from '../../Redux/turnDuck'
import { formatNumber } from '../../utils'
import { Chart as ChartJs, LineElement, CategoryScale, LinearScale, PointElement, Title, Tooltip, Legend, Filler, ArcElement } from 'chart.js'
import { Bar, Doughnut, Line } from 'react-chartjs-2';
import { embedDashboard } from "@superset-ui/embedded-sdk";

import TemplateMenu from '../../template/menu.template'

import './index.css'

ChartJs.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    ArcElement,
    Title,
    Tooltip,
    Legend, Filler
)


const Home = ({ getMenu, getReview, fetching, data, getCategoryProduct, fetching_category, data_category, getTurnActive, fetching_me,
    user
}) => {


    const [businessId, setBusinessId] = useState('');

    useEffect(() => {
        getMenu()
        // getReview()
        getCategoryProduct()
        getTurnActive();

    }, [])

    useEffect(() => {
        if (fetching_me === 2) {
            setBusinessId(user.business_id)
            console.log('user', user);

        }
    }, [fetching_me])



    return (
        <TemplateMenu>
            <Row className="h-100">
                {businessId !== '' &&
                    <Col lg={12} style={{ height: "100vh" }}>
                        <iframe
                            src={`http://186.116.33.117:8088/superset/dashboard/1/?standalone=true&business_id=${businessId}`}
                            title="ggraias"
                            width="100%"
                            height="100%"
                            style={{ border: "none" }}
                        ></iframe>
                    </Col>}

            </Row>

        </TemplateMenu >
    )
}

const mapsState = (props) => {
    let { category, report, user } = props

    return {
        data: report.data,
        fetching: report.fetching,
        fetching_category: category.fetching,
        data_category: category.data,
        fetching_me: user.fetching_me,
        user: user.data
    }
}

export default connect(mapsState, { getMenu, getReview, getCategoryProduct, getTurnActive })(Home);