import { getHeaders } from "../utils";
const API_URL = process.env.REACT_APP_API_URL

let initialState = {
    fetching_list: 0,
    fetching_create: 0,
    fetching_by_id: 0,
    data: [],
    row: {},
    errors: {}
}

let LIST_PURCHASE = "LIST_PURCHASE";
let LIST_PURCHASE_SUCCESS = "LIST_PURCHASE_SUCCESS";
let CREATE_PURCHASE = "CREATE_PURCHASE";
let CREATE_PURCHASE_SUCCESS = "CREATE_PURCHASE_SUCCESS";
let CREATE_PURCHASE_ERROR = "CREATE_PURCHASE_ERROR";
let PURCHASE_BY_ID = "PURCHASE_BY_ID";
let PURCHASE_BY_ID_SUCCESS = "PURCHASE_BY_ID_SUCCESS";
let RESTART_PURCHASE = "RESTART_PURCHASE";
let DELETE_PURCHASE = "DELETE_PURCHASE";
let DELETE_PURCHASE_SUCCESS = "DELETE_PURCHASE_SUCCESS";


export default function reducer(state = initialState, action) {
    switch (action.type) {
        case LIST_PURCHASE:
            return { ...state, fetching_list: 1, fetching_create: 0, fetching_by_id: 0 }
        case LIST_PURCHASE_SUCCESS:
            return { ...state, fetching_list: 2, data: action.payload, fetching_by_id: 0 }
        case CREATE_PURCHASE:
            return { ...state, fetching_create: 1 }
        case CREATE_PURCHASE_SUCCESS:
            return { ...state, fetching_create: 2 }
        case CREATE_PURCHASE_ERROR:
            return { ...state, fetching_create: 3, errors: action.payload }
        case PURCHASE_BY_ID:
            return { ...state, fetching_by_id: 1, fetching_create: 0 }
        case PURCHASE_BY_ID_SUCCESS:
            return { ...state, fetching_by_id: 2, row: action.payload, fetching_list: 0 }
        case RESTART_PURCHASE:
            return { ...state, fetching_by_id: 0, fetching_list: 0, fetching_create: 0 }
        case DELETE_PURCHASE:
            return { ...state, fetching_create: 1 }
        case DELETE_PURCHASE_SUCCESS:
            return { ...state, fetching_create: 2 }
        default:
            return state;
    }
}

export let restartPurchase = () => (dispatch, getState) => {
    dispatch({
        type: RESTART_PURCHASE,
    });
}

export let getPurchases = () => (dispatch, getState) => {
    dispatch({
        type: LIST_PURCHASE,
    });

    let { date_init, date_end } = JSON.parse(localStorage.getItem("date"))
    let branch = JSON.parse(localStorage.getItem("branch"))

    let url = `${API_URL}/api/purchase?date_init=${date_init}&date_end=${date_end}&branch=${branch.id}`;


    fetch(url, {
        headers: getHeaders()
    }).then(resp => resp.json()).then(resp => {
        dispatch({
            type: LIST_PURCHASE_SUCCESS,
            payload: resp.results
        });

    })
}

export let getPurchaseById = (id) => (dispatch, getState) => {
    dispatch({
        type: PURCHASE_BY_ID,
    });
    let url = `${API_URL}/api/purchase/${id}`;


    fetch(url, {
        headers: getHeaders()
    }).then(resp => resp.json()).then(resp => {
        dispatch({
            type: PURCHASE_BY_ID_SUCCESS,
            payload: resp
        });

    })
}

export let onCreatePurchases = (form) => (dispatch, getState) => {
    dispatch({
        type: CREATE_PURCHASE,
    });
    let url = `${API_URL}/api/purchase`;


    let branch = JSON.parse(localStorage.branch)

    form.branch_id = branch.id

    fetch(url, {
        method: "POST",
        body: JSON.stringify(form),
        headers: getHeaders()
    }).then(resp => resp.json()).then(resp => {
        dispatch({
            type: CREATE_PURCHASE_SUCCESS,
        });

    })
}
export let onEditPurchases = (form) => (dispatch, getState) => {
    dispatch({
        type: CREATE_PURCHASE,
    });
    let url = `${API_URL}/api/purchase/${form.id}`;


    let branch = JSON.parse(localStorage.branch)

    form.branch_id = branch.id

    fetch(url, {
        method: "PUT",
        body: JSON.stringify(form),
        headers: getHeaders()
    }).then(resp => resp.json()).then(resp => {
        dispatch({
            type: CREATE_PURCHASE_SUCCESS,
        });

    })
}

export let onDeletePurchases = (id) => (dispatch, getState) => {
    dispatch({
        type: DELETE_PURCHASE,
    });
    let url = `${API_URL}/api/purchase/${id}`;


    let branch = JSON.parse(localStorage.branch)


    fetch(url, {
        method: "DELETE",
        headers: getHeaders()
    }).then(resp => resp.json()).then(resp => {
        dispatch({
            type: DELETE_PURCHASE_SUCCESS,
        });

    })
}

export let onCreateDirectPurchases = (form) => (dispatch, getState) => {
    dispatch({
        type: CREATE_PURCHASE,
    });
    let url = `${API_URL}/api/purchase-direct`;


    let branch = JSON.parse(localStorage.branch)

    console.log('form', form);


    if (form.supplier_id === 0) {
        dispatch({
            type: CREATE_PURCHASE_ERROR,
            payload: { supplier_id: 'Proveedor requerido' }
        });
        return
    }

    form.branch_id = branch.id

    fetch(url, {
        method: "POST",
        body: JSON.stringify(form),
        headers: getHeaders()
    }).then(resp => resp.json()).then(resp => {
        dispatch({
            type: CREATE_PURCHASE_SUCCESS,
        });

    })
}