import React, { useEffect, useState } from 'react'
import { Col, Form, Row, Table } from 'react-bootstrap';
import TemplateMenu from '../../../template/menu.template'
import { getInventoryCount, deleteInventoryCountById } from '../../../Redux/inventoryDuck'
import { connect } from 'react-redux';
import moment from 'moment';
import ItemTableCounter from '../item.table.counter';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';

const InventoryCountPage = ({ getInventoryCount, fetching_list, data, deleteInventoryCountById, fetching_delete }) => {

    const [list, setList] = useState([]);
    const [form, setForm] = useState({
        product_id: 0,
        registration_date: moment().format("YYYY-MM-DD"),
        daily: false,
        week: false,
        month: false,
    });

    useEffect(() => {
        if (fetching_delete === 2) {
            getInventoryCount(form)
        }
    }, [fetching_delete])

    useEffect(() => {
        getInventoryCount(form)
    }, [])

    useEffect(() => {
        if (fetching_list === 2) {
            setList(data);
        }
    }, [fetching_list]);

    const onDeleteItem = (item) => {
        if (window.confirm("Deseas eliminar el Conteo?")) {
            deleteInventoryCountById(item.id)
        }
    }

    return (
        <TemplateMenu>
            <Row className="my-3">
                <Col lg={3}>
                    <h3>Conteo de Inventario</h3>
                </Col>
                <Col lg={2}>
                    <Link to="/conteo-fisico/new" className='btn btn-success btn-sm'>Crear Conteo Nuevo</Link>
                </Col>
            </Row>
            <Row className="d-flex justify-content-center">
                <Col lg={11}>
                    <Table className="table-bordered table-sm">
                        <thead className="thead-dark">
                            <tr>
                                <th></th>
                                <th>Fecha</th>
                                <th>Usuario</th>
                                <th>Estado</th>
                                <th>Acciones</th>
                            </tr>
                        </thead>
                        <tbody>
                            {list.length > 0 ? list.map((d, k) => <tr key={k}>
                                <td>{d.id}</td>
                                <td>{d.registration_date}</td>
                                <td>{d.user_created}</td>
                                <td>{d.status}</td>
                                <td>
                                    <Link to={`/conteo-fisico/${d.id}`} className="btn btn-sm btn-info mx-3">Editar</Link>
                                    {d.status_id === 1 && <button className='btn btn-sm btn-danger' onClick={() => onDeleteItem(d)}>Borrar</button>}

                                </td>
                            </tr>)
                                : <tr><td colSpan={2}>No hay conteos registrados</td></tr>}
                        </tbody>
                    </Table>
                </Col>
            </Row>
        </TemplateMenu>
    )
}

const mapsState = (props) => {
    let { inventory } = props;

    return {
        fetching_list: inventory.fetching_list,
        fetching_delete: inventory.fetching_delete,
        data: inventory.data,
    }
}

export default connect(mapsState, { getInventoryCount, deleteInventoryCountById })(InventoryCountPage);