import React, { useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import TemplateMenu from '../../template/menu.template'
import FormPublicity from './form.publicity';
import { getPublicityClients } from '../../Redux/clientDuck';
import { onCreatePublicity } from '../../Redux/publicityDuck';
import { toogleLoader } from '../../Redux/alertDuck';
import { validatePhone } from '../../utils';


const CreatePublicityPage = ({ getPublicityClients, clients, fetching_client, onCreatePublicity, toogleLoader, fetching_create }) => {

    const [list, setList] = useState([])

    useEffect(() => {
        toogleLoader(true)
        getPublicityClients()
    }, [])

    useEffect(() => {
        if (fetching_create === 2) {
            toogleLoader(false)
        }
    }, [fetching_create])

    useEffect(() => {
        if (fetching_client === 2) {

            let _clients = clients.map(d => {
                d.validate = validatePhone(d.phone)
                d.checked = false
                return d;
            });

            setList(_clients)
            toogleLoader(false)
        }

    }, [fetching_client])

    const onSuccess = (form) => {
        toogleLoader(true)
        onCreatePublicity(form)
    }

    const onCheckAll = (e) => {

        if (e.target.checked) {
            const updatedItems = list.map((item) => ({
                ...item,
                checked: true,
            }));

            setList(updatedItems);
        } else {
            const updatedItems = list.map((item) => ({
                ...item,
                checked: false,
            }));

            setList(updatedItems);
        }
    }

    return (
        <TemplateMenu>
            <Row className="mt-3">
                <Col>
                    <FormPublicity errors={{}} list_clients={list} onSuccess={onSuccess}
                        checkAll={onCheckAll} />
                </Col>
            </Row>
        </TemplateMenu>
    )
}


const mapsState = (props) => {
    let { client, publicity } = props

    return {
        fetching_client: client.fetching_publicity,
        clients: client.data_publicity,
        fetching_create: publicity.fetching_create,
    }
}
export default connect(mapsState, { getPublicityClients, onCreatePublicity, toogleLoader })(CreatePublicityPage);