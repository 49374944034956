import moment from "moment"

const formatNumber = (number) => {
    return new Intl.NumberFormat('de-DE').format(number)
    // return parseFloat(number).toFixed(1).replace(/\d(?=(\d{3})+\.)/g, '$&,')
}

const capitalize = (s) => {
    if (typeof s !== 'string') return ''
    return s.charAt(0).toUpperCase() + s.slice(1)
}

const formatAddress = (address) => {

    let add = address[1].long_name
    let add2 = address[0].long_name

    let first = add.split(/^(Calle|Carrera|Avenida|Transversal)/).filter(row => !!row);

    first.push(add2.substring(0, 2))
    first.push(add2.substring(2, 4))

    let short = "";

    if (first[0] === 'Calle') {
        short = "Cl."
    }
    if (first[0] === 'Carrera') {
        short = "Cr."
    }
    if (first[0] === 'Transversal') {
        short = "Transv."
    }
    if (first[0] === 'Diagonal') {
        short = "Diag."
    }
    if (first[0] === 'Avenida') {
        short = "Av."
    }

    first.push(short)
    return first;

}

const removeSession = () => {
    localStorage.removeItem("token")
    localStorage.removeItem("storage")
    localStorage.removeItem("date")
    localStorage.removeItem("menu")
    localStorage.removeItem("list_branch")
    localStorage.removeItem("range")
    localStorage.removeItem("branch")
}

function validateUser(form, edit) {
    let err = {};
    edit = edit || false

    // if (form.email === '') {
    //     err.email = "Email Requerida"
    // }

    // if (form.email !== '') {
    //     if (!/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(form.email)) {
    //         err.email = "Email no valido";
    //     }
    // }


    if (!edit) {
        if (form.password === '') {
            err.password = "Clave Requerida"
        }

        if (form.confirmation === '') {
            err.confirmation = "Confirmación Requerida"
        }
    }



    if (form.password !== '' && form.confirmation !== '') {
        if (form.password !== form.confirmation) {
            err.confirmation = "Confirmación debe coincidir con la Clave"
        }
    }

    if (form.name === '') {
        err.name = "Nombres Requeridos"
    }

    if (form.role_id === 0) {
        err.role_id = "Rol Requerida"
    }

    if (form.phone === '') {
        err.phone = "Celular Requerido"
    }

    if (form.business_id === 0) {
        err.business_id = "Cliente es Requerido"
    }
    if (form.branch_id === 0) {
        err.branch_id = "Sucursal es Requerido"
    }

    console.log('err', err)

    return err;
}

function validateBranch(form) {
    let err = {};

    if (form.title === '') {
        err.title = "Titulo Requerida"
    }

    if (form.priority === '') {
        err.priority = "Orden Requerida"
    }
    if (form.address === '') {
        err.address = "Dirección Requerida"
    }

    return err;
}

function validateBusiness(form) {
    let err = {};


    console.log('form', form);


    if (form.business === '') {
        err.business = "Alias es requerido"
    }

    if (form.business_name === '') {
        err.business_name = "Razón social es requerida"
    }
    if (form.document === '') {
        err.document = "Documento es requerido"
    }
    if (form.phone === '' || form.phone === null) {
        err.phone = "Telefono es requerido"
    }
    if (form.economic_activity === '' || form.economic_activity === null) {
        err.economic_activity = "Actividad Economica es requerido"
    }
    if (form.dv === '' || form.dv === null) {
        err.dv = "DV es requerido"
    }



    return err;
}
function validateProduct(form) {
    let err = {};

    if (form.name === '') {
        err.name = "El nombre es requerido"
    }
    if (form.description === '') {
        err.description = "La descripción es requerida"
    }

    if (form.priority === '') {
        err.priority = "La prioridad es requerida"
    }

    return err;
}

export const validatePhone = (phone) => {
    let pattern = /^3[0-9]{9}$/;

    if (pattern.test(phone)) {
        return true;
    }

    return false;

}

export const validateInput = (form, rules) => {
    let err = {}

    for (const [key, value] of Object.entries(form)) {
        rules.forEach(val => {
            if (key === val.field) {
                if (value === null || value === 0 || value.toString().trim() === '') {
                    err[key] = val.msg
                }

                if (val.field === 'email') {
                    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                    if (!emailRegex.test(value)) {
                        err[key] = val.msg || "Correo electrónico inválido";
                    }
                }

                if (val.length !== undefined && Number(val.length) !== value.length) {
                    err[key] = val.msg || `El valor permitido es ${val.length}`;
                }

                if (val.min !== undefined && Number(value) < val.min) {
                    err[key] = val.msg || `El valor mínimo permitido es ${val.min}`;
                }

                if (val.max !== undefined && Number(value) > val.max) {
                    err[key] = val.msg || `El valor máximo permitido es ${val.max}`;
                }
            }



        });
    }

    return Object.keys(err).length === 0 ? null : err
}



function errorLoginServer(error) {
    let err = {}

    if (error.response.status === 422) {
        err = { email: '', phone: '', name: '' }

        for (const [key, value] of Object.entries(error.response.data.errors)) {
            if ('email' === key) {
                err.email = value[0]

                if (value[0] === 'The email has already been taken.') {
                    err.message = "Email ya Existe"
                }

            }
            if ('phone' === key) {
                err.phone = value[0]
                if (value[0] === 'The phone has already been taken.') {
                    err.message = "Número de Teléfono ya Existe"
                }


            }
            if ('name' === key) {
                err.name = value[0]
            }
            if ('password' === key) {
                err.password = value[0]
                if (value[0] === 'The password must be at least 4 characters.') {
                    err.message = "La contraseña debe tener por lo menos 4 Caracteres"
                }
            }
        }
    } else {
        err.message = "Datos Incorrectos"
    }

    return err;
}

const getHeaders = () => {
    return {
        "Authorization": 'Bearer ' + localStorage.token,
        "Content-Type": "application/json",
        "Accept": "application/json",
    }
}
const getHeadersImage = () => {
    return {
        "Authorization": 'Bearer ' + localStorage.token,
        "Content-Type": "multipart/form-data",
        "Accept": "application/json",

    }
}

const getQuantityDays = (date_init, date_end) => {
    return (moment.duration(moment(date_init).diff(moment(date_end).format("YYYY-MM-DD"))).asDays() * -1) + 1
}


const argbToHex = (argb) => {
    // Removemos el prefijo '0x' si está presente
    const hex = argb.toString(16).padStart(8, '0');

    // Extraemos cada componente (Alpha, Red, Green, Blue)
    const alpha = parseInt(hex.substring(0, 2), 16) / 255; // Alpha (opcional)
    const red = parseInt(hex.substring(2, 4), 16);
    const green = parseInt(hex.substring(4, 6), 16);
    const blue = parseInt(hex.substring(6, 8), 16);

    // Convertir a formato HEX
    const hexColor = `#${hex.substring(2)}`;

    // Convertir a formato rgba()
    // const rgbaColor = `rgba(${red}, ${green}, ${blue}, ${alpha.toFixed(2)})`;
    const rgbaColor = `rgba(${red}, ${green}, ${blue}, ${1})`;

    return {
        hex: hexColor,
        rgba: rgbaColor
    };
}

const hexToARGB = (hex, alpha = 1) => {
    // Verificar que el valor hex esté bien formateado
    if (!/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
        throw new Error('Formato HEX inválido');
    }

    // Elimina el carácter '#' si está presente
    hex = hex.replace('#', '');

    // Si el HEX es corto (ejemplo: #FFF), expandirlo a largo (ejemplo: #FFFFFF)
    if (hex.length === 3) {
        hex = hex.split('').map(char => char + char).join('');
    }

    // Convertir Alpha (0 - 1) a hexadecimal (00 - FF)
    const alphaHex = Math.round(alpha * 255).toString(16).padStart(2, '0').toUpperCase();

    // Extraer los componentes de RGB
    const red = hex.substring(0, 2).toUpperCase();
    const green = hex.substring(2, 4).toUpperCase();
    const blue = hex.substring(4, 6).toUpperCase();

    // Formatear el color en ARGB
    const argb = `0x${alphaHex}${red}${green}${blue}`;
    return argb;
}

export {
    formatNumber, capitalize, formatAddress, removeSession, errorLoginServer, validateUser,
    validateBranch, getHeaders, validateBusiness, validateProduct, getHeadersImage,
    getQuantityDays, argbToHex, hexToARGB
}