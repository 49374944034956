import React, { useState } from 'react'
import { Col, Modal, Row, Tab, Tabs } from 'react-bootstrap';
import ListServiceFE from './list.services.fe';
import { formatNumber } from '../../utils';
import uuid from 'react-uuid';

const ModalItemFe = ({ show, listServices, onHide, onConfirmSelected }) => {

    const [total, setTotal] = useState(0)

    const [listSelected, setListSelected] = useState([])

    const onChange = (event, service, item) => {

        const validate = listSelected.find(d => d.item.id === item.id)
        if (event.target.checked === true) {
            if (validate === undefined) {
                let row = {
                    item,
                    uuid: uuid(),
                    quantity: 1,
                    code: "servicio",
                    service: service.name,
                    category: item.category.title,
                    tax_value: item.tax.value,
                    price: item.price,
                    line_extension_amount: item.price * 1,
                    taxable_amount: item.price * 1,
                    tax_amount: item.price * item.tax.value,
                    percent: item.tax.description,
                }

                setListSelected([
                    ...listSelected,
                    row
                ])
            }
        } else {
            const filtered = listSelected.filter(d => d.uid !== item.uid)
            setListSelected(filtered)
        }
    }


    return (
        <Modal show={show} size='lg' onHide={onHide}>

            <Modal.Body>
                <Modal.Title>Agregar Items ({formatNumber(listSelected.reduce((a, b) => a + parseFloat(b.item.price), 0))})</Modal.Title>
                <Row>
                    <Col>
                        <Tabs defaultActiveKey="services" id="uncontrolled-tab-example">
                            <Tab eventKey="services" title="Servicios">
                                <ListServiceFE listServices={listServices} onChange={onChange} />
                            </Tab>
                            <Tab eventKey="store" title="Tienda">
                            </Tab>
                            <Tab eventKey="parking" title="Parqueadero">
                            </Tab>
                        </Tabs>
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <Row>
                    <Col className='text-center'>
                        {listSelected.length > 0 &&
                            <button className='btn btn-sm btn-info' onClick={() => onConfirmSelected(listSelected)}>Agregar</button>}

                    </Col>
                </Row>
            </Modal.Footer>
        </Modal>

    )
}

export default ModalItemFe;