import React, { useState } from 'react'
import { formatNumber } from '../../utils';
import { MdClose, MdBorderColor } from "react-icons/md";
import { MdInsertPhoto } from "react-icons/md";

const ItemTableExpensePayroll = ({ item, onDelete, onEdit, onShowPhoto }) => {
    const [show, setShow] = useState(false)
    const [form, setForm] = useState({ value: 0 })
    let images = item.support !== null ? <MdInsertPhoto size={20} className="cursor-pointer" onClick={() => onShowPhoto(item.support)} /> : ''

    const showEdit = () => {
        setShow(true);
        setForm(item)
    }

    const updateInput = (e) => {
        setForm({
            ...form,
            [e.target.name]: e.target.value
        })
    }

    const onEditPre = () => {
        setShow(false)
        onEdit(form)
    }

    return (
        show === false ?
            <tr className={`${item.deleted_at === null ? '' : 'table-danger'}`}>
                <td>{item.registration_date}</td>
                <td>{item.worker}</td>
                <td>{item.parameter}</td>
                <td>{item.description}</td>
                <td>{item.method_payment}</td>
                <td align='center'>{images}</td>
                <td align='right'>{formatNumber(item.value)}</td>
                <td>
                    {item.deleted_at === null && <>
                        <MdBorderColor className='cursor-pointer mx-3' onClick={() => showEdit()} />
                        <MdClose className='cursor-pointer' onClick={() => onDelete(item)} />
                    </>}
                </td>
            </tr> :
            <tr>
                <td>{item.registration_date}</td>
                <td>{item.parameter}</td>

                <td>
                    <input className='form-control' value={form.description} name="description" onChange={updateInput} />
                </td>
                <td>{item.payment_method}</td>
                <td align='right'>
                    <input type={"number"} className='form-control' value={form.value} name="value" onChange={updateInput} />
                </td>
                <td>
                    <button className='btn btn-info btn-sm mx-3' onClick={() => onEditPre(form)}>Editar</button>
                    <button className='btn btn-danger btn-sm' onClick={() => setShow(false)}>Cancelar</button>

                </td>
            </tr>

    )
}

export default ItemTableExpensePayroll;