import { Col, Row } from "react-bootstrap";
import { formatNumber } from "../../utils";
import moment from "moment";

const ItemTableFE = ({ item, onSelectItem }) => {
    return (
        <tr>
            <td>
                <Row>
                    <Col>#</Col>
                    <Col>{item.id}</Col>
                </Row>
                <Row>
                    <Col><b>Fecha</b></Col>
                    <Col>{moment(item.finish_at).format("YYYY-MM-DD")}</Col>
                </Row>
                <Row>
                    <Col><b>Cliente</b></Col>
                    <Col>{item.client}</Col>
                </Row>
                {(item.observations !== null && item.observations !== '') &&
                    <Row>
                        <Col><b>Observaciones</b></Col>
                        <Col>{item.observations}</Col>
                    </Row>}

            </td>
            <td className='text-right' align="middle">{formatNumber(parseFloat(item.subtotal) + parseFloat(item.total_product))}</td>
            <td className='text-right'>{formatNumber(item.discount)}</td>
            <td className='text-right'>{formatNumber(item.tax)}</td>
            <td className='text-right'>{formatNumber(item.total)}</td>
            <td className='text-right'>
                <input type='checkbox' className="form-control" checked={item.checked} onChange={(e) => onSelectItem(item)} />
            </td>
        </tr>
    )
}

export default ItemTableFE;