import React, { useEffect, useState } from 'react'
import { Card, Col, Container, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { getListSuppplier } from '../../Redux/supplierDuck';
import { onCreatePurchases, onCreateDirectPurchases } from '../../Redux/purchaseDuck';
import TemplateMenu from '../../template/menu.template'
import { useHistory } from 'react-router-dom';
import RowProduct from './row.product';
import ItemDetailPurchase from './item.detail.purchase';
import moment from 'moment';
import { formatNumber } from '../../utils';
import ModalReviewPurchase from './modal.review.purchase';

const CreatePurchasePage = ({ errors, getListSuppplier, fetching_list, suppliers, onCreatePurchases, fetching_create, onCreateDirectPurchases }) => {
    const [form, setForm] = useState({ supplier_id: 0, registration_date: moment().format("YYYY-MM-DD") })
    const history = useHistory()
    const [listSupplier, setListSupplier] = useState([])
    const [selected, setSelected] = useState({});
    const [selectedProduct, setSelectedProduct] = useState({});
    const [listProduct, setListProduct] = useState([]);
    const [listDetail, setListDetail] = useState([]);
    const [show, setShow] = useState(false)
    const [modal, setModal] = useState(false)
    const [total, setTotal] = useState(0)

    useEffect(() => {
        if (fetching_create === 2) {
            setListDetail([])
            history.goBack()
        }
    }, [fetching_create])

    useEffect(() => {
        if (fetching_list === 2) {
            setListSupplier(suppliers)
        }
    }, [fetching_list])

    useEffect(() => {
        getListSuppplier('?status=1')
    }, [])


    const updateInput = (e) => {
        setForm({
            ...form,
            [e.target.name]: e.target.value
        })

        if (e.target.name === 'supplier_id') {
            let _selected = listSupplier.filter(s => s.id === parseInt(e.target.value))[0]
            setSelected(_selected)
        }
    }

    const onAddProduct = () => {
        if (Object.keys(selectedProduct).length > 0) {
            let _list = [
                ...listDetail,
                selectedProduct
            ]
            setListDetail(_list)

            setSelectedProduct({})

            let _total = _list.reduce((a, b) => a = a + parseFloat(b.cost * b.quantity), 0)

            setTotal(_total)
            setShow(false)
        } else {
            setShow(true)
        }
    }

    const onDeleteItem = (item) => {
        let list = listDetail.filter(l => l._id !== item._id);
        let _total = list.reduce((a, b) => a = a + parseFloat(b.amount) * parseFloat(b.cost), 0)

        setTotal(_total)

        setListDetail(list);
    }

    const onAddItem = (item) => {
        let _list = [
            ...listDetail,
            item
        ]

        let _total = _list.reduce((a, b) => a = a + parseFloat(b.amount) * parseFloat(b.cost), 0)

        setTotal(_total)

        setListDetail(_list)
        setShow(false)
    }

    const onSuccessFast = () => {

        if (listDetail.length > 0) {
            setModal(true);
        } else {
            alert("No puedes crear una orden vacia");
        }

    }

    const onEdit = (item) => {
        setShow(true)
        setSelectedProduct(item)
    }

    const onEditItem = (item) => {
        let _list = listDetail.map(d => {
            if (item._id === d._id) {
                d.amount = item.amount;
                d.cost = item.cost;
            }
            return d
        })

        setListDetail(_list)
        setSelectedProduct({})
        setShow(false)
    }

    const onConfirmPurchase = () => {
        let _form = form;
        _form.list_products = listDetail;
        onCreateDirectPurchases(_form);
    }

    return (
        <TemplateMenu>
            <Container fluid>
                <Row className='my-2'>
                    <Col>
                        <button className='btn btn-sm btn-secondary' onClick={() => history.goBack()}>Volver</button>
                    </Col>
                </Row>
                <Row className='d-flex justify-content-center'>
                    <Col lg={8}>

                        <Row className='my-2'>
                            <Col lg={6}><h3 className='text-center' >Realizar Compra</h3></Col>
                            <Col className='d-flex justify-content-end'>
                                <button className='btn btn-info btn-sm mx-2' onClick={onSuccessFast}>Crear Orden de Compra con Inventario</button>
                                {/* <button className='btn btn-success btn-sm' onClick={onSuccess}>Crear Orden de Compra</button> */}
                            </Col>
                        </Row>
                        <Row className='mt-2'>
                            <Col>
                                <Row>
                                    <Col><div className="form-group">
                                        <label>Proveedor</label>
                                        <select className='form-control' name='supplier_id' value={form.supplier_id} onChange={updateInput}>
                                            <option value="0">Seleccione</option>
                                            {listSupplier.map(m => <option key={m.id} value={m.id}>{m.business} doc:({m.document})</option>)}
                                        </select>
                                        {errors.supplier_id !== undefined && (<label className="error-label">{errors.supplier_id}</label>)}
                                    </div></Col>
                                </Row>
                                <Row>
                                    <Col><div className="form-group">
                                        <label>Fecha de Registro</label>
                                        <input type={"date"} className="form-control" name='registration_date' value={form.registration_date} onChange={updateInput} />
                                    </div>
                                    </Col>
                                </Row>
                            </Col>
                            <Col>
                                <Card>
                                    <Card.Header>
                                        <Card.Title>Información Proveedor</Card.Title>
                                    </Card.Header>
                                    <Card.Body>

                                        <Row>
                                            <Col><b>Razon Social</b></Col>
                                            <Col>{selected.business}</Col>
                                        </Row>
                                        <Row>
                                            <Col><b>Documento</b></Col>
                                            <Col>{selected.document}</Col>
                                        </Row>
                                        <Row>
                                            <Col><b>Productos Registrados</b></Col>
                                            <Col>{listProduct.length}</Col>
                                        </Row>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                        <Row className='mt-4'>
                            <Col className=''>
                                <table className='table table-sm table-bordered'>
                                    <thead className='thead-dark'>
                                        <tr>
                                            <th width="30%">Producto</th>
                                            <th>Cantidad</th>
                                            <th align='right'>Costo</th>
                                            <th align='right'>Imp</th>
                                            <th align='right'>SubTotal</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {listDetail.map((d, key) =>
                                            <ItemDetailPurchase item={d} key={key} onEdit={onEdit} onDelete={onDeleteItem}
                                                listProduct={listProduct} />)}
                                        {(show === true) && <RowProduct
                                            onEditItem={onEditItem}
                                            formEdit={selectedProduct}
                                            listProduct={listProduct}
                                            onAddItem={onAddItem}
                                            onDelete={() => {
                                                setSelectedProduct({})
                                                setShow(false)
                                            }} />}
                                        <tr>
                                            <td colSpan={7}> {
                                                !show &&
                                                <button className='btn btn-sm btn-info' onClick={onAddProduct}>Agregar Producto</button>}</td>
                                        </tr>

                                    </tbody>
                                    <tfoot>
                                        <tr>
                                            <td colSpan={4} align="right">TOTAL</td>
                                            <td align="right">{formatNumber(total)}</td>
                                        </tr>
                                    </tfoot>
                                </table>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <ModalReviewPurchase show={modal} listDetail={listDetail} onSuccess={onConfirmPurchase} onHide={() => setModal(false)} />
            </Container >

        </TemplateMenu >
    )
}

const mapsState = (props) => {
    let { supplier, purchase } = props
    return {
        fetching_list: supplier.fetching_list,
        suppliers: supplier.data,
        errors: supplier.errors,
        fetching_create: purchase.fetching_create
    }
}

export default connect(mapsState, { getListSuppplier, onCreatePurchases, onCreateDirectPurchases })(CreatePurchasePage);