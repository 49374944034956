
import axios from 'axios'
import { getHeaders, getHeadersImage } from '../utils'

const API_URL = process.env.REACT_APP_API_URL

let initialState = {
    fetching: 0,
    fetching_list: 0,
    fetching_credit: 0,
    fetching_by_id: 0,
    fetching_payment: 0,
    fetching_delete: 0,
    data: [],
    data_credit: [],
    row: {},
    errors: {},
}

const LIST_BRIEFCASE = "LIST_BRIEFCASE"
const LIST_BRIEFCASE_SUCCESS = "LIST_BRIEFCASE_SUCCESS"
const LIST_CREDIT = "LIST_CREDIT"
const LIST_CREDIT_SUCCESS = "LIST_CREDIT_SUCCESS"
const CREATE_BRIEFCASE = "CREATE_BRIEFCASE"
const CREATE_BRIEFCASE_SUCCESS = "CREATE_BRIEFCASE_SUCCESS"
const BRIEFCASE_BY_ID = "BRIEFCASE_BY_ID"
const BRIEFCASE_BY_ID_SUCCESS = "BRIEFCASE_BY_ID_SUCCESS"
const PAYMENT_BRIEFCASE = "PAYMENT_BRIEFCASE"
const PAYMENT_BRIEFCASE_SUCCESS = "PAYMENT_BRIEFCASE_SUCCESS"
const DELETE_BRIEFCASE = "DELETE_BRIEFCASE"
const DELETE_BRIEFCASE_SUCCESS = "DELETE_BRIEFCASE_SUCCESS"
const REVERSE_BRIEFCASE = "REVERSE_BRIEFCASE"
const REVERSE_BRIEFCASE_SUCCESS = "REVERSE_BRIEFCASE_SUCCESS"


export default function reducer(state = initialState, action) {
    switch (action.type) {
        case LIST_BRIEFCASE:
            return { ...state, fetching_list: 1, }
        case LIST_BRIEFCASE_SUCCESS:
            return { ...state, fetching_list: 2, data: action.payload }
        case LIST_CREDIT:
            return { ...state, fetching_credit: 1, }
        case LIST_CREDIT_SUCCESS:
            return { ...state, fetching_credit: 2, data_credit: action.payload }
        case CREATE_BRIEFCASE:
            return { ...state, fetching: 1 }
        case CREATE_BRIEFCASE_SUCCESS:
            return { ...state, fetching: 2, row: action.payload }
        case BRIEFCASE_BY_ID:
            return { ...state, fetching_by_id: 1 }
        case BRIEFCASE_BY_ID_SUCCESS:
            return { ...state, fetching_by_id: 2, row: action.payload }
        case PAYMENT_BRIEFCASE:
            return { ...state, fetching_payment: 1 }
        case PAYMENT_BRIEFCASE_SUCCESS:
            return { ...state, fetching_payment: 2 }
        case DELETE_BRIEFCASE:
            return { ...state, fetching_delete: 1 }
        case DELETE_BRIEFCASE_SUCCESS:
            return { ...state, fetching_delete: 2 }
        case REVERSE_BRIEFCASE:
            return { ...state, fetching_delete: 1 }
        case REVERSE_BRIEFCASE_SUCCESS:
            return { ...state, fetching_delete: 2 }
        default:
            return state;
    }
}

export let getListBriefcase = (status_id) => (dispatch, getState) => {
    dispatch({
        type: LIST_BRIEFCASE
    })

    if (getState().user.loggedIn) {

        let { date_init, date_end } = JSON.parse(localStorage.date)

        let branch = JSON.parse(localStorage.getItem("branch"))

        fetch(`${API_URL}/api/briefcase?branch_id=${branch.id}&date_init=${date_init}&date_end=${date_end}&status_id=${status_id}`, {
            headers: getHeaders()
        })
            .then(resp => resp.json())
            .then(resp => {
                dispatch({
                    type: LIST_BRIEFCASE_SUCCESS,
                    payload: resp.results
                });

            }).catch(function (error) {
                console.log(error);
            });
    } else {
        console.log("no Ingreso");
    }
}

export let getListCredit = (form) => (dispatch, getState) => {
    dispatch({
        type: LIST_CREDIT
    })

    if (getState().user.loggedIn) {

        // let { date_init, date_end } = JSON.parse(localStorage.date)

        let branch = JSON.parse(localStorage.getItem("branch"))

        fetch(`${API_URL}/api/briefcase-credit?branch_id=${branch.id}&date_init=${form.date_init}&date_end=${form.date_end}`, {
            headers: getHeaders()
        })
            .then(resp => resp.json())
            .then(resp => {
                dispatch({
                    type: LIST_CREDIT_SUCCESS,
                    payload: resp
                });

            }).catch(function (error) {
                console.log(error);
            });
    } else {
        console.log("no Ingreso");
    }
}

export let onCreateOrUpdateBriefcase = (form) => (dispatch, getState) => {
    dispatch({
        type: CREATE_BRIEFCASE
    })

    if (getState().user.loggedIn) {
        let branch = JSON.parse(localStorage.getItem("branch"))
        form.branch_id = branch.id

        let url = `${API_URL}/api/briefcase`
        let method = "POST"

        if (form.id !== undefined) {
            url = `${API_URL}/api/briefcase/${form.id}`
            method = "PUT";
        }

        fetch(url, {
            method,
            body: JSON.stringify(form),
            headers: getHeaders()
        })
            .then(resp => resp.json())
            .then(resp => {
                dispatch({
                    type: CREATE_BRIEFCASE_SUCCESS,
                    payload: resp
                });

            }).catch(function (error) {
                console.log(error);
            });
    } else {
        console.log("no Ingreso");
    }
}

export let onPaymentBriefcase = (form) => (dispatch, getState) => {
    dispatch({
        type: PAYMENT_BRIEFCASE
    })

    if (getState().user.loggedIn) {
        let branch = JSON.parse(localStorage.getItem("branch"))
        form.branch_id = branch.id

        let url = `${API_URL}/api/briefcase-payment`
        let method = "POST"

        fetch(url, {
            method,
            body: JSON.stringify(form),
            headers: getHeaders()
        })
            .then(resp => resp.json())
            .then(resp => {
                dispatch({
                    type: PAYMENT_BRIEFCASE_SUCCESS,
                });

            }).catch(function (error) {
                console.log(error);
            });
    } else {
        console.log("no Ingreso");
    }
}

export let onFirmBriefcase = (form) => (dispatch, getState) => {
    dispatch({
        type: CREATE_BRIEFCASE
    })

    if (getState().user.loggedIn) {

        let branch = JSON.parse(localStorage.getItem("branch"))

        form.branch_id = branch.id

        let formData = new FormData()

        for (const [key, value] of Object.entries(form)) {
            formData.append(key, value);
        }

        let url = `${API_URL}/api/briefcase-firm`

        axios.post(url, formData, {
            headers: getHeadersImage()
        }).then(resp => {
            dispatch({
                type: CREATE_BRIEFCASE_SUCCESS,
                payload: resp
            });

        }).catch(function (error) {
            console.log(error);
        });
    } else {
        console.log("no Ingreso");
    }
}


export let getListBriefcaseById = (id) => (dispatch, getState) => {
    dispatch({
        type: BRIEFCASE_BY_ID
    })

    if (getState().user.loggedIn) {



        fetch(`${API_URL}/api/briefcase/${id}`, {
            headers: getHeaders()
        })
            .then(resp => resp.json())
            .then(resp => {
                dispatch({
                    type: BRIEFCASE_BY_ID_SUCCESS,
                    payload: resp
                });

            }).catch(function (error) {
                console.log(error);
            });
    } else {
        console.log("no Ingreso");
    }
}

export let onDeleteBriefcase = (id) => (dispatch, getState) => {
    dispatch({
        type: DELETE_BRIEFCASE
    })

    if (getState().user.loggedIn) {



        fetch(`${API_URL}/api/briefcase/${id}`, {
            method: "DELETE",
            headers: getHeaders()
        })
            .then(resp => resp.json())
            .then(resp => {
                dispatch({
                    type: DELETE_BRIEFCASE_SUCCESS,
                    payload: resp
                });

            }).catch(function (error) {
                console.log(error);
            });
    } else {
        console.log("no Ingreso");
    }
}
export let onReverseBriefcase = (id) => (dispatch, getState) => {
    dispatch({
        type: REVERSE_BRIEFCASE
    })

    if (getState().user.loggedIn) {
        fetch(`${API_URL}/api/briefcase-reverse/${id}`, {
            method: "PUT",
            headers: getHeaders()
        })
            .then(resp => resp.json())
            .then(resp => {
                dispatch({
                    type: REVERSE_BRIEFCASE_SUCCESS,
                    payload: resp
                });

            }).catch(function (error) {
                console.log(error);
            });
    } else {
        console.log("no Ingreso");
    }
}