import React from 'react'
import { Card, Col, Row } from 'react-bootstrap';
import InputField from '../../Components/Widgets/InputField';

const ResolutionInfoPage = ({ formBusiness, storeResolutionForm, resolutionSelected, onChangeResolution,
    listResolution, formResolution, listTypeDocument, updateInputResolution
}) => {
    return (
        <Row className='d-flex justify-content-center'>
            <Col lg={5}>
                <Card>
                    <Card.Header>
                        <Row>
                            <Col><Card.Title>Resolución</Card.Title></Col>
                            <Col className='d-flex justify-content-end'>
                                {formBusiness.api_token !== '' &&
                                    <button className='btn btn-sm btn-success' onClick={storeResolutionForm}>Guardar</button>
                                }
                            </Col>
                        </Row>
                    </Card.Header>
                    <Card.Body>
                        <Row>
                            <Col>
                                <div className="form-group">
                                    <label>Resoluciones</label>
                                    <select className='form-control'
                                        value={resolutionSelected}
                                        onChange={onChangeResolution}>
                                        <option value={0}>Seleccione</option>
                                        {listResolution.map((a, b) => <option key={a.id} value={a.id}>{a.prefix} {a.resolution} {a.from}-{a.to}</option>)}
                                    </select>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <div className="form-group">
                                    <label>Tipo de Documento</label>
                                    <select className='form-control'
                                        name='type_document_id'
                                        value={formResolution.type_document_id}
                                        onChange={updateInputResolution}>
                                        <option value={0}>Seleccione</option>
                                        {listTypeDocument.map((a, b) => <option key={a.id} value={a.id}>{a.name}</option>)}

                                    </select>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <div className="form-group">
                                    <label>Prefix</label>
                                    <InputField
                                        placeholder="Password"
                                        value={formResolution.prefix || ''}
                                        name="prefix"
                                        onChange={updateInputResolution}
                                    // error={errors.business}
                                    />
                                </div>
                            </Col>
                            <Col>
                                <div className="form-group">
                                    <label>Resolución</label>
                                    <InputField
                                        placeholder="Resolución"
                                        value={formResolution.resolution || ''}
                                        name="resolution"
                                        onChange={updateInputResolution}
                                    // error={errors.business}
                                    />
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <div className="form-group">
                                    <label>Fecha de Resolución</label>
                                    <InputField
                                        type={"date"}
                                        placeholder="Fecha de Resolución"
                                        value={formResolution.resolution_date || ''}
                                        name="resolution_date"
                                        onChange={updateInputResolution}
                                    // error={errors.business}
                                    />
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <div className="form-group">
                                    <label>Llave Tecnica</label>
                                    <InputField
                                        placeholder="Llave Tecnica"
                                        value={formResolution.technical_key || ''}
                                        name="technical_key"
                                        onChange={updateInputResolution}
                                    // error={errors.business}
                                    />
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <div className="form-group">
                                    <label>Desde</label>
                                    <InputField
                                        placeholder="Desde"
                                        value={formResolution.from || ''}
                                        name="from"
                                        onChange={updateInputResolution}
                                    // error={errors.business}
                                    />
                                </div>
                            </Col>
                            <Col>
                                <div className="form-group">
                                    <label>Hasta</label>
                                    <InputField
                                        placeholder="Hasta"
                                        value={formResolution.to || ''}
                                        name="to"
                                        onChange={updateInputResolution}
                                    // error={errors.business}
                                    />
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <div className="form-group">
                                    <label>Fecha Desde</label>
                                    <InputField
                                        type={"date"}
                                        placeholder="Fecha Desde"
                                        value={formResolution.date_from || ''}
                                        name="date_from"
                                        onChange={updateInputResolution}
                                    // error={errors.business}
                                    />
                                </div>
                            </Col>
                            <Col>
                                <div className="form-group">
                                    <label>Fecha Hasta</label>
                                    <InputField
                                        type={"date"}
                                        placeholder="Fecha Hasta"
                                        value={formResolution.date_to || ''}
                                        name="date_to"
                                        onChange={updateInputResolution}
                                    // error={errors.business}
                                    />
                                </div>
                            </Col>
                        </Row>

                        <Row>
                            <Col lg={6}>
                                <div className="form-group">
                                    <label>Fecha Generada</label>
                                    <InputField
                                        placeholder="Fecha Generada"
                                        value={formResolution.generated_to_date || ''}
                                        name="generated_to_date"
                                        onChange={updateInputResolution}
                                    // error={errors.business}
                                    />
                                </div>
                            </Col>
                        </Row>

                    </Card.Body>
                </Card>
            </Col>
        </Row>
    )
}

export default ResolutionInfoPage;