import React, { useRef } from 'react'
import { Table } from 'react-bootstrap';
import { DownloadTableExcel } from 'react-export-table-to-excel';
import { RiFileExcel2Fill } from 'react-icons/ri';
import { formatNumber } from '../../../utils';

const ReviewPayrollList = ({ listData }) => {
    const table = useRef(null)
    return (
        <Table className='table table-sm table-bordered table-hover table-striped' ref={table}>
            <thead className='thead-dark'>
                <tr>
                    <th colSpan={4} className="text-center">
                        <span className='px-4'>Reporte Nomina</span>
                        <DownloadTableExcel
                            className="px-5"
                            filename="total_servicios"
                            sheet="orders"
                            currentTableRef={table.current}>
                            <RiFileExcel2Fill color='green' className='cursor-pointer' />
                        </DownloadTableExcel>
                    </th>
                </tr>
                <tr>
                    <th>Trabajador</th>
                    <th>Método de pago</th>
                    <th>Valor</th>
                </tr>
            </thead>
            <tbody>
                {listData.length === 0 ? <tr><td colSpan={5} align='center'>Sin datos</td></tr> :
                    listData.map((row, i) => <tr key={i}>
                        <td>{row.worker}</td>
                        <td>{row.payment_method}</td>
                        <td align='right'>{formatNumber(row.total)}</td>
                    </tr>)}
            </tbody>
            <tfoot>
                <tr>
                    <th colSpan={2}>TOTAL</th>
                    <th className='text-right'>{formatNumber(listData.reduce((a, b) => a + parseFloat(b.total), 0))}</th>
                </tr>
            </tfoot>
        </Table>
    )
}

export default ReviewPayrollList;