import React, { useEffect, useRef, useState } from 'react'
import { Col, Row, Table } from 'react-bootstrap';
import TemplateMenu from '../../template/menu.template'
import { getListOrders, onChangePayment } from '../../Redux/orderDuck'
import { getParameterBySegment } from '../../Redux/parameterDuck'
import { getOperators, getAdministrators } from '../../Redux/userDuck'
import { getCategories } from '../../Redux/categoryDuck'
import { initService } from '../../Redux/serviceDuck'
import { getTurns } from '../../Redux/turnDuck';
import { connect } from 'react-redux';
import ItemTable from './item.table';
import moment from 'moment';
import { RiFileExcel2Fill } from "react-icons/ri";
import { DownloadTableExcel } from 'react-export-table-to-excel';
import ModalPhotos from './modal.photos';
import { formatNumber } from '../../utils';
import ModalPaymentCredit from './modal.payment';
import { Link } from 'react-router-dom';
import { toogleAlert } from '../../Redux/alertDuck'


const OrderPage = ({ getListOrders, fetching_list, data, getParameterBySegment, fetching_segment, data_segment, getOperators, fetching_operator, data_operator,
    getCategories, fetching_category, data_category, getAdministrators, fetching_admin, data_administrator, onChangePayment, fetching_payment, initService, getTurns,
    fetching_list_turn, data_turn, toogleAlert }) => {

    const [list, setList] = useState([]);
    const [listFilter, setListFilter] = useState([]);
    const [listMethods, setListMethods] = useState([]);
    const [listOperator, setListOperator] = useState([]);
    const [listCategory, setListCategory] = useState([]);
    const [listAdmin, setListAdmin] = useState([]);
    const [moreFilter, setMoreFilter] = useState(false);
    const [form, setForm] = useState({
        date_init: moment().format("YYYY-MM-01"), date_end: moment().format("YYYY-MM-DD"), status_id: 0, operator_id: 0, payment_id: 0,
        category_id: 0, administrator_id: 0, plate: '', turn_id: '0', order_id: ''
    });

    const [listPhotos, setListPhotos] = useState([])
    const [listTurn, setListTurn] = useState([])
    const [modal, setModal] = useState(false)
    const [modalPayment, setModalPayment] = useState(false)
    const [dataPayment, setDataPayment] = useState({})
    const [total, setTotal] = useState(0)
    const [totalToPay, setTotalToPay] = useState(0)
    const [totalTip, setTotalTip] = useState(0)
    const [totalTax, setTotalTax] = useState(0)

    const tableRef = useRef(null);

    useEffect(() => {
        if (fetching_list === 2) {
            setListFilter(data)
            setList(data)

            let _total = data.filter(d => d.status_id === 3).reduce((a, b) => a + parseFloat(b.detail.reduce((c, d) => c + parseFloat(d.price), 0)), 0)
            let _totalToPay = data.filter(d => d.status_id === 3).reduce((a, b) => a + parseFloat(b.detail.reduce((c, d) => c + (parseFloat(d.price) * parseFloat(d.percent / 100)), 0)), 0)
            let _totalTip = data.filter(d => d.status_id === 3).reduce((a, b) => a + parseFloat(b.tip), 0)
            let _totalTax = data.filter(d => d.status_id === 3).reduce((a, b) => a + parseFloat(b.tax), 0)

            setTotalTax(_totalTax)
            setTotalTip(_totalTip)
            setTotalToPay(_totalToPay)
            setTotal(_total)
            toogleAlert(false)

        }
    }, [fetching_list])

    useEffect(() => {
        if (fetching_list_turn === 2) {
            toogleAlert(false)
            setListTurn(data_turn)
        }
    }, [fetching_list_turn])

    useEffect(() => {
        if (fetching_admin === 2) {
            setListAdmin(data_administrator)
        }
    }, [fetching_admin])

    useEffect(() => {
        if (fetching_payment === 2) {

            setModalPayment(false)
            getListOrders()

        }
    }, [fetching_payment])

    useEffect(() => {
        if (fetching_category === 2) {
            setListCategory(data_category)
        }
    }, [fetching_category])

    useEffect(() => {
        if (fetching_operator === 2) {
            setListOperator(data_operator)
        }
    }, [fetching_operator])

    useEffect(() => {
        if (fetching_segment === 2) {
            setListMethods(data_segment)
        }
    }, [fetching_segment])

    useEffect(() => {
        toogleAlert(true)
        getParameterBySegment("payment")
        getOperators();
        getCategories();
        getAdministrators()
        initService()
        getTurns()
    }, [])

    const updateField = (e) => {
        setForm({
            ...form,
            [e.target.name]: e.target.value
        })
    }
    const onFilter = () => {
        toogleAlert(true)
        getListOrders(form)
    }

    const onShowPhotos = (photos) => {
        setListPhotos(photos)
        setModal(true)
    }

    const onPayment = (item) => {
        setModalPayment(true)
        setDataPayment(item)
    }

    const onSuccessPayment = (form) => {
        onChangePayment(form)
    }
    const onFilterDelete = (e) => {
        toogleAlert(true)

        if (e.target.checked === true) {
            setListFilter(list.filter(d => d.status_id !== 5))
        } else {
            setListFilter(list)
        }
        toogleAlert(false)

    }

    const onMoreFilter = (e) => {
        setMoreFilter(e.target.checked)
    }

    return (
        <TemplateMenu>
            <Row className='my-3'>
                <Col lg={2}><h3>Historial de Ordenes</h3></Col>
                <Col>
                    <Link to={`/ordenes/new`} className="btn btn-success btn-sm">Crear Orden</Link>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Row>
                        <Col lg={3}>
                            <div className="form-group">
                                <label>Turnos</label>
                                <select className='form-control' name='turn_id' value={form.turn_id} onChange={updateField}>
                                    <option value={0}>Rango de Fecha</option>
                                    {listTurn.map((d, k) => <option key={k} value={d.id}>({d.id}){d.user.name} | {d.date_start} | {d.date_end}</option>)}
                                </select>
                            </div>
                        </Col>
                        <Col lg={1}>
                            <div className="form-group">
                                <label>Orden</label>
                                <input className='form-control' placeholder='Orden' name="order_id" value={form.order_id} onChange={updateField} />
                            </div>
                        </Col>
                        <Col lg={1}>
                            <div className="form-group">
                                <label>Buscar Placa</label>
                                <input className='form-control' placeholder='Placa' name="plate" value={form.plate} onChange={updateField} />
                            </div>
                        </Col>
                        {form.turn_id === '0' && <>
                            <Col>
                                <div className="form-group">
                                    <label>Fecha de Inicio</label>
                                    <input
                                        type="date"
                                        className="form-control"
                                        value={form.date_init}
                                        name="date_init"
                                        onChange={updateField}
                                    />
                                </div>
                            </Col>
                            <Col>
                                <div className="form-group">
                                    <label>Fecha Fin</label>
                                    <input
                                        type="date"
                                        className="form-control"
                                        value={form.date_end}
                                        name="date_end"
                                        onChange={updateField}
                                    />
                                </div>
                            </Col>
                        </>}

                        <Col lg={2} className="d-flex align-items-center">
                            <button className='btn btn-sm btn-warning' onClick={onFilter}>Filtrar</button>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={2}>
                            <div className="form-group">
                                <label>Mas filtros</label>
                                <input type='checkbox' className='form-control' checked={moreFilter} onChange={onMoreFilter} />
                            </div>
                        </Col>
                        {moreFilter && <>
                            <Col>
                                <div className="form-group">
                                    <label>Métodos de Pago</label>
                                    <select className='form-control' name='payment_id' value={form.payment_id} onChange={updateField}>
                                        <option value={0}>Todos</option>
                                        {listMethods.map((d, k) => <option key={k} value={d.id}>{d.description}</option>)}
                                    </select>
                                </div>
                            </Col>
                            <Col>
                                <div className="form-group">
                                    <label>Administradores</label>
                                    <select className='form-control' name='administrator_id' value={form.administrator_id} onChange={updateField}>
                                        <option value={0}>Seleccione</option>
                                        {listAdmin.map((d, k) => <option key={k} value={d.id}>{d.name}</option>)}

                                    </select>
                                </div>
                            </Col>
                            <Col>
                                <div className="form-group">
                                    <label>Trabajadores</label>
                                    <select className='form-control' name='operator_id' value={form.operator_id} onChange={updateField}>
                                        <option value={0}>Seleccione</option>
                                        {listOperator.map((d, k) => <option key={k} value={d.id}>{d.name}</option>)}
                                    </select>
                                </div>
                            </Col>
                            <Col>
                                <div className="form-group">
                                    <label>Estado</label>
                                    <select className='form-control' name='status_id' value={form.status_id} onChange={updateField}>
                                        <option value={0}>Todos</option>
                                        <option value={1}>En Proceso</option>
                                        <option value={2}>Finalizado</option>
                                        <option value={3}>Pagados</option>
                                        <option value={4}>Pagados y Liquidados</option>
                                    </select>
                                </div>
                            </Col>
                            <Col>
                                <div className="form-group">
                                    <label>Categoria</label>
                                    <select className='form-control' name='category_id' value={form.category_id} onChange={updateField}>
                                        <option value={0}>Todos</option>
                                        {listCategory.map((d, k) => <option key={k} value={d.id}>{d.description}</option>)}
                                    </select>
                                </div>
                            </Col>
                            <Col lg={2}>
                                <div className="form-group">
                                    <label>Quitar eliminado</label>
                                    <input type='checkbox' className='form-control' placeholder='Quitar eliminado' onChange={onFilterDelete} />
                                </div>
                            </Col>
                        </>}

                        <Col lg={2} className='d-flex align-items-center'>
                            <DownloadTableExcel
                                filename="orders"
                                sheet="orders"
                                currentTableRef={tableRef.current}>
                                <RiFileExcel2Fill color='green' size={30} className='cursor-pointer' />
                                {/* <button className='btn btn-sm btn-info'> Export excel </button> */}
                            </DownloadTableExcel>
                        </Col>
                    </Row>
                </Col>

            </Row>
            <Row className='my-1'>

                {/* <Col>
                    <button className='btn btn-sm btn-warning'>Crear Cuenta de Cobro</button>
                </Col> */}
            </Row>
            <Row>
                <Col className='table-responsive'>
                    <Table className='table-sm table-bordered table-hover' ref={tableRef}>
                        <thead className='thead-dark'>
                            <tr>
                                <th></th>
                                <th>Fecha</th>
                                <th>#</th>
                                <th>Orden</th>
                                <th>Cliente</th>
                                <th>Telefono</th>
                                <th>#Servicios</th>
                                <th>Vehículo</th>
                                <th>Placa</th>
                                <th>Método</th>
                                <th>Fotos</th>
                                <th>Descuento</th>
                                <th>SubTotal</th>
                                <th>Productos</th>
                                <th>Total</th>
                                <th>Imp</th>
                                <th>Prop</th>
                                <th>Total a Pagar</th>
                                <th>Comentario</th>
                                <th>Operador</th>
                                <th>Estado</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {(listFilter.length > 0) ? listFilter.map((l, k) => <ItemTable key={k} index={k + 1} item={l}
                                onShowPhotos={onShowPhotos} onPayment={onPayment} />) : <tr align="center"><td colSpan={22}>Ordenes no encontradas</td></tr>}
                        </tbody>
                        <tfoot>
                            <tr>
                                <td colSpan={11}><b>TOTAL</b></td>
                                <td align='right'>{formatNumber(listFilter.filter(d => d.status_id !== 5).reduce((a, b) => a + parseFloat(b.discount), 0))}</td>
                                <td align='right'>{formatNumber(listFilter.filter(d => d.status_id !== 5).reduce((a, b) => a + parseFloat(b.total_product || 0), 0))}</td>
                                <td align='right'>{formatNumber(listFilter.filter(d => d.status_id !== 5).reduce((a, b) => a + parseFloat(b.total), 0))}</td>
                                <td align='right'>{formatNumber(listFilter.filter(d => d.status_id !== 5).reduce((a, b) => a + parseFloat(b.total) - parseFloat(b.discount), 0))}</td>
                                <td align='right'>{formatNumber(totalTax)}</td>
                                <td align='right'>{formatNumber(totalTip)}</td>
                                <td align='right'>{formatNumber(totalToPay)}</td>
                            </tr>
                        </tfoot>
                    </Table>
                </Col>
            </Row>
            <ModalPhotos show={modal} photos={listPhotos} onHide={() => setModal(false)} />
            <ModalPaymentCredit show={modalPayment} data={dataPayment} onSuccess={onSuccessPayment} listMethods={listMethods} onHide={() => setModalPayment(false)} />
        </TemplateMenu>
    )
}

const mapsState = (props) => {
    let { order, parameter, user, category, turn } = props

    return {
        fetching_list: order.fetching_list,
        data: order.data,
        fetching_payment: order.fetching_payment,
        fetching_segment: parameter.fetching_segment,
        data_segment: parameter.data_segment,
        fetching_operator: user.fetching_operator,
        data_operator: user.data_operator,
        fetching_admin: user.fetching_admin,
        data_administrator: user.data_administrator,
        fetching_category: category.fetching,
        data_category: category.data,
        fetching_list_turn: turn.fetching_list,
        data_turn: turn.data,
    }
}

export default connect(mapsState, {
    getListOrders, getParameterBySegment, getOperators, getCategories, getAdministrators,
    onChangePayment, initService, getTurns, toogleAlert
})(OrderPage);