import React, { useEffect, useState } from 'react'
import { formatNumber } from '../../utils'
import Loading from '../Loader/loading'
import { Col, Row } from 'react-bootstrap'

const RowTableInitial = ({ item, onUpdateRow, index }) => {
    const [form, setForm] = useState({ amount: 0, cost: 0 })


    useEffect(() => {
        setForm({
            ...form,
            "amount": item.amount,
            "cost": item.cost
        })
    }, [item])

    const updateField = (e) => {
        setForm({
            ...form,
            [e.target.name]: e.target.value
        })
    }
    const updateFieldBlur = (e) => {
        let _form = form
        _form.id = item.id
        _form.inventory_id = item.inventory_id

        onUpdateRow(_form)
    }


    return (
        <tr>
            <td>{index}</td>
            <td>
                <Row>
                    <Col>{item.product}</Col>
                </Row>
                <Row>
                    <Col className='text-muted font-11'>{item.description}</Col>
                </Row>
            </td>
            <td>{item.measure_unit}</td>
            <td>
                <div className='d-flex'>
                    <input type={"number"} className='form-control input-sm text-right mr-3' min={0} name="amount" value={form.amount} onChange={updateField}
                        onBlur={updateFieldBlur}
                    />
                    {item.Loading && <Loading />}

                </div>
            </td>
            <td>
                <div className='d-flex'>
                    <input type={"number"} className='form-control input-sm text-right mr-3' name='cost' value={form.cost} onChange={updateField}
                        onBlur={updateFieldBlur} />
                    {/* <Loading /> */}
                </div>
            </td>
            <td valign="middle" align='right'>{formatNumber(item.cost * item.amount)}</td>

        </tr>
    )
}

export default RowTableInitial;