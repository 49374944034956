import axios from 'axios'
import { getHeaders } from '../utils';
import { addItem, getLocalItems } from '../hooks/indexedDB'

const API_URL = process.env.REACT_APP_API_URL

let initialState = {
    fetching: 0,
    fetching_create: 0,
    fetching_admin: 0,
    fetching_assoc: 0,
    fetching_price: 0,
    fetching_by_id: 0,
    data: [],
    row: {},
    data_admin: [],
    selected: {},
    errors: {},
    fetching_delete: 0
}

let LIST_CATEGORY = "LIST_CATEGORY";
let LIST_CATEGORY_SUCCESS = "LIST_CATEGORY_SUCCESS";
let LIST_CATEGORY_ADMIN = "LIST_CATEGORY_ADMIN";
let LIST_CATEGORY_ADMIN_SUCCESS = "LIST_CATEGORY_ADMIN_SUCCESS";
let CATEGORY_ERROR = "CATEGORY_ERROR";
let CATEGORY_SELECTED = "CATEGORY_SELECTED";
let CATEGORY_CLEAR = "CATEGORY_CLEAR";
let CREATE_CATEGORY = "CREATE_CATEGORY"
let CREATE_CATEGORY_SUCCESS = "CREATE_CATEGORY_SUCCESS"
let DELETE_CATEGORY = "DELETE_CATEGORY";
let DELETE_CATEGORY_SUCCESS = "DELETE_CATEGORY_SUCCESS";
let ASSOC_CATEGORY = "ASSOC_CATEGORY";
let ASSOC_CATEGORY_SUCCESS = "ASSOC_CATEGORY_SUCCESS";
let CATEGORY_PRICE = "CATEGORY_PRICE";
let CATEGORY_PRICE_SUCCESS = "CATEGORY_PRICE_SUCCESS";
let CATEGORY_BY_ID = "CATEGORY_BY_ID";
let CATEGORY_BY_ID_SUCCESS = "CATEGORY_BY_ID_SUCCESS";


export default function reducer(state = initialState, action) {
    switch (action.type) {
        case LIST_CATEGORY:
            return { ...state, fetching: 1, fetching_create: 0, fetching_assoc: 0 }
        case LIST_CATEGORY_SUCCESS:
            return { ...state, fetching: 2, data: action.payload }
        case LIST_CATEGORY_ADMIN:
            return { ...state, fetching_admin: 1, fetching_create: 0 }
        case LIST_CATEGORY_ADMIN_SUCCESS:
            return { ...state, fetching_admin: 2, data_admin: action.payload }
        case CREATE_CATEGORY:
            return { ...state, fetching_create: 1 }
        case CREATE_CATEGORY_SUCCESS:
            return { ...state, fetching_create: 2 }

        case CATEGORY_ERROR:
            return { ...state, errors: action.payload, fetching: 3, selected: action.form }
        case CATEGORY_SELECTED:
            return { ...state, fetching: 0, selected: action.payload }
        case CATEGORY_CLEAR:
            return { ...state, fetching: 0, selected: {} }

        case DELETE_CATEGORY:
            return { ...state, fetching_delete: 1 }
        case DELETE_CATEGORY_SUCCESS:
            return { ...state, fetching_delete: 2 }
        case ASSOC_CATEGORY:
            return { ...state, fetching_assoc: 1 }
        case ASSOC_CATEGORY_SUCCESS:
            return { ...state, fetching_assoc: 2 }
        case CATEGORY_PRICE:
            return { ...state, fetching_price: 1 }
        case CATEGORY_PRICE_SUCCESS:
            return { ...state, fetching_price: 2 }
        case CATEGORY_BY_ID:
            return { ...state, fetching_by_id: 1 }
        case CATEGORY_BY_ID_SUCCESS:
            return { ...state, fetching_by_id: 2, row: action.payload }
        default:
            return state;
    }
}

export let setSelectedItem = (item) => (dispatch, getState) => {
    dispatch({
        type: CATEGORY_SELECTED,
        payload: item
    })
}
export let clearSelected = () => (dispatch, getState) => {
    dispatch({
        type: CATEGORY_CLEAR
    })
}



export let getCategories = () => (dispatch, getState) => {
    dispatch({
        type: LIST_CATEGORY
    })

    if (getState().user.loggedIn) {

        let categories = []

        getLocalItems("categories").then(resp => {
            if (resp.length == 0) {
                axios.get(`${API_URL}/api/categories`, {
                    headers: getHeaders()
                }).then(resp => {
                    //resp.data.results.map(d => addItem('categories', d).then())

                    dispatch({
                        type: LIST_CATEGORY_SUCCESS,
                        payload: resp.data.results
                    });

                }).catch(function (error) {
                    console.log(error);
                });
            } else {
                dispatch({
                    type: LIST_CATEGORY_SUCCESS,
                    payload: resp
                });
            }

        });

    } else {
        console.log("no Ingreso");
    }
}

export let getCategoryParking = () => (dispatch, getState) => {
    dispatch({
        type: LIST_CATEGORY
    })

    if (getState().user.loggedIn) {

        let categories = []

        getLocalItems("categories").then(resp => {
            if (resp.length == 0) {
                axios.get(`${API_URL}/api/categories-parking`, {
                    headers: getHeaders()
                }).then(resp => {
                    //resp.data.results.map(d => addItem('categories', d).then())

                    dispatch({
                        type: LIST_CATEGORY_SUCCESS,
                        payload: resp.data.results
                    });

                }).catch(function (error) {
                    console.log(error);
                });
            } else {
                dispatch({
                    type: LIST_CATEGORY_SUCCESS,
                    payload: resp
                });
            }

        });

    } else {
        console.log("no Ingreso");
    }
}

export let getCategoryById = (id) => (dispatch, getState) => {
    dispatch({
        type: CATEGORY_BY_ID
    })

    if (getState().user.loggedIn) {

        getLocalItems("categories").then(resp => {
            if (resp.length == 0) {
                axios.get(`${API_URL}/api/categories/${id}`, {
                    headers: getHeaders()
                }).then(resp => {

                    dispatch({
                        type: CATEGORY_BY_ID_SUCCESS,
                        payload: resp.data
                    });

                }).catch(function (error) {
                    console.log(error);
                });
            } else {
                dispatch({
                    type: LIST_CATEGORY_SUCCESS,
                    payload: resp
                });
            }

        });

    } else {
        console.log("no Ingreso");
    }
}

export let getCategoriesAdmin = () => (dispatch, getState) => {
    dispatch({
        type: LIST_CATEGORY_ADMIN
    })

    if (getState().user.loggedIn) {

        axios.get(`${API_URL}/api/categories-admin`, {
            headers: getHeaders()
        }).then(resp => {
            dispatch({
                type: LIST_CATEGORY_ADMIN_SUCCESS,
                payload: resp.data.results
            });

        }).catch(function (error) {
            console.log(error);
        });
    } else {
        console.log("no Ingreso");
    }
}

export let getCategoriesClient = (product_id) => (dispatch, getState) => {
    dispatch({
        type: LIST_CATEGORY
    })

    if (getState().user.loggedIn) {

        axios.get(`${API_URL}/api/categories-client/${product_id}`, {
            headers: getHeaders()
        }).then(resp => {
            dispatch({
                type: LIST_CATEGORY_SUCCESS,
                payload: resp.data.results
            });

        }).catch(function (error) {
            console.log(error);
        });
    } else {
        console.log("no Ingreso");
    }
}

export let getCategoryProduct = () => (dispatch, getState) => {
    dispatch({
        type: LIST_CATEGORY
    })

    if (getState().user.loggedIn) {

        let branch = JSON.parse(localStorage.getItem("branch"))

        axios.get(`${API_URL}/api/categories-product?branch_id=${branch.id}`, {
            headers: getHeaders()
        }).then(resp => {
            dispatch({
                type: LIST_CATEGORY_SUCCESS,
                payload: resp.data.results
            });

        }).catch(function (error) {
            console.log(error);
        });
    } else {
        console.log("no Ingreso");
    }
}

export let createCategory = (param) => (dispatch, getState) => {

    dispatch({
        type: CREATE_CATEGORY
    })


    if (getState().user.loggedIn) {

        let branch = JSON.parse(localStorage.getItem("branch"))

        const form = new FormData();
        form.append("id", param.id);
        form.append("title", param.title);
        form.append("description", param.description);
        form.append("priority", param.priority);
        form.append("status", param.status);
        form.append("branch_id", branch.id);
        form.append("category_price", JSON.stringify(param.category_price));
        form.append("image", param.image);

        axios.post(`${API_URL}/api/categories`, form, {
            headers: getHeaders()
        }).then(resp => {
            dispatch({
                type: CREATE_CATEGORY_SUCCESS,
                payload: resp.data.results,

            });

        }).catch(error => {
            let err = {}

            for (const [key, value] of Object.entries(error.response.data.errors)) {
                if ('title' === key) {
                    err.title = value[0]
                }
                if ('description' === key) {
                    err.description = value[0]
                }
            }

            dispatch({
                type: CATEGORY_ERROR,
                payload: err,
                form: form
            })
        });
    } else {
        console.log("no Ingreso");
    }
}


export let onAssocCategory = (category) => (dispatch, getState) => {

    dispatch({
        type: ASSOC_CATEGORY
    })

    if (getState().user.loggedIn) {
        let url = `${API_URL}/api/categories-assoc/${category.category_id}`
        console.log('url', url);

        axios.put(url, category, {
            headers: getHeaders()
        }).then(resp => {
            dispatch({
                type: ASSOC_CATEGORY_SUCCESS
            });

        }).catch(error => {

        });
    } else {
        console.log("no Ingreso");
    }
}

export let onCreateOrUpdatePrice = (form) => (dispatch, getState) => {

    dispatch({
        type: CATEGORY_PRICE
    })

    let branch = JSON.parse(localStorage.getItem("branch"))


    if (getState().user.loggedIn) {
        form.branch_id = branch.id

        let url = `${API_URL}/api/categories-price/${form.category_id}`
        console.log('url', url);

        axios.put(url, form, {
            headers: getHeaders()
        }).then(resp => {
            dispatch({
                type: CATEGORY_PRICE_SUCCESS
            });

        }).catch(error => {

        });
    } else {
        console.log("no Ingreso");
    }
}

export let deleteCategory = (form) => (dispatch, getState) => {

    dispatch({
        type: DELETE_CATEGORY
    })

    if (getState().user.loggedIn) {

        axios.delete(`${API_URL}api/category/${form.id}`, {
            headers: getHeaders()
        }).then(resp => {
            dispatch({
                type: DELETE_CATEGORY_SUCCESS
            });

        }).catch(error => {

        });
    } else {
        console.log("no Ingreso");
    }
}

export let deleteCategoryAssoc = (id) => (dispatch, getState) => {

    dispatch({
        type: DELETE_CATEGORY
    })

    if (getState().user.loggedIn) {

        axios.delete(`${API_URL}/api/categories-assoc/${id}`, {
            headers: getHeaders()
        }).then(resp => {
            dispatch({
                type: DELETE_CATEGORY_SUCCESS
            });

        }).catch(error => {

        });
    } else {
        console.log("no Ingreso");
    }
}

export let deleteCategoryPrice = (id) => (dispatch, getState) => {

    dispatch({
        type: DELETE_CATEGORY
    })

    if (getState().user.loggedIn) {

        axios.delete(`${API_URL}/api/categories-price/${id}`, {
            headers: getHeaders()
        }).then(resp => {
            dispatch({
                type: DELETE_CATEGORY_SUCCESS
            });

        }).catch(error => {

        });
    } else {
        console.log("no Ingreso");
    }
}