import React, { useState } from 'react'
import { Col, Modal, Row } from 'react-bootstrap';
import InputField from '../Components/Widgets/InputField';

const ModalClient = ({ show, onHide, onCreateClient, errors }) => {
    const [form, setForm] = useState({ name: '', phone: '', has_agrement: false, document: '', email: '' });

    const updateInput = (e) => {
        setForm({
            ...form,
            [e.target.name]: e.target.value
        })
    }

    const onSubmit = () => {
        onCreateClient(form)
    }

    return (
        <Modal show={show} onHide={onHide}>
            <Modal.Header closeButton>
                <Modal.Title>Agregar Cliente</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col>
                        <div className="form-group">
                            <label>Nombre</label>
                            <InputField
                                placeholder="Nombres"
                                value={form.name || ''}
                                name="name"
                                onChange={updateInput}
                                error={errors.name}
                            />
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div className="form-group">
                            <label>Documento</label>
                            <InputField
                                type={"number"}
                                placeholder="Documento"
                                value={form.document || ''}
                                name="document"
                                onChange={updateInput}
                                error={errors.document}
                            />
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div className="form-group">
                            <label>Email</label>
                            <InputField
                                placeholder="Email"
                                value={form.email || ''}
                                name="email"
                                onChange={updateInput}
                                error={errors.document}
                            />
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div className="form-group">
                            <label>Celular</label>
                            <InputField
                                placeholder="phone"
                                value={form.phone || ''}
                                name="phone"
                                onChange={updateInput}
                                error={errors.phone}
                            />
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div className="form-group">
                            <label>¿Tiene Convenio?</label>
                            <InputField
                                type={"checkbox"}
                                value={form.has_agrement || ''}
                                name="has_agrement"
                                onChange={updateInput}
                                error={errors.has_agrement}
                            />
                        </div>
                    </Col>
                </Row>

            </Modal.Body>
            <Modal.Footer className="justify-content-center">
                <Row>
                    <Col>
                        <button className='btn btn-sm btn-danger' onClick={onHide}>Cerrar</button>
                    </Col>
                    <Col>
                        <button className='btn btn-sm btn-success' onClick={onSubmit}>Guardar</button>
                    </Col>
                </Row>
            </Modal.Footer>
        </Modal>
    )
}

export default ModalClient;