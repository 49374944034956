import React, { useEffect, useState } from 'react'
import TemplateMenu from '../../template/menu.template'
import { Card, Col, Row, Table } from 'react-bootstrap';
import { connect } from 'react-redux';
import { onCreateInvoice, getDataCreate, getListOrders } from '../../Redux/fetDuck'
import { getAllClients } from '../../Redux/clientDuck'
import moment from 'moment';
import { formatNumber, validateInput } from '../../utils';
import { toogleLoader } from '../../Redux/alertDuck'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { useToasts } from 'react-toast-notifications';
import Select from 'react-select'
import ModalClientFe from '../Payroll/modal.client.fe';
import { MdModeEdit } from "react-icons/md";
import ModalHistory from './modal.history';
import ItemTable from './item.table';

const customStyles = {
    control: (provided) => ({
        ...provided,
        minHeight: "32px",
        fontSize: "14px",
        borderRadius: "4px",
    }),
    valueContainer: (provided) => ({
        ...provided,
        padding: "0 8px",
    }),
    input: (provided) => ({
        ...provided,
        margin: "0",
    }),
};

const FECreatePage = ({ onCreateInvoice, getDataCreate, data_create, fetching_data_create, getAllClients, fetching_client,
    data_clients, getListOrders, fetching_order, data_order, toogleLoader, fetching_create_invoice, fe_errors }) => {

    const [form, setForm] = useState({
        'invoice_number': '',
        'prefix': '',
        "date": moment().format("YYYY-MM-DD"),
        "hour": moment().format("hh:mm:ss"),
        "payment_form_id": 2,
        "payment_method_id": 30,
        "customer_id": 0,
        "notes": '',
        "customer": {}
    })
    const [listPaymentForm, setListPaymentForm] = useState([])
    const [listPaymentMethod, setListPaymentMethod] = useState([])
    const [listClients, setListClients] = useState([])
    const [client, setClient] = useState('')
    const [listMunicipalies, setListMunicipalities] = useState([])
    const [listDepartment, setListDepartment] = useState([])
    const [listTypeLiabilites, setListTypeLiabilities] = useState([])
    const [listRegimes, setListRegimes] = useState([])
    const [listTypeOrganizations, setListTypeOrganizations] = useState([])
    const [listTypeDocumentIdentification, setListTypeDocumentIdentification] = useState([])
    const [listOrder, setListOrders] = useState([])
    const [show, setShow] = useState(false)
    const [showHistory, setShowHistory] = useState(false)
    const history = useHistory()
    const { addToast } = useToasts();

    useEffect(() => {
        if (fetching_data_create === 2) {
            let { payment_forms, payment_methods, invoice, prefix, type_regimes, type_organizations, type_document_identifications,
                type_liabilities, departments } = data_create
            setListPaymentForm(payment_forms)
            setListPaymentMethod(payment_methods)
            setListRegimes(type_regimes)
            setListTypeOrganizations(type_organizations)
            setListTypeDocumentIdentification(type_document_identifications)
            setListDepartment(departments)
            setListTypeLiabilities(type_liabilities)
            setForm({
                ...form,
                "invoice_number": invoice,
                "prefix": prefix,
            })
        }
    }, [fetching_data_create])


    useEffect(() => {
        if (fetching_create_invoice === 2) {
            toogleLoader(false)
            history.goBack()
        }
    }, [fetching_create_invoice])

    useEffect(() => {
        if (fetching_client === 2) {
            setListClients(data_clients)
        }
    }, [fetching_client])

    useEffect(() => {
        getAllClients()
        getDataCreate()
    }, [])

    const onInvoice = () => {
        toogleLoader(true)
        let _form = form;

        if (client === '') {
            addToast('Cliente es requerido', { appearance: 'error' })
            toogleLoader(false)
            return
        }

        _form.customer = client;
        _form.orders = listOrder;
        onCreateInvoice(_form)
    }

    const updateInput = (e) => {
        setForm({
            ...form,
            [e.target.name]: e.target.value
        })
    }

    const onSearchSelect = (value) => {

        if (value === null) {
            setClient('')
            return;
        }

        console.log('value', value);


        setClient(value)

        let clientSelected = listClients.find(client => client.id === value.id)

        const rules = [
            { field: "phone", msg: "Telefono es requerido" },
            { field: "email", msg: "Email es requerido" },
            { field: "dv", msg: "DV es requerido" },
            { field: "document", msg: "Documento es requerido" },
            { field: "name", msg: "Nombres es requerido" },
        ]

        let validate = validateInput(clientSelected, rules)
        if (validate !== null) {
            setShow(true)
            return
        }
    }

    const btnEditClient = () => {

        let _client = {
            ...client
        }

        if (client.municipality_id !== null && client.municipality_id !== 0) {
            let dep = listDepartment.find(dep => dep.municipalities.find(m => m.id === client.municipality_id))
            setListMunicipalities(dep.municipalities)
            _client.department_id = dep.id
        }

        setClient(_client)
        setShow(true)
    }

    const onAddItems = (items) => {
        setShowHistory(false)
        setListOrders(prevOrders => {
            const newItems = items.filter(item =>
                !prevOrders.some(order => order.id === item.id)
            );

            return [...prevOrders, ...newItems];
        });
    }

    const onDeleteItem = (item) => {
        let filter = listOrder.filter(d => d.id !== item.id)
        setListOrders(filter)
    }

    return (
        <TemplateMenu>
            {fe_errors.length > 0 &&
                <Row>
                    <Col>
                        <Card className='bg-danger-custom'>
                            <Card.Body>
                                {fe_errors.map((d, k) => <Row key={k}>
                                    <Col>{d}</Col>
                                </Row>)}
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>}

            <Row className='d-flex justify-content-center'>
                <Col lg={7}>
                    <Card>
                        <Card.Body>
                            <Card.Title className='text-right'>Factura #{form.prefix}{form.invoice_number}</Card.Title>
                            <Row>
                                <Col>
                                    <div className="form-group">
                                        <label>Cliente</label>
                                        <Select
                                            styles={customStyles}
                                            classNamePrefix="custom"
                                            isClearable={true}
                                            value={client}
                                            onChange={onSearchSelect}
                                            options={listClients}
                                            placeholder="Buscar Cliente" />
                                    </div>
                                </Col>

                                {client !== '' ? <Col>
                                    <Card>
                                        <Card.Body>
                                            <Card.Title>{client.name} <MdModeEdit className='cursor-pointer' onClick={btnEditClient} /> </Card.Title>
                                            <Row>
                                                <Col>Documento</Col>
                                                <Col>{client.document}</Col>
                                            </Row>
                                            <Row>
                                                <Col>Email</Col>
                                                <Col>{client.email}</Col>
                                            </Row>
                                        </Card.Body>
                                    </Card></Col> :
                                    <Col className='d-flex align-items-center justify-content-center'>
                                        <Row >
                                            <Col>
                                                <button className='btn btn-sm btn-info'
                                                    onClick={() => setShow(true)}>
                                                    Crear Cliente
                                                </button>
                                            </Col>
                                        </Row>
                                    </Col>}
                            </Row>
                            <Row>
                                <Col>
                                    <div className="form-group">
                                        <label>Forma de pago</label>
                                        <select className='form-control' name="payment_form_id" value={form.payment_form_id} onChange={updateInput}>
                                            <option value={0}>Seleccione</option>
                                            {listPaymentForm.map((a, b) => <option key={b} value={a.id}>{a.name}</option>)}
                                        </select>
                                    </div>
                                </Col>
                                <Col>
                                    <div className="form-group">
                                        <label>Método de Pago</label>
                                        <select className='form-control' name="payment_method_id" value={form.payment_method_id} onChange={updateInput}>
                                            <option value={0}>Seleccione</option>
                                            {listPaymentMethod.map((a, b) => <option key={b} value={a.id}>{a.name}</option>)}
                                        </select>
                                    </div>
                                </Col>
                            </Row>
                            <Row className='mt-4'>
                                <Col>
                                    Agregar Servicios, Productos o Parqueo
                                </Col>
                                <Col className='d-flex justify-content-end'>
                                    <button className='btn btn-sm btn-info' onClick={() => setShowHistory(true)}>Agregar </button>
                                </Col>
                            </Row>
                            <Row className='my-1'>
                                <Col>
                                    <Table className='table table-sm table-bordered'>
                                        <thead className='thead-dark'>
                                            <tr>
                                                <th>#</th>
                                                <th>Descripcion</th>
                                                <th>Subtotal</th>
                                                <th>Desc</th>
                                                <th>Imp</th>
                                                <th>Total</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {listOrder.length === 0 ? <tr><td colSpan={6} className='text-center'>Sin Datos</td></tr> :
                                                listOrder.map((a, b) => <ItemTable
                                                    item={a}
                                                    onDeleteItem={onDeleteItem}
                                                    key={b} index={b + 1} />)}
                                        </tbody>
                                        <tfoot>
                                            <tr>
                                                <th colSpan={5}>TOTAL</th>
                                                <th className='text-right'>{formatNumber(listOrder.reduce((a, b) => a + parseFloat(b.total), 0))}</th>
                                            </tr>
                                        </tfoot>
                                    </Table>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <div className="form-group">
                                        <label>Nota</label>
                                        <textarea className='form-control' name='notes' onChange={updateInput} placeholder='Notas'></textarea>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col className='text-center'>
                                    <button className='btn btn-sm btn-success' onClick={onInvoice}
                                        disabled={listOrder.length === 0}>Facturar</button>
                                </Col>
                            </Row>

                        </Card.Body>
                    </Card>
                </Col>
            </Row>

            <ModalClientFe
                show={show}
                client={client}
                onHide={() => {
                    setShow(false);
                    setClient('')
                }}
                onUpdateMunicipalities={(municipalities) => setListMunicipalities(municipalities)}
                listTypeDocumentIdentification={listTypeDocumentIdentification}
                listMunicipalies={listMunicipalies}
                listDepartment={listDepartment}
                listTypeLiabilites={listTypeLiabilites}
                listRegimes={listRegimes}
                listTypeOrganizations={listTypeOrganizations}
                onCancel={() => {
                    setShow(false)
                }}
                onSuccess={(value) => {
                    console.log('value', value);

                    let _value = value
                    _value.label = `${value.name} (${value.phone})`
                    _value.value = value.id
                    setClient(_value)
                    setShow(false)
                }}
            />
            <ModalHistory show={showHistory} onAddItems={onAddItems} onHide={() => setShowHistory(false)} />
        </TemplateMenu >
    )
}

const mapsState = (props) => {
    let { fe, client } = props

    return {
        fetching_client: client.fetching_client,
        data_clients: client.clients,
        fetching_data_create: fe.fetching_data_create,
        data_create: fe.data_create,
        fetching_order: fe.fetching_order,
        data_order: fe.data_order,
        fetching_create_invoice: fe.fetching_create_invoice,
        fe_errors: fe.errors
    }
}
export default connect(mapsState, {
    onCreateInvoice, getDataCreate, getAllClients, getListOrders,
    toogleLoader
})(FECreatePage);