import React, { useState } from 'react'
import { Card, Col, Row } from 'react-bootstrap';
import InputField from '../../Components/Widgets/InputField';

const SoftwareInfoPage = ({ formBusiness, storeSoftwareForm, formSoftware, updateInputSoftware }) => {

    return (
        <Row className='d-flex justify-content-center'>
            <Col lg={5}>
                <Card>
                    <Card.Header>
                        <Row>
                            <Col><Card.Title>Software</Card.Title></Col>
                            <Col className='d-flex justify-content-end'>

                                {formBusiness.api_token !== '' &&
                                    <button className='btn btn-sm btn-success' onClick={storeSoftwareForm}>Guardar</button>}

                            </Col>
                        </Row>
                    </Card.Header>
                    <Card.Body>
                        <Row>
                            <Col>
                                <div className="form-group">
                                    <label>Id</label>
                                    <InputField
                                        placeholder="id"
                                        value={formSoftware.id || ''}
                                        name="id"
                                        onChange={updateInputSoftware}
                                    // error={errors.business}
                                    />
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <div className="form-group">
                                    <label>Pin</label>
                                    <InputField
                                        placeholder="pin"
                                        value={formSoftware.pin || ''}
                                        name="pin"
                                        onChange={updateInputSoftware}
                                    // error={errors.business}
                                    />
                                </div>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
            </Col>
        </Row>
    )
}

export default SoftwareInfoPage;