import React, { useEffect, useRef, useState } from 'react'
import { Card, Col, Container, Row, Table } from 'react-bootstrap';
import TemplateMenu from '../../template/menu.template'
import { getParameterBySegment } from '../../Redux/parameterDuck'
import { getOperators } from '../../Redux/userDuck'
import { getCategories } from '../../Redux/categoryDuck'
import { getReview } from '../../Redux/reportDuck'

import { connect } from 'react-redux';

import { toogleAlert } from '../../Redux/alertDuck'
import { getTurns } from '../../Redux/turnDuck';
import { getNewReview } from '../../Redux/reportDuck';

import "./index.css"
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import moment from 'moment';
import { formatNumber } from '../../utils';
import ChartDataLabels from 'chartjs-plugin-datalabels';

import { Chart as ChartJs, LineElement, CategoryScale, LinearScale, PointElement, RadialLinearScale, Title, Tooltip, Legend, Filler, ArcElement } from 'chart.js'
import { Bar, Doughnut, PolarArea } from 'react-chartjs-2';


ChartJs.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    ArcElement,
    RadialLinearScale,
    Title,
    Tooltip,
    Legend, Filler,
    ChartDataLabels
)


// const options = {
//     responsive: true,
//     maintainAspectRatio: false
// };

const options = {
    plugins: {
        title: {
            display: true, // Habilita el título
            text: 'Ingresos', // Texto del título
            font: {
                size: 18, // Tamaño de la fuente
            },
        },
        legend: {
            display: true, // Muestra la leyenda
            position: 'top'
        },
        datalabels: {
            formatter: (value, context) => {
                const total = context.dataset.data.reduce((acc, val) => acc + val, 0);
                const percentage = ((value / total) * 100).toFixed(1) + '%';
                return percentage; // Muestra el porcentaje en cada sector
            },
            color: '#5b5b5c', // Color del texto
            font: {
                size: 16,
                weight: 'bold'
            }
        }
    }
};


const NewReportPage = ({ getNewReview, fetching_review, data_review, toogleAlert, getTurns, fetching_list_turn, data_turn }) => {
    const [form, setForm] = useState({ turn_id: "0", date_init: moment().format("YYYY-MM-01"), date_end: moment().format("YYYY-MM-DD") })
    const [data, setData] = useState({ orders: 0, products: 0, result_parking: 0, result_expense: 0, result_payroll: 0 })
    const [listTurn, setListTurn] = useState([])
    const [totalInput, setTotalInput] = useState(0)

    const [graph, setGraph] = useState({
        labels: [],
        datasets: [
            {
                data: [],
                backgroundColor: []
            }
        ]
    });

    useEffect(() => {
        if (fetching_review === 2) {

            setTotalInput(data_review.orders + data_review.other_input_total + data_review.products
                + data_review.result_parking
            )


            let labels = [], data = []
            if (data_review.orders > 0) {
                labels.push("Servicios")
                data.push(data_review.orders)
            }
            if (data_review.products > 0) {
                labels.push("Productos")
                data.push(data_review.products)
            }
            if (data_review.result_parking > 0) {
                labels.push("Parqueadero")
                data.push(data_review.result_parking)
            }
            if (data_review.other_input_total > 0) {
                labels.push("Otros Ingresos")
                data.push(data_review.other_input_total)
            }

            setGraph(
                {
                    labels,
                    datasets: [
                        {
                            label: 'Movimientos',
                            data,
                            backgroundColor: [
                                'rgba(75, 192, 192, 0.2)',
                                'rgba(54, 162, 235, 0.2)',
                                'rgba(172, 54, 235, 0.2)',
                                'rgba(255, 206, 86, 0.2)',
                                'rgba(255, 99, 132, 0.2)',
                            ],
                            borderColor: [
                                'rgba(75, 192, 192, 1)',
                                'rgba(54, 162, 235, 1)',
                                'rgb(154, 54, 235)',
                                'rgba(255, 206, 86, 1)',
                                'rgba(255, 99, 132, 1)',
                            ],
                            borderWidth: 1,
                        },
                    ],
                });

            setData(data_review)
            toogleAlert(false)
        }
    }, [fetching_review, data_review])

    useEffect(() => {
        if (fetching_list_turn === 2) {
            setListTurn(data_turn)
        }
    }, [fetching_list_turn])

    useEffect(() => {
        toogleAlert(true)

        let _form = form

        let { date_init, date_end } = JSON.parse(localStorage.getItem("date"))
        _form.date_init = date_init
        _form.date_end = date_end
        setForm(_form)

        getNewReview(form)
        getTurns()
    }, [])

    const updateField = (e) => {
        setForm({
            ...form,
            [e.target.name]: e.target.value
        })
    }

    const onFilter = () => {
        toogleAlert(true)
        getNewReview(form)

    }

    const chartData = {
        labels: ['Servicios', 'Productos', 'Parqueadero', 'Gastos'],
        datasets: [
            {
                label: 'Movimientos',
                data: [100, 200, 300, 400],
                backgroundColor: ['rgba(255, 99, 132, 0.2)', 'rgba(54, 162, 235, 0.2)', 'rgba(255, 206, 86, 0.2)', 'rgba(75, 192, 192, 0.2)'],
                borderColor: ['rgba(255, 99, 132, 1)', 'rgba(54, 162, 235, 1)', 'rgba(255, 206, 86, 1)', 'rgba(75, 192, 192, 1)'],
                borderWidth: 1,
            },
        ],
    };

    return (
        <TemplateMenu>
            <Container fluid>
                <Row>
                    <Col lg={3}>
                        <div className="form-group">
                            <label>Turnos</label>
                            <select className='form-control' name='turn_id' value={form.turn_id} onChange={updateField}>
                                <option value={0}>Rango de Fecha</option>
                                {listTurn.map((d, k) => <option key={k} value={d.id}>({d.id}){d.user.name} | {d.date_start} | {d.date_end}</option>)}
                            </select>
                        </div>
                    </Col>
                    {form.turn_id === '0' && <>
                        <Col lg={2}>
                            <div className="form-group">
                                <label>Fecha de Inicio</label>
                                <input
                                    type="date"
                                    className="form-control"
                                    value={form.date_init}
                                    name="date_init"
                                    onChange={updateField}
                                />
                            </div>
                        </Col>
                        <Col lg={2}>
                            <div className="form-group">
                                <label>Fecha Fin</label>
                                <input
                                    type="date"
                                    className="form-control"
                                    value={form.date_end}
                                    name="date_end"
                                    onChange={updateField}
                                />
                            </div>
                        </Col>
                    </>}

                    <Col lg={2} className="d-flex align-items-center">
                        <button className='btn btn-sm btn-success mt-3' onClick={onFilter}>Buscar</button>
                    </Col>
                </Row>


                <Row className='my-4'>
                    <Col lg={3}>
                        {graph.labels.length > 0 ? (
                            <Doughnut key={JSON.stringify(graph)} data={graph} options={options} />
                        ) : (
                            <p>Cargando datos...</p>
                        )}
                    </Col>
                    <Col>
                        <Row className='mt-5'>
                            <Col>
                                <Card className="bg-success-custom-altern">
                                    <Card.Body>
                                        <Card.Title>Total de ingresos</Card.Title>
                                        <Row>
                                            <Col className='text-right'><h4>${formatNumber(totalInput)}</h4></Col>
                                        </Row>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                        <Row className='mt-2'>
                            <Col>
                                <Link to={`/resumen-services`} className="text-decoration-none text-reset">
                                    <Card className="bg-success-custom">
                                        <Card.Body>
                                            <Card.Title>Venta de Servicios</Card.Title>
                                            <Row>
                                                <Col className='text-left'><h4>#{formatNumber(data.quantity_order)}</h4></Col>
                                                <Col className='text-right'><h4>${formatNumber(data.orders)}</h4></Col>
                                            </Row>
                                        </Card.Body>
                                    </Card>
                                </Link>
                            </Col>
                            <Col>
                                <Link to={`/resumen-products`} className="text-decoration-none text-reset">
                                    <Card className="bg-primary-custom">
                                        <Card.Body>
                                            <Card.Title>Venta de Productos</Card.Title>
                                            <Row>
                                                <Col className='text-left'><h4>#{formatNumber(data.quantity_products)}</h4></Col>
                                                <Col className='text-right'><h4>{formatNumber(data.products)}</h4></Col>
                                            </Row>
                                        </Card.Body>
                                    </Card>
                                </Link>
                            </Col>
                            <Col>
                                <Link to={`/resumen-otros-ingresos`} className="text-decoration-none text-reset">
                                    <Card className="bg-purple-custom">
                                        <Card.Body>
                                            <Card.Title>Otros ingresos</Card.Title>
                                            <Row>
                                                <Col className='text-left'><h4>#{formatNumber(data.other_input_quantity)}</h4></Col>
                                                <Col className='text-right'><h4>{formatNumber(data.other_input_total)}</h4></Col>
                                            </Row>
                                        </Card.Body>
                                    </Card>
                                </Link>
                            </Col>

                        </Row>

                        <Row className='my-2'>
                            <Col>
                                <Link to={`/resumen-parking`} className="text-decoration-none text-reset">
                                    <Card className="bg-warning-custom">
                                        <Card.Body>
                                            <Card.Title>Servicios de Parqueo</Card.Title>
                                            <Row>
                                                <Col className='text-left'><h4>#{formatNumber(data.quantity_parking)}</h4></Col>
                                                <Col className='text-right'><h4>{formatNumber(data.result_parking)}</h4></Col>
                                            </Row>
                                        </Card.Body>
                                    </Card>
                                </Link>
                            </Col>
                            <Col>
                                <Link to={`/resumen-gastos`} className="text-decoration-none text-reset">

                                    <Card className="bg-danger-custom">
                                        <Card.Body>
                                            <Card.Title>Gastos</Card.Title>
                                            <Row>
                                                <Col className='text-right'><h4>{formatNumber(data.result_expense + data.result_expense_payroll)}</h4></Col>
                                            </Row>
                                        </Card.Body>
                                    </Card>
                                </Link>
                            </Col>

                            <Col>
                                <Link to={`/resumen-client`} className="text-decoration-none text-reset">

                                    <Card>
                                        <Card.Body>
                                            <Card.Title>Clientes</Card.Title>
                                            <Row>
                                                <Col className='text-right'><h4>{formatNumber(data.result_clients)}</h4></Col>
                                            </Row>
                                        </Card.Body>
                                    </Card>
                                </Link>

                            </Col>

                        </Row>
                        <Row>
                            <Col>
                                <Link to={`/resumen-payment-method`}>
                                    <Card>
                                        <Card.Body>
                                            <Card.Title>Medios de Pagos</Card.Title>
                                            <Row className='py-3'>
                                                <Col className='text-right'></Col>
                                            </Row>
                                        </Card.Body>
                                    </Card>
                                </Link>
                            </Col>
                            <Col lg={4}>
                                <Card>
                                    <Card.Body>
                                        <Card.Title>Servicios de Pintura</Card.Title>
                                        <Row className='py-3'>
                                            <Col className='text-right'></Col>
                                        </Row>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col lg={4}>
                                <Card>
                                    <Card.Body>
                                        <Card.Title>Servicios de Serviteca</Card.Title>
                                        <Row className='py-3'>
                                            <Col className='text-right'></Col>
                                        </Row>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </Col>
                </Row>

            </Container >
        </TemplateMenu >
    )
}

const mapsState = (props) => {
    let { report, turn } = props

    return {
        fetching_review: report.fetching_review,
        data_review: report.data_review,
        fetching_list_turn: turn.fetching_list,
        data_turn: turn.data
    }
}

export default connect(mapsState, {
    getParameterBySegment, getOperators, getCategories, getReview, toogleAlert, getTurns,
    getNewReview
})(NewReportPage);