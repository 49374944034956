import React, { useState } from 'react'

const ItemEditCount = ({ item, onEdit, status_id }) => {
    const [quantity, setQuantity] = useState('')

    const onShowEdit = (q) => {
        setQuantity(q);
    }
    const onEditPre = () => {
        onEdit(item, quantity)
        setQuantity('')
    }

    return (
        <tr>
            <td>{item.product.id}</td>
            <td>{item.product.name}</td>
            <td>
                {quantity !== '' ? <>
                    <input className='form-control' value={quantity} onChange={(e) => setQuantity(e.target.value)} type="number" />
                </> : item.quantity}
            </td>
            <td>
                {status_id === 1 && <>
                    {quantity === '' ?
                        <button className='btn btn-sm btn-info' onClick={() => onShowEdit(item.quantity)}>Editar</button>
                        : <>
                            <button className='btn btn-sm btn-success mx-3' onClick={onEditPre}>Guardar</button>
                            <button className='btn btn-sm btn-danger' onClick={() => setQuantity('')}>Cancelar</button>
                        </>}
                </>}


            </td>
        </tr>

    )
}

export default ItemEditCount;