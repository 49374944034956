import moment from 'moment';
import React, { useEffect, useState } from 'react'
import { Col, Modal, Row, Tab, Tabs } from 'react-bootstrap';
import { connect } from 'react-redux';
import { toogleLoader } from '../../Redux/alertDuck'
import { onCreateInvoice, getDataCreate, getListOrders } from '../../Redux/fetDuck'
import { getAllClients } from '../../Redux/clientDuck'
import ServicesFe from './services.fe';
import StoreFe from './store.fe';
import ParkingFe from './parking.fe';

const ModalHistory = ({ show, toogleLoader, getListOrders, fetching_order, data_order, onAddItems,
    onHide
}) => {
    const [filter, setFilter] = useState({
        client_id: 0,
        date_init: moment().format("YYYY-MM-01"),
        date_end: moment().format("YYYY-MM-DD"),
        plate: '',
        order_id: ''
    })
    const [list, setList] = useState([])
    const [listFiltered, setListFiltered] = useState([])
    const [listFilteredCart, setListFilteredCart] = useState([])
    const [listFilteredParking, setListFilteredParking] = useState([])

    useEffect(() => {
        if (fetching_order === 2) {

            console.log('data_order', data_order);

            const { orders, carts, parkings } = data_order

            console.log('carts', carts);


            if (orders.length > 0) {
                let list = orders.map(item => ({ ...item, checked: false, type: "SERVICIO" }))
                setList(list)
                setListFiltered(list)
            }

            if (carts.length > 0) {
                let listCart = carts.map(item => ({ ...item, checked: false, type: "TIENDA" }))
                setListFilteredCart(listCart)
            }

            // let listCart = carts.map(item => ({ ...item, checked: false, type: "TIENDA" }))
            // let listParking = parkings.map(item => ({ ...item, checked: false, type: "PARQUEADERO" }))
            // setListFilteredCart(listCart)
            // setListFilteredParking(listParking)

            toogleLoader(false)
        }
    }, [fetching_order])

    const updateInputFilter = (e) => {
        setFilter({
            ...filter,
            [e.target.name]: e.target.value
        })
        setListFiltered([])
    }

    const onSearch = () => {
        toogleLoader(true)
        getListOrders(filter)
    }

    const handleCheck = (row) => {
        setListFiltered(listFiltered.map(item =>
            item.id === row.id ? { ...item, checked: !item.checked } : item
        ));
    }

    const handleCheckCart = (row) => {
        setListFilteredCart(listFilteredCart.map(item =>
            item.id === row.id ? { ...item, checked: !item.checked } : item
        ));
    }

    const handleCheckParking = (row) => {
        setListFilteredParking(listFilteredParking.map(item =>
            item.id === row.id ? { ...item, checked: !item.checked } : item
        ));
    }

    const onAddItemsTmp = () => {
        onAddItems(listFiltered.filter(d => d.checked === true));
        onAddItems(listFilteredCart.filter(d => d.checked === true));
        onAddItems(listFilteredParking.filter(d => d.checked === true));
        setListFiltered([])
    }

    return (
        <Modal show={show} size='lg' onHide={onHide}>
            <Modal.Body>
                <Modal.Title>Buscar Ordenes</Modal.Title>
                <Row className='mt-2'>
                    <Col>
                        <Row>
                            <Col lg={3}>
                                <div className="form-group">
                                    <label>Orden</label>
                                    <input className='form-control' placeholder='Orden' name='order_id'
                                        value={filter.order_id}
                                        onChange={updateInputFilter} />
                                </div>
                            </Col>
                            <Col lg={3}>
                                <div className="form-group">
                                    <label>Placa</label>
                                    <input className='form-control' placeholder='Plate' name='plate'
                                        value={filter.plate}
                                        onChange={updateInputFilter} />
                                </div>
                            </Col>
                            <Col>
                                <div className="form-group">
                                    <label>Fecha de inicio</label>
                                    <input
                                        type='date'
                                        className='form-control'
                                        placeholder='Fecha de Inicio' name='date_init'
                                        value={filter.date_init}
                                        onChange={updateInputFilter} />
                                </div>
                            </Col>
                            <Col>
                                <div className="form-group">
                                    <label>Fecha de Fin</label>
                                    <input
                                        type='date'
                                        className='form-control'
                                        placeholder='Fecha de Fin' name='date_end'
                                        value={filter.date_end}
                                        onChange={updateInputFilter} />
                                </div>
                            </Col>
                            <Col lg={1} className='d-flex align-items-center'>
                                <button className='btn btn-sm btn-info' onClick={onSearch}>Buscar</button>
                            </Col>
                        </Row>
                        <Row>
                            <Col className='text-center'>
                                {(listFiltered.filter(d => d.checked === true).length > 0 || listFilteredCart.filter(d => d.checked === true).length > 0) &&
                                    <button className='btn btn-sm btn-success' onClick={onAddItemsTmp}>Agregar ({listFiltered.filter(d => d.checked === true).length + listFilteredCart.filter(d => d.checked === true).length} Seleccionadas)</button>}

                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Tabs defaultActiveKey="service" id="uncontrolled-tab-example">
                                    <Tab eventKey="service" title={`Servicios (${listFiltered.length})`}>
                                        <ServicesFe listFiltered={listFiltered} handleCheck={handleCheck} />
                                    </Tab>
                                    <Tab eventKey="store" title={`Tienda (${listFilteredCart.length})`}>
                                        <StoreFe listFiltered={listFilteredCart} handleCheck={handleCheckCart} />
                                    </Tab>
                                    <Tab eventKey="parking" title={`Parquedero (${listFilteredParking.length})`}>
                                        <ParkingFe listFiltered={listFilteredParking} handleCheck={handleCheckParking} />
                                    </Tab>
                                </Tabs>

                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Modal.Body>
        </Modal>
    )
}

const mapsState = (props) => {
    let { fe } = props

    return {
        fetching_data_create: fe.fetching_data_create,
        data_create: fe.data_create,
        fetching_order: fe.fetching_order,
        data_order: fe.data_order,
        fe_errors: fe.errors
    }
}
export default connect(mapsState, { toogleLoader, getListOrders })(ModalHistory);