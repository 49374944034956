import React, { useEffect, useState } from 'react'
import TemplateMenu from '../../template/menu.template'
import { Card, Col, Row, Table } from 'react-bootstrap';
import { connect } from 'react-redux';
import { getAllUser } from '../../Redux/userDuck'
import { formatNumber, getQuantityDays } from '../../utils';
import { onCreatePayroll, getExpenseForPayroll } from '../../Redux/payrollDuck'
import { toogleLoader } from '../../Redux/alertDuck'
import moment from 'moment';
import { getParameterBySegment } from '../../Redux/parameterDuck'
import ModalPaymentPayroll from './modal.payment.payroll';
import { useToasts } from 'react-toast-notifications';
import { RiMoneyDollarCircleFill } from 'react-icons/ri';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const CreaatePayrollPage = ({ getAllUser, fetching_list, data_users, onCreatePayroll, getParameterBySegment,
    fetching_segment, data_segment, fetching_create, fetching_active, row, getExpenseForPayroll,
    data_expense, fetching_expense, toogleLoader
}) => {
    const [listOperator, setListOperator] = useState([]);
    const [listMethod, setListMethod] = useState([]);
    const [modal, setModal] = useState(false);
    const [turnActive, setTurnActive] = useState({ user: { name: '' } });
    const [typeLiquidation, setTypeLiquidation] = useState(false);
    const history = useHistory();
    const initState = {
        salary: 0, worker_id: 0, discount: 0, supplies: 0, advances: 0, bonification: 0,
        date_init: moment().format("YYYY-MM-01"), date_end: moment().format("YYYY-MM-DD"),
        days: getQuantityDays(moment().format("YYYY-MM-01"), moment().format("YYYY-MM-DD")),
        extra_hour: 0, range_date: 0
    }

    const last_month = moment().subtract(1, 'month')


    const [listDates, setListDates] = useState([
        {
            id: 1, text: last_month.format("MMM 01") + " a " + last_month.format("MMM 15"), init: last_month.format("YYYY-MM-01"), end: last_month.format("YYYY-MM-15")
        },
        {
            id: 2, text: last_month.format("MMM 16") + " a " + last_month.endOf('month').format("MMM DD"), init: last_month.format("YYYY-MM-16"), end: last_month.endOf('month').format("YYYY-MM-DD")
        },
        {
            id: 3, text: moment().format("MMM 01") + " a " + moment().format("MMM 15"), init: moment().format("YYYY-MM-01"), end: moment().format("YYYY-MM-15")
        },
        {
            id: 4, text: moment().format("MMM 16") + " a " + moment().endOf("month").format("MMM DD"), init: moment().format("YYYY-MM-16"), end: moment().endOf("month").format("YYYY-MM-DD")
        },
    ]);

    const [form, setForm] = useState(initState)
    const [listExpense, setListExpense] = useState([])
    const [listCredit, setListCredit] = useState([])

    const [selected, setSelected] = useState({ id: 0 })
    const { addToast } = useToasts();

    useEffect(() => {
        if (fetching_active === 2) {
            setTurnActive(row)
        }
    }, [fetching_active])

    useEffect(() => {
        if (fetching_expense === 2) {
            toogleLoader(false)
            setListCredit(data_expense.credits)

            const _data_expense = data_expense.results.map((item) => ({
                ...item,
                checked: true,
            }));
            setListExpense(_data_expense)
        }
    }, [fetching_expense])

    useEffect(() => {
        if (fetching_list === 2) {
            setListOperator(data_users)
        }
    }, [fetching_list])
    useEffect(() => {
        if (fetching_segment === 2) {
            setListMethod(data_segment)
        }
    }, [fetching_segment])
    useEffect(() => {
        if (fetching_create === 2) {
            setModal(false)
            setForm(initState)
            setSelected({ id: 0 })
            addToast('Guardado con Exito', { appearance: 'success', autoDismiss: true });
            history.goBack()
        }
    }, [fetching_create])

    useEffect(() => {
        getAllUser(true);
        getParameterBySegment("payment")
    }, [])

    const updateField = (e) => {
        let _form = {
            ...form,
            [e.target.name]: e.target.value
        }
        if (e.target.name === 'date_end') {
            let days = getQuantityDays(form.date_init, e.target.value)
            _form.days = days
            _form.salary = Math.round(((selected.salary / 30) * days))
        }

        if (e.target.name === 'range_date') {
            let _date = listDates.filter(d => d.id === parseInt(e.target.value))[0]
            let days = 15;
            _form.days = 15;
            _form.date_init = _date.init
            _form.date_end = _date.end
            _form.salary = Math.round(((selected.salary / 30) * days))
        }

        setForm(_form)
    }
    const updateFieldWorker = (e) => {
        let _selected = listOperator.filter(d => d.id === parseInt(e.target.value))
        setSelected(_selected[0])

        let days = getQuantityDays(form.date_init, form.date_end)
        let _form = {
            ...form,
            [e.target.name]: e.target.value
        }

        _form.salary = Math.round((_selected[0].salary / 30) * days)
        _form.bonification = _selected[0].bonification
        getExpenseForPayroll(_form.worker_id)
        toogleLoader(true)
        setForm(_form)
    }

    const onSubmit = (method_payment_id) => {
        let _form = form
        _form.method_payment_id = method_payment_id;
        _form.cashier_id = turnActive.id;
        _form.advances = _form.advances + listExpense.filter(d => d.checked === true)
            .reduce((a, b) => a + parseFloat(b.value), 0);

        _form.list_expenses = listExpense.filter(d => d.checked === true)
        onCreatePayroll(form)
    }

    const onChangeCheckbox = (item) => {
        setListExpense(listExpense.map(value =>
            value.id === item.id ? { ...value, checked: !item.checked } : value
        ));
    }

    return (
        <TemplateMenu>
            <Row className='d-flex justify-content-center'>
                <Col lg={6}>
                    <Card>
                        <Card.Body>
                            <Row>
                                <Col className='text-center'><h5>TURNO</h5></Col>
                            </Row>
                            <Row>
                                {(turnActive !== undefined && turnActive !== null) && <Col className='text-center'>
                                    <Card>
                                        <Card.Body>
                                            <Row>
                                                <Col><RiMoneyDollarCircleFill size={30} /></Col>
                                            </Row>
                                            <Row>
                                                <Col>Turno</Col>
                                                <Col>({turnActive.id}) - {turnActive.user.name}</Col>
                                            </Row>
                                            <Row className='mt-2'>
                                                <Col>Fecha de Apertura</Col>
                                                <Col>{turnActive.date_start}</Col>
                                            </Row>
                                        </Card.Body>
                                    </Card>
                                </Col>}

                            </Row>
                        </Card.Body>
                    </Card></Col>
            </Row>
            <Row>
                <Col>
                    <Card>
                        <Card.Body>
                            <Card.Title>Liquidación de Trabajador</Card.Title>
                            <Row>
                                <Col>
                                    <div className="form-group">
                                        <label>Trabajadores</label>
                                        <select className='form-control' name='worker_id' value={form.worker_id} onChange={updateFieldWorker}>
                                            <option value={0}>Seleccione</option>
                                            {listOperator.filter(d => d.is_payroll === true).map((d, k) => <option key={k} value={d.id}>{d.name} ({d.rol.title})</option>)}
                                        </select>
                                    </div>
                                </Col>
                            </Row>
                            {selected.id !== 0 && <>
                                <Row>
                                    {typeLiquidation ? <>

                                        <Col lg={4}>
                                            <div className="form-group">
                                                <label>Fecha de Inicio</label>
                                                <input
                                                    type="date"
                                                    className="form-control"
                                                    value={form.date_init}
                                                    name="date_init"
                                                    onChange={updateField}
                                                />
                                            </div>
                                        </Col>
                                        <Col lg={4}>
                                            <div className="form-group">
                                                <label>Fecha Fin</label>
                                                <input
                                                    type="date"
                                                    className="form-control"
                                                    value={form.date_end}
                                                    name="date_end"
                                                    onChange={updateField}
                                                />
                                            </div>
                                        </Col></>
                                        : <Col>
                                            <div className="form-group">
                                                <label>Quicenas</label>
                                                <select className='form-control' name='range_date' value={form.range_date} onChange={updateField}>
                                                    <option value={0}>Seleccione</option>
                                                    {listDates.map(r => <option key={r.id} value={r.id}>{r.text}</option>)}
                                                </select>
                                            </div>
                                        </Col>}
                                    <Col className=''>
                                        <div className='form-group'>
                                            <label htmlFor="liquidation">Liquidar por Rango de Fecha</label>
                                            <input type='checkbox' id="liquidation" checked={typeLiquidation}
                                                onChange={() => setTypeLiquidation(!typeLiquidation)} className='form-control' />
                                        </div>
                                    </Col>
                                </Row>
                                <Row className='border-bottom'>
                                    <Col>
                                        Sueldo
                                    </Col>
                                    <Col className='text-right'>
                                        {formatNumber(selected.salary)}
                                    </Col>
                                </Row>
                                <Row className='my-3 border-bottom'>
                                    <Col>
                                        Días Trabajados
                                    </Col>
                                    <Col className='text-right'>
                                        {formatNumber(form.days)}
                                    </Col>
                                </Row>
                                <Row className='my-3 border-bottom'>
                                    <Col>
                                        Salario
                                    </Col>
                                    <Col className='text-right'>
                                        {formatNumber(form.salary)}
                                    </Col>
                                </Row>
                                <Row className='my-1 '>
                                    <Col>
                                        Bonificación Extra Legal
                                    </Col>
                                    <Col className='text-right'>
                                        <input type='number' className='form-control text-right' value={form.bonification} name="bonification"
                                            onChange={updateField} />
                                    </Col>
                                </Row>
                                <Row className='my-3 border-bottom'>
                                    <Col>
                                        Horas Extras
                                    </Col>
                                    <Col className='text-right'>
                                        {formatNumber(form.extra_hour)}
                                    </Col>
                                </Row>

                                <Row className=''>
                                    <Col>
                                        Descuentos
                                    </Col>
                                    <Col className='text-right'>
                                        <input type='number' className='form-control text-right' value={form.discount} name="discount"
                                            onChange={updateField} />
                                    </Col>
                                </Row>
                                <Row className='my-3 border-bottom'>
                                    <Col>
                                        Anticipos Nomina
                                    </Col>
                                    <Col className='text-right'>
                                        {formatNumber(listExpense.filter(d => d.checked === true)
                                            .reduce((a, b) => a + parseFloat(b.value), 0))}
                                    </Col>
                                </Row>
                                <Row className='my-1'>
                                    <Col>
                                        Adelantos Adicionales
                                    </Col>
                                    <Col className='text-right'>
                                        <input type='number' className='form-control text-right' value={form.advances} name="advances"
                                            onChange={updateField} />
                                    </Col>
                                </Row>
                                <Row className=''>
                                    <Col>
                                        Insumos
                                    </Col>
                                    <Col className='text-right'>
                                        {formatNumber(form.supplies)}
                                    </Col>
                                </Row>
                                <Row className='my-1'>
                                    <Col className='border-top'>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        TOTAL A PAGAR
                                    </Col>
                                    <Col className='text-right font-weight-bold'>
                                        {formatNumber(parseFloat(form.salary) +
                                            parseFloat(form.bonification || 0) - parseFloat(form.supplies || 0) - parseFloat(form.advances)
                                            - parseFloat(form.discount || 0) - listExpense.filter(d => d.checked === true)
                                                .reduce((a, b) => a + parseFloat(b.value), 0))}
                                    </Col>
                                </Row>
                                <Row className='mt-2'>
                                    <Col className='text-center'>
                                        <button className='btn btn-sm btn-info' onClick={() => setModal(true)}>Guardar</button>
                                    </Col>
                                </Row>
                            </>}

                        </Card.Body>
                    </Card>
                </Col>
                <Col>
                    <Card>
                        <Card.Body>
                            <Row>
                                <Col>
                                    <Row>
                                        <Col><b>Anticipos Nomina</b></Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <Table className='table table-sm table-bordered'>
                                                <thead className='thead-dark'>
                                                    <tr>
                                                        <th></th>
                                                        <th>Fecha</th>
                                                        <th>Turno</th>
                                                        <th>Tipo</th>
                                                        <th>Descripción</th>
                                                        <th>Metodo de Pago</th>
                                                        <th>Valor</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {listExpense.map((value, k) => <tr key={k}>
                                                        <td><input type='checkbox' checked={value.checked} onChange={() => onChangeCheckbox(value)} /></td>
                                                        <td>{value.registration_date}</td>
                                                        <td>{value.cashier_id}</td>
                                                        <td>{value.parameter.description}</td>
                                                        <td>{value.description}</td>
                                                        <td>{value.method_payment.description}</td>
                                                        <td align='right'>{value.value_formated}</td>
                                                    </tr>)}
                                                </tbody>
                                                <tfoot>
                                                    <tr>
                                                        <th colSpan={5}>TOTAL</th>
                                                        <th className='text-right'>{formatNumber(listExpense
                                                            .reduce((a, b) => a + parseFloat(b.value), 0))}</th>
                                                    </tr>
                                                </tfoot>
                                            </Table>
                                        </Col>
                                    </Row>

                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Row>
                                        <Col><b>Compras en Tienda</b></Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <Table className='table table-sm table-bordered'>
                                                <thead className='thead-dark'>
                                                    <tr>
                                                        <th></th>
                                                        <th>Id</th>
                                                        <th>Fecha</th>
                                                        <th>Productos</th>
                                                        <th>Valor</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {listCredit.map((value, k) => <tr key={k}>
                                                        <td><input type='checkbox' checked={value.checked} onChange={() => onChangeCheckbox(value)} /></td>
                                                        <td>{value.cart_worker_id}</td>
                                                        <td>{value.created_at}</td>
                                                        <td>{value.products}</td>
                                                        <td align='right'>{formatNumber(value.amount)}</td>
                                                    </tr>)}
                                                </tbody>
                                                <tfoot>
                                                    <tr>
                                                        <th colSpan={3}>TOTAL</th>
                                                        <th className='text-right'>{formatNumber(listCredit
                                                            .reduce((a, b) => a + parseFloat(b.amount), 0))}</th>
                                                    </tr>
                                                </tfoot>
                                            </Table>
                                        </Col>
                                    </Row>

                                </Col>
                            </Row>

                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <ModalPaymentPayroll show={modal}
                turn_active={turnActive}
                onHide={() => setModal(false)} listMethod={listMethod}
                total={parseFloat(form.salary) +
                    parseFloat(form.bonification || 0) - parseFloat(form.supplies || 0) - parseFloat(form.advances)
                    - parseFloat(form.discount || 0) - listExpense.filter(d => d.checked === true)
                        .reduce((a, b) => a + parseFloat(b.value), 0)} onSubmit={onSubmit} />
        </TemplateMenu>
    )
}

const mapsState = (props) => {
    let { user, parameter, payroll, turn } = props

    console.log('user', user);


    return {
        fetching_list: user.fetching_list,
        data_users: user.list,
        fetching_segment: parameter.fetching_segment,
        data_segment: parameter.data_segment,
        fetching_create: payroll.fetching_create,
        fetching_active: turn.fetching_active,
        row: turn.row,
        fetching_expense: payroll.fetching_expense,
        data_expense: payroll.data_expense,
    }
}
export default connect(mapsState, {
    getAllUser, onCreatePayroll, getParameterBySegment, getExpenseForPayroll,
    toogleLoader
})(CreaatePayrollPage);