import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react'
import { Col, Container, Row, Table } from 'react-bootstrap';
import { connect } from 'react-redux';
import { getListAdmin, onCreateExpenseAdmin, onDeleteExpenseAdmin, onEditExpenseAdmin } from '../../Redux/expenseDuck';
import ModalPhoto from './modal.photo';
import { DownloadTableExcel } from 'react-export-table-to-excel';
import { RiFileExcel2Fill } from 'react-icons/ri';
import ItemTableExpenseAdmin from './item.table.expense.admin';
import { formatNumber } from '../../utils';
import ModalExpenseAdmin from './modal.expense.admin';

const OperationCostAdminPage = ({ listPayment, fetching_list, data, errors, listTurn, listExpense,
    fetching_create, getListAdmin, listOperator, onCreateExpenseAdmin, onEditExpenseAdmin, onDeleteExpenseAdmin,
    fetching_delete
}) => {
    const [form, setForm] = useState({
        date_init: moment().format("YYYY-MM-01"), date_end: moment().format("YYYY-MM-DD"), status_id: 3,
        payment_method_id: 0, concept_id: 0, deleted: false, turn_id: "0", admin: true
    })
    const [list, setList] = useState([])

    const [modal, setModal] = useState(false)
    const [moreFilter, setMoreFilter] = useState(false)
    const [modalPhoto, setModalPhoto] = useState(false)
    const [photo, setPhoto] = useState(null)
    const tableRef = useRef(null);

    useEffect(() => {
        getListAdmin(form)
    }, [])

    useEffect(() => {
        if (fetching_create === 2) {
            setModal(false)
            getListAdmin(form)
        }
    }, [fetching_create])

    useEffect(() => {
        if (fetching_delete === 2) {
            setModal(false)
            getListAdmin(form)
        }
    }, [fetching_delete])

    useEffect(() => {
        if (fetching_list === 2) {
            setList(data);
        }
    }, [fetching_list])

    const updateField = (e) => {

        if (e.target.type === 'checkbox') {
            setForm({
                ...form,
                [e.target.name]: e.target.checked
            })
        } else {
            setForm({
                ...form,
                [e.target.name]: e.target.value
            })
        }
    }

    const onDelete = (item) => {
        if (window.confirm("¿Realmente deseas Borrar Este Registro?")) {
            onDeleteExpenseAdmin(item.id)
        }
    }

    const onFilter = () => {
        getListAdmin(form)
    }

    const onShowPhoto = (photo) => {
        setModalPhoto(true)
        setPhoto(photo)
    }

    return (
        <Container fluid className='mt-4'>
            <Row className='my-4'>
                <Col>
                    <button className='btn btn-sm btn-success' onClick={() => setModal(true)}>Agregar Gasto Administrativo</button>
                </Col>
            </Row>
            <Row>
                <Col lg={3}>
                    <div className="form-group">
                        <label>Fecha de Inicio</label>
                        <input
                            type="date"
                            className="form-control"
                            value={form.date_init}
                            name="date_init"
                            onChange={updateField}
                        />
                    </div>
                </Col>
                <Col lg={3}>
                    <div className="form-group">
                        <label>Fecha Fin</label>
                        <input
                            type="date"
                            className="form-control"
                            value={form.date_end}
                            name="date_end"
                            onChange={updateField}
                        />
                    </div>
                </Col>
                <Col lg={2}>
                    <div className="form-group">
                        <label>Más Filtros</label>
                        <input
                            type="checkbox"
                            className="form-control"
                            checked={moreFilter}
                            onChange={(e) => setMoreFilter(e.target.checked)}
                        />
                    </div>
                </Col>
                <Col lg={2} className="d-flex align-items-center">
                    <button className='btn btn-sm btn-warning' onClick={onFilter}>Filtrar</button>
                </Col>
            </Row>
            {moreFilter && <Row>
                <Col lg={3}>
                    <div className="form-group">
                        <label>Método de Pago</label>
                        <select className='form-control' name="payment_method_id" onChange={updateField}>
                            <option value={0}>Todos</option>
                            {listPayment.map((v, k) => <option value={v.id} key={k}>{v.description}</option>)}
                        </select>
                        {errors.payment_method_id !== undefined && (<label className="error-label">{errors.payment_method_id}</label>)}

                    </div>
                </Col>
                <Col lg={2}>
                    <div className="form-group">
                        <label>Concepto</label>
                        <select className='form-control' name="concept_id" onChange={updateField}>
                            <option value={0}>Todos</option>
                            {listExpense.map((v, k) => <option value={v.id} key={k}>{v.description}</option>)}
                        </select>
                        {errors.method_payment_id !== undefined && (<label className="error-label">{errors.method_payment_id}</label>)}

                    </div>
                </Col>
                <Col lg={2}>
                    <div className="form-group">
                        <label>Borrados?</label>
                        <input
                            type="checkbox"
                            className="form-control"
                            value={form.deleted}
                            name="deleted"
                            onChange={updateField}
                        />
                    </div>
                </Col>
            </Row>}

            <Row className='d-flex justify-content-center'>
                <Col lg={12}>
                    <Row>
                        <Col>
                            <DownloadTableExcel
                                filename="gastos"
                                sheet="gastos"
                                currentTableRef={tableRef.current}>
                                <RiFileExcel2Fill color='green' size={30} className='cursor-pointer' />
                            </DownloadTableExcel>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Table className='table-sm table-bordered table-hover' ref={tableRef} responsive>
                                <thead className='thead-dark'>
                                    <tr>
                                        <th>Fecha</th>
                                        <th>Concepto</th>
                                        <th>Descripción</th>
                                        <th>Método de Pago</th>
                                        <th>Soporte</th>
                                        <th className='text-center'>Valor</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {(list.length > 0) ? list.map((l, k) => <ItemTableExpenseAdmin key={k} item={l}
                                        onDelete={onDelete}
                                        onEdit={onEditExpenseAdmin}
                                        onShowPhoto={onShowPhoto} />) : <tr><td colSpan={6}>No hay registros</td></tr>}
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <td colSpan={5}><b>TOTAL</b></td>
                                        <td align='right'><b>{formatNumber(list.reduce((a, b) => a + parseFloat(b.amount), 0))}</b></td>
                                    </tr>
                                </tfoot>
                            </Table>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <ModalPhoto show={modalPhoto} photo={photo} onHide={() => setModalPhoto(false)} />
            <ModalExpenseAdmin
                listTurn={listTurn}
                show={modal}
                listExpense={listExpense}
                listPayment={listPayment}
                list_operators={listOperator}
                onSuccess={onCreateExpenseAdmin}
                errors={errors}
                onHide={() => setModal(false)}
            />
        </Container>
    )
}

const mapsState = (props) => {
    let { expense } = props
    return {
        fetching_list: expense.fetching_list_admin,
        data: expense.data_admin,
        fetching_create: expense.fetching_create,
        fetching_delete: expense.fetching_delete_admin,
        errors: expense.errors,

    }
}

export default connect(mapsState, { onCreateExpenseAdmin, getListAdmin, onEditExpenseAdmin, onDeleteExpenseAdmin })(OperationCostAdminPage);