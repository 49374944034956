import React, { useState } from 'react'
import { Card, Col, Row } from 'react-bootstrap';
import InputField from '../../Components/Widgets/InputField';

const BusinessInfoPage = ({ storeBusiness, listDepartments, listTypeDocumentIdentification, listTypeOrganization,
    listTypeLiabilities, listTypeRegimes, formBusiness, updateInputBusiness,
    listMunicipalities
}) => {
    return (
        <Row className='d-flex justify-content-center'>
            <Col lg={8}>
                <Card>
                    <Card.Header>
                        <Row>
                            <Col><Card.Title>Información de la empresa</Card.Title></Col>
                            <Col className='d-flex justify-content-end'>
                                <button className='btn btn-sm btn-success' onClick={storeBusiness}>Guardar</button>
                            </Col>
                        </Row>
                    </Card.Header>
                    <Card.Body>
                        <Card.Title>Empresa</Card.Title>
                        <Row>
                            <Col>
                                <div className="form-group">
                                    <label>Razón social</label>
                                    <InputField
                                        placeholder="Razón social"
                                        value={formBusiness.business_name || ''}
                                        name="business_name"
                                        onChange={updateInputBusiness}
                                    // error={errors.business}
                                    />
                                </div>
                            </Col>
                            <Col>
                                <div className="form-group">
                                    <label>Registro de Comerciante</label>
                                    <InputField
                                        placeholder="Registro de Comerciante"
                                        value={formBusiness.merchant_registration || ''}
                                        name="merchant_registration"
                                        onChange={updateInputBusiness}
                                    // error={errors.business}
                                    />
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <div className="form-group">
                                    <label>Tipo de Identificación</label>
                                    <select className='form-control'
                                        name='type_document_identification_id'
                                        value={formBusiness.type_document_identification_id}
                                        onChange={updateInputBusiness}>
                                        <option value={0}>Seleccione</option>
                                        {listTypeDocumentIdentification.map((a, b) => <option key={a.id} value={a.id}>{a.name}</option>)}
                                    </select>

                                </div>
                            </Col>
                            <Col>
                                <div className="form-group">
                                    <label>Tipo de Persona</label>
                                    <select className='form-control'
                                        name='type_organization_id'
                                        value={formBusiness.type_organization_id}
                                        onChange={updateInputBusiness}>
                                        <option value={0}>Seleccione</option>
                                        {listTypeOrganization.map((a, b) => <option key={a.id} value={a.id}>{a.name}</option>)}
                                    </select>

                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <div className="form-group">
                                    <label>Tipo de Iva</label>
                                    <select className='form-control'
                                        name='type_regime_id'
                                        value={formBusiness.type_regime_id}
                                        onChange={updateInputBusiness}>
                                        <option value={0}>Seleccione</option>
                                        {listTypeRegimes.map((a, b) => <option key={a.id} value={a.id}>{a.name}</option>)}
                                    </select>

                                </div>
                            </Col>
                            <Col>
                                <div className="form-group">
                                    <label>Tipo de Responsabilidad</label>
                                    <select className='form-control'
                                        name='type_liability_id'
                                        value={formBusiness.type_liability_id}
                                        onChange={updateInputBusiness}>
                                        <option value={0}>Seleccione</option>
                                        {listTypeLiabilities.map((a, b) => <option key={a.id} value={a.id}>{a.name}</option>)}
                                    </select>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <div className="form-group">
                                    <label>Número de Identificación</label>
                                    <InputField
                                        type={"number"}
                                        placeholder="Número de Identificación"
                                        value={formBusiness.identification_number || ''}
                                        name="identification_number"
                                        onChange={updateInputBusiness}
                                    // error={errors.business}
                                    />
                                </div>
                            </Col>
                            <Col>
                                <div className="form-group">
                                    <label>Verificación</label>
                                    <InputField
                                        type={"number"}
                                        placeholder="Verificación"
                                        value={formBusiness.dv || ''}
                                        name="dv"
                                        onChange={updateInputBusiness}
                                    // error={errors.business}
                                    />
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <div className="form-group">
                                    <label>Departamento</label>
                                    <select className='form-control'
                                        name='department_id'
                                        value={formBusiness.department_id}
                                        onChange={updateInputBusiness}>
                                        <option value={0}>Seleccione</option>
                                        {listDepartments.map((a, b) => <option key={a.id} value={a.id}>{a.name}</option>)}

                                    </select>
                                </div>
                            </Col>
                            <Col>
                                <div className="form-group">
                                    <label>Ciudad</label>
                                    <select className='form-control'
                                        name='municipality_id'
                                        value={formBusiness.municipality_id}
                                        onChange={updateInputBusiness}>
                                        <option value={0}>Seleccione</option>
                                        {listMunicipalities.map((a, b) => <option key={a.id} value={a.id}>{a.name}</option>)}

                                    </select>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={7}>
                                <div className="form-group">
                                    <label>Dirección</label>
                                    <InputField
                                        placeholder="Dirección"
                                        value={formBusiness.address || ''}
                                        name="address"
                                        onChange={updateInputBusiness}
                                    // error={errors.business}
                                    />
                                </div>
                            </Col>
                            <Col>
                                <div className="form-group">
                                    <label>Telefono</label>
                                    <InputField
                                        type={"number"}
                                        placeholder="Telefono"
                                        value={formBusiness.phone || ''}
                                        name="phone"
                                        onChange={updateInputBusiness}
                                    // error={errors.business}
                                    />
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={7}>
                                <div className="form-group">
                                    <label>Email</label>
                                    <InputField
                                        placeholder="Email"
                                        value={formBusiness.email || ''}
                                        name="email"
                                        onChange={updateInputBusiness}
                                    // error={errors.business}
                                    />
                                </div>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
            </Col>
        </Row>
    )
}

export default BusinessInfoPage;