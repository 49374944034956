import React, { useEffect, useState } from 'react'
import Select from 'react-select'
import uuid from 'react-uuid';
import { formatNumber, getHeaders } from '../../utils';
import { Form, InputGroup } from 'react-bootstrap';
import AsyncSelect from 'react-select/async';

const API_URL = process.env.REACT_APP_API_URL

const customStyles = {
    control: (provided) => ({
        ...provided,
        minHeight: "32px",
        fontSize: "14px",
        borderRadius: "4px",
    }),
    valueContainer: (provided) => ({
        ...provided,
        padding: "0 8px",
    }),
    input: (provided) => ({
        ...provided,
        margin: "0",
    }),
};

const RowProduct = ({ onDelete, onAddItem, formEdit, onEditItem }) => {

    const [form, setForm] = useState({ amount: 1, cost: 0, product: {}, subtotal: '' })
    const [selectedProduct, setSelectedProduct] = useState({ tax: { description: '' }, measure_unit: { description: '' } });
    const [showButton, setShowButton] = useState(false)
    const [productSearch, setProductSearch] = useState('')
    const [listSearch, setListSearch] = useState([])

    useEffect(() => {
        if (Object.keys(formEdit).length > 0) {
            setForm(formEdit)
            setSelectedProduct(formEdit.product)
            setShowButton(true)
            setProductSearch({ label: formEdit.product.name, value: formEdit.product.id });
        }

    }, [formEdit])

    const loadOptions = async (inputValue) => {
        let branch = JSON.parse(localStorage.getItem("branch"))
        let url = `${API_URL}/api/products-search/${inputValue}?branch=${branch.id}`

        let _search = await fetch(url, {
            headers: getHeaders()
        }).then(resp => resp.json())

        setListSearch(_search)

        return _search;
    }

    const updateInput = (e) => {
        if (e.target.name === 'amount') {
            setForm({
                ...form,
                [e.target.name]: e.target.value,
                ["cost"]: form.subtotal / e.target.value
            })
        }

        if (e.target.name === 'subtotal') {
            setForm({
                ...form,
                [e.target.name]: e.target.value,
                ["cost"]: e.target.value / form.amount
            })
        }
    }

    const onAddItemBefore = (item) => {
        item._id = uuid()

        if (item.subtotal !== '') {
            onAddItem(item)
        } else {
            alert("Debes ingresar el costo");
        }
    }

    return (
        <tr>
            <td>
                <AsyncSelect
                    styles={customStyles}
                    isClearable
                    value={productSearch}
                    loadOptions={loadOptions}
                    onChange={(e) => {
                        if (e !== null) {
                            setSelectedProduct(e)
                            setForm({
                                ...form,
                                "cost": 0,
                                "product": e
                            })
                            setShowButton(true)
                            setProductSearch({ label: e.name, value: e.id });
                        } else {
                            setProductSearch('')
                        }
                    }}
                    placeholder="Busca el producto"
                />

            </td>
            <td align='right'>
                <InputGroup className="mb-3">
                    <Form.Control
                        type="number"
                        onChange={updateInput}
                        value={form.amount}
                        name='amount'
                        placeholder="Cantidad"
                        aria-describedby="basic-addon2"
                    />
                    <InputGroup.Text id="basic-addon2">{selectedProduct.measure_unit.description}</InputGroup.Text>
                </InputGroup>


            </td>
            <td align='right'>
                {formatNumber(form.cost)}
            </td>
            <td align='right'>{selectedProduct.tax.description}</td>
            <td align='right'>
                <input type={"number"} className='form-control' placeholder='Subtotal' name='subtotal' value={form.subtotal} onChange={updateInput} />
            </td>

            {showButton && <td>

                {form._id !== undefined
                    ? <button className='btn btn-sm btn-info mx-2' onClick={() => onEditItem(form)}>Editar</button> :
                    <button className='btn btn-sm btn-success mx-2' onClick={() => onAddItemBefore(form)}>Guardar</button>
                }
                <button className='btn btn-sm btn-danger' onClick={() => onDelete(form)}>Cancelar</button>
            </td>}

        </tr>
    )
}

export default RowProduct;