import React from 'react'
import { Card, Col, Row } from 'react-bootstrap';
import InputField from '../../Components/Widgets/InputField';

const CertifcateInfoPage = ({ formBusiness, storeCertForm, uploadFile, base64, formCert, updateInputCert }) => {
    return (
        <Row className='d-flex justify-content-center'>
            <Col lg={5}>
                <Card>
                    <Card.Header>
                        <Row>
                            <Col><Card.Title>Certificado</Card.Title></Col>
                            <Col className='d-flex justify-content-end'>
                                {formBusiness.api_token !== '' &&
                                    <button className='btn btn-sm btn-success' onClick={storeCertForm}>Guardar</button>
                                }

                            </Col>
                        </Row>
                    </Card.Header>
                    <Card.Body>
                        <Row>
                            <Col>
                                <div className="form-group">
                                    <label>Archivo P12</label>
                                    <input type="file" className="form-control" placeholder="Sube el archivo" onChange={uploadFile} />
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <div className="form-group">
                                    <label>Certificado en Codificado</label>
                                    <textarea
                                        className='form-control'
                                        rows={8}
                                        name='certificate'
                                        value={base64}
                                    ></textarea>
                                </div>
                            </Col>
                        </Row>

                        <Row>
                            <Col>
                                <div className="form-group">
                                    <label>Password</label>
                                    <InputField
                                        placeholder="Password"
                                        value={formCert.password || ''}
                                        name="password"
                                        onChange={updateInputCert}
                                    // error={errors.business}
                                    />
                                </div>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
            </Col>
        </Row>
    )
}

export default CertifcateInfoPage;