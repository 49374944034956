import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { Card, Col, Container, Row, Table } from 'react-bootstrap';
import { getOrderByIdDetail, onScoreOrder } from '../../Redux/orderDuck'
import ItemDetailService from './item.detail.service';
import { formatNumber } from '../../utils';
import { connect } from 'react-redux';
import { Rating } from 'react-simple-star-rating'


const ViewOrderClientPage = ({ fetching_by_id, row, getOrderByIdDetail, onScoreOrder, fetching_score, fetching_rating }) => {
    let { id } = useParams();
    const [data, setData] = useState({ client: { name: '' }, operator: { name: '' }, turn: { id: 0, user: { name: '' } } })
    const [detail, setDetail] = useState([])
    const [operadores, setOperadores] = useState([])
    const [listPayment, setListPayment] = useState([])
    const [image, setImage] = useState('')
    const [rating, setRating] = useState(0)
    const [observation, setObservation] = useState('')

    // Catch Rating value
    const handleRating = (rate) => {
        setRating(rate)
    }
    // Optinal callback functions
    const onPointerEnter = () => console.log('Enter')
    const onPointerLeave = () => console.log('Leave')
    const onPointerMove = (value, index) => console.log(value, index)

    console.log('fetching_score', fetching_score);


    useEffect(() => {
        if (fetching_rating === 2) {
            window.location.reload()
        }
    }, [fetching_rating])


    useEffect(() => {
        if (fetching_by_id === 2) {

            setObservation(row.observation_score)
            setRating(row.rating)

            console.log('row.rating', row.rating);


            setData(row)
            if (row.photos.length > 0) {
                setImage(row.photos[0].full_path)
            }

            setDetail(row.detail)
            setOperadores(row.operators)
            setListPayment(row.payments)
        }
    }, [fetching_by_id])

    useEffect(() => {
        getOrderByIdDetail(id)
    }, [id])

    const onPreScoreOrder = () => {
        if (rating !== 0) {
            onScoreOrder(id, rating, observation)
        } else {
            alert("No haz Calificado el Servicio")
        }
    }

    console.log('rating', rating);


    return (
        <Container fluid>
            <Row className='d-flex justify-content-center mt-4'>
                <Col lg={5}>
                    <Card>
                        <Card.Body>
                            <Row>
                                <Col>Orden</Col>
                                <Col>{data.id}</Col>
                            </Row>
                            <Row>
                                <Col>Cliente</Col>
                                <Col>{data.client.name}</Col>
                            </Row>
                            <Row>
                                <Col>Fecha</Col>
                                <Col>{data.updated_at_text}</Col>
                            </Row>
                            {data.observations !== '' &&
                                <Row>
                                    <Col>Observaciones</Col>
                                    <Col>{data.observations}</Col>
                                </Row>}

                            {operadores.map((d, k) => <Row key={k}>
                                <Col>Operador</Col>
                                <Col>{d.user.name}</Col>
                            </Row>)}
                            {image !== '' && <Row>
                                <Col>Imagen</Col>
                                <Col><img src={image} className="img-order " /></Col>
                            </Row>}

                            <Row className='mt-4'>
                                <Col><h3>Servicios</h3></Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Table className='table-sm table-bordered'>
                                        <thead className='thead-dark'>
                                            <tr>
                                                <th>Servicio</th>
                                                <th>Costo</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {detail.map((d, k) => <ItemDetailService key={k} item={d} />)}
                                        </tbody>
                                        <tfoot>
                                            <tr>
                                                <td>TOTAL</td>
                                                <td align='right'>{formatNumber(detail.reduce((a, b) => a = a + parseFloat(b.price), 0))}</td>
                                            </tr>
                                        </tfoot>
                                    </Table>
                                </Col>
                            </Row>
                            {data.status_id != 1 &&
                                <Row>
                                    <Col>
                                        <Row>
                                            <Col className='text-center'>¿Si deseas puedes calificar nuestro Servicio?</Col>
                                        </Row>
                                        <Row className='my-3'>
                                            <Col className='d-flex justify-content-center'>
                                                <Rating
                                                    initialValue={rating}
                                                    ratingValue={rating}
                                                    onClick={handleRating}
                                                    onPointerEnter={onPointerEnter}
                                                    onPointerLeave={onPointerLeave}
                                                    onPointerMove={onPointerMove}
                                                /* Available Props */
                                                />
                                            </Col>
                                        </Row>
                                        <Row className='my-3'>
                                            <Col className='d-flex justify-content-center'>
                                                <textarea className='form-control' placeholder='Observaciones' value={observation}
                                                    onChange={(e) => setObservation(e.target.value)}>
                                                </textarea>
                                            </Col>
                                        </Row>
                                        {data.is_rated === false &&
                                            <Row>
                                                <Col className='d-flex justify-content-center'>
                                                    <button className='btn btn-sm btn-info' onClick={onPreScoreOrder}>Calificar</button>
                                                </Col>
                                            </Row>}

                                    </Col>
                                </Row>}

                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    )
}

const mapsState = (props) => {
    let { order } = props;
    return {
        fetching_by_id: order.fetching_by_id,
        fetching_score: order.fetching_score,
        fetching_rating: order.fetching_rating,
        row: order.row,
    }
}

export default connect(mapsState, { getOrderByIdDetail, onScoreOrder })(ViewOrderClientPage);