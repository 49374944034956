import React, { useEffect, useRef, useState } from 'react'
import { connect } from 'react-redux';
import MenuTemplate from '../../template/menu.template';
import { Col, Form, Row, Table } from 'react-bootstrap';
import { getListInventoryAvailable, onValidateInventory } from '../../Redux/inventoryDuck'
import { toogleLoader } from '../../Redux/alertDuck'
import { Link, useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { DownloadTableExcel } from 'react-export-table-to-excel';
import { RiFileExcel2Fill } from 'react-icons/ri';
import ImgPDF from '../../Images/pdf.png'
import { formatNumber } from '../../utils';

const ExistanceProductsPage = ({ getListInventoryAvailable, fetching_list, data, toogleLoader, onValidateInventory,
    fetching_validate
}) => {
    const [form, setForm] = useState({ date_init: '', date_end: '', branch_id: '', business_id: '' })
    const [list, setList] = useState([])
    const [listFilter, setListFilter] = useState([])
    const history = useHistory();
    const tableRef = useRef(null);


    useEffect(() => {
        if (fetching_list === 2) {
            toogleLoader(false)
            setList(data)
            setListFilter(data)

        }
    }, [fetching_list])

    useEffect(() => {

        let { date_init, date_end } = JSON.parse(localStorage.getItem("date"))
        let branch = JSON.parse(localStorage.getItem("branch"))
        let { business } = branch


        console.log('branch', branch);


        setForm({
            ...form,
            ["date_init"]: date_init,
            ["date_end"]: date_end,
            ["branch_id"]: branch.id,
            ["business_id"]: business.id,
        })

        toogleLoader(true)
        getListInventoryAvailable()
    }, [])

    useEffect(() => {
        if (fetching_validate === 2) {
            getListInventoryAvailable()
        }
    }, [fetching_validate])

    const onReloadInventory = () => {
        toogleLoader(true)
        onValidateInventory()
    }

    const onFilter = (e) => {

        if (e.target.value !== '') {
            setListFilter(list.filter((item) =>
                item.name.toLowerCase().includes(e.target.value.toLowerCase())
            ))
        } else {
            setListFilter(list)
        }
    }

    const updateCheck = (e) => {
        if (e.target.checked === true) {
            setListFilter(list.filter((item) =>
                item.sales > 0 || item.lowdown > 0 || item.inputs > 0 || item.purchases > 0
            ))
        } else {
            setListFilter(list)
        }
    }

    const openDetail = (d) => {
        window.open(`/existencias/${d.id}`, "_blank");
    }

    return (
        <MenuTemplate>
            <Row className='my-3'>
                <Col className='text-center' lg={2}>
                    <h4>Existencias</h4>

                </Col>
                <Col lg={2}>
                    <Form.Check
                        type="switch"
                        id="status"
                        checked={form.status}
                        className='form-control'
                        name="status"
                        label={`Ver con movimientos `}
                        onChange={updateCheck}
                    />
                </Col>
                <Col lg={3}>
                    <button className='btn btn-sm btn-info' onClick={onReloadInventory}>Recalcular</button>
                </Col>
                <Col lg={4}>
                    <input className='form-control' placeholder='Buscar Producto' onChange={onFilter} />
                </Col>
            </Row>
            <Row>
                <Col >
                    <DownloadTableExcel
                        filename="inventario"
                        sheet="inventario"
                        currentTableRef={tableRef.current}>
                        <RiFileExcel2Fill color='green' size={30} className='cursor-pointer' />
                        {/* <button className='btn btn-sm btn-info'> Export excel </button> */}
                    </DownloadTableExcel>
                    <a
                        href={`${process.env.REACT_APP_API_URL}/api/inventory-available-view-pdf?date_init=${form.date_init}&date_end=${form.date_end}&branch_id=${form.branch_id}&business_id=${form.business_id}`}
                        target="_blank" rel="noreferrer">
                        <img src={ImgPDF} alt='img-pdf' width={50} className='cursor-pointer' />
                    </a>


                </Col>
            </Row>
            <Row className='d-flex justify-content-center'>
                <Col lg={12}>
                    <Table className='table table-sm table-bordered table-hover' ref={tableRef}>
                        <thead className='thead-dark'>
                            <tr>
                                <th></th>
                                <th>Id</th>
                                <th>Categoria</th>
                                <th>Producto</th>
                                <th>Precio</th>
                                <th>Inicial</th>
                                <th>Compras</th>
                                <th>Insumos</th>
                                <th>Ventas</th>
                                <th>Bajas</th>
                                <th>Ajustes</th>
                                <th>Total Disponible</th>
                            </tr>
                        </thead>
                        <tbody>
                            {listFilter.length > 0 ? listFilter.map((d, k) => <tr key={k}
                                className='cursor-pointer'
                                onClick={() => openDetail(d)}>
                                <td>{k + 1}</td>
                                <td>{d.id}</td>
                                <td>{d.category}</td>
                                <td>{d.name}</td>
                                <td align='right'>{formatNumber(d.price)}</td>
                                <td align='right'>{d.initial}</td>
                                <td align='right'>{d.purchases}</td>
                                <td align='right'>{d.inputs}</td>
                                <td align='right'>{d.sales}</td>
                                <td align='right'>{d.lowdown}</td>
                                <td align='right'>{d.adjust}</td>
                                <td align='right'>{parseFloat(d.available)}</td>
                            </tr>) : <tr>
                                <td colSpan={7} align='center'>No tienes Datos</td></tr>}

                        </tbody>
                    </Table>
                </Col>
            </Row>
        </MenuTemplate>
    )
}

const mapsState = (props) => {
    let { inventory } = props

    return {
        fetching_list: inventory.fetching_list,
        fetching_validate: inventory.fetching_validate,
        data: inventory.data,
    }
}


export default connect(mapsState, { getListInventoryAvailable, toogleLoader, onValidateInventory })(ExistanceProductsPage);