import React, { useEffect, useState } from 'react'
import { Card, Col, Row, Table } from 'react-bootstrap';
import TemplateMenu from '../../template/menu.template'
import { updateInventoryInitital } from '../../Redux/inventoryDuck'
import { getProductsInitial } from '../../Redux/productDuck'
import { toogleAlert } from '../../Redux/alertDuck'
import { connect } from 'react-redux';
import RowTableInitial from './row.table.initial';
import { useHistory } from 'react-router-dom';
import { formatNumber } from '../../utils';


const InitialInventoryPage = ({ getProductsInitial, fetching_initial, data, updateInventoryInitital, toogleAlert }) => {

    const [list, setList] = useState([])
    const history = useHistory();

    useEffect(() => {
        if (fetching_initial === 2) {
            setList(data)
            toogleAlert(false)
        }
    }, [fetching_initial])

    useEffect(() => {
        toogleAlert(true)
        getProductsInitial()
    }, [])

    const onUpdateRow = (row) => {
        updateInventoryInitital(row)
    }

    return (
        <TemplateMenu>
            <Row className='my-4'>
                <Col><h3 className='text-center'>Inventario Inicial</h3></Col>
            </Row>
            <Row className='d-flex justify-content-center'>
                <Col lg={8}>
                    {list.map((d, key) => <Row className='mt-2' key={key}>
                        <Col>
                            <Card>
                                <Card.Body>
                                    <Card.Title>{d.name}, Total Productos: ({d.products.length})</Card.Title>
                                    <Row className='d-flex justify-content-center mt-2'>

                                        <Col>
                                            <Table className='table-sm table-bordered table-hover'>
                                                <thead>
                                                    <tr className='thead-dark'>
                                                        <th>#</th>
                                                        <th width="50%">Producto</th>
                                                        <th width="10%">Medida</th>
                                                        <th>Cantidad</th>
                                                        <th>Costo</th>
                                                        <th>Total</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {(d.products.length > 0)
                                                        ? d.products.map((row, k) => <RowTableInitial index={k + 1} key={k} item={row} onUpdateRow={onUpdateRow} />)
                                                        : <tr>
                                                            <td colSpan={5} align='center'>NO tienes Productos de Inventario </td>
                                                        </tr>
                                                    }
                                                </tbody>
                                                <tfoot>
                                                    <tr>
                                                        <th colSpan={3}>TOTAL</th>
                                                        <th className='text-right pr-5'>
                                                            {d.products.length > 0
                                                                ? d.products.reduce((a, b) => a + parseFloat(b.amount || 0), 0)
                                                                : 0}
                                                        </th>
                                                        <th className='text-right pr-5'>
                                                            0
                                                        </th>
                                                        <th className='text-right'>
                                                            {d.products.length > 0
                                                                ? formatNumber(d.products.reduce((a, b) => a + parseFloat(b.amount || 0) * parseFloat(b.cost), 0))
                                                                : 0}
                                                        </th>
                                                    </tr>
                                                </tfoot>
                                            </Table>

                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>)}
                </Col>
            </Row>


        </TemplateMenu>
    )
}

const mapsState = (props) => {
    let { product } = props
    return {
        fetching_initial: product.fetching_initial,
        data: product.data,
    }
}

export default connect(mapsState, { getProductsInitial, updateInventoryInitital, toogleAlert })(InitialInventoryPage);