import axios from 'axios'
import { getHeaders, getHeadersImage, validateInput } from '../utils';
const API_URL = process.env.REACT_APP_API_URL

let initialState = {
    fetching_list: 0,
    fetching_create: 0,
    fetching_admin: 0,
    fetching_assoc: 0,
    data: [],
    data_admin: [],
    selected: {},
    errors: {},
    fetching_delete: 0
}

let LIST_CATEGORY_PRODUCT = "LIST_CATEGORY_PRODUCT";
let LIST_CATEGORY_PRODUCT_SUCCESS = "LIST_CATEGORY_SUCCESS";

let CREATE_CATEGORY_PRODUCT = "CREATE_CATEGORY_PRODUCT"
let CREATE_CATEGORY_PRODUCT_SUCCESS = "CREATE_CATEGORY_PRODUCT_SUCCESS"
let CREATE_CATEGORY_PRODUCT_ERROR = "CREATE_CATEGORY_PRODUCT_ERROR"
let DELETE_CATEGORY_PRODUCT = "DELETE_CATEGORY_PRODUCT";
let DELETE_CATEGORY_PRODUCT_SUCCESS = "DELETE_CATEGORY_PRODUCT_SUCCESS";

let UPDATE_CATEGORY_PRODUCT = "UPDATE_CATEGORY_PRODUCT";
let UPDATE_CATEGORY_PRODUCT_SUCCESS = "UPDATE_CATEGORY_PRODUCT_SUCCESS";


export default function reducer(state = initialState, action) {
    switch (action.type) {
        case LIST_CATEGORY_PRODUCT:
            return { ...state, fetching_list: 1, fetching_create: 0, fetching_assoc: 0 }
        case LIST_CATEGORY_PRODUCT_SUCCESS:
            return { ...state, fetching_list: 2, data: action.payload }
        case CREATE_CATEGORY_PRODUCT:
            return { ...state, fetching_create: 1 }
        case CREATE_CATEGORY_PRODUCT_SUCCESS:
            return { ...state, fetching_create: 2 }
        case CREATE_CATEGORY_PRODUCT_ERROR:
            return { ...state, fetching_create: 3, errors: action.payload }
        case DELETE_CATEGORY_PRODUCT:
            return { ...state, fetching_delete: 1 }
        case DELETE_CATEGORY_PRODUCT_SUCCESS:
            return { ...state, fetching_delete: 2 }
        case UPDATE_CATEGORY_PRODUCT:
            return { ...state, fetching_create: 1 }
        case UPDATE_CATEGORY_PRODUCT_SUCCESS:
            return { ...state, fetching_create: 2 }

        default:
            return state;
    }
}

export let getCategoryProduct = () => (dispatch, getState) => {
    dispatch({
        type: LIST_CATEGORY_PRODUCT
    })

    if (getState().user.loggedIn) {

        let branch = JSON.parse(localStorage.getItem("branch"))

        axios.get(`${API_URL}/api/category-product?branch_id=${branch.id}`, {
            headers: getHeaders()
        }).then(resp => {
            dispatch({
                type: LIST_CATEGORY_PRODUCT_SUCCESS,
                payload: resp.data.results
            });

        }).catch(function (error) {
            console.log(error);
        });
    } else {
        console.log("no Ingreso");
    }
}


export let createCategoryProduct = (form) => (dispatch, getState) => {

    dispatch({
        type: CREATE_CATEGORY_PRODUCT
    })


    if (getState().user.loggedIn) {

        let rules = [
            { field: "name", msg: "El nombre es requerido" },
            { field: "priority", msg: "El Orden es requerido" },
        ]

        let validation = validateInput(form, rules);

        if (validation !== null) {
            dispatch({
                type: CREATE_CATEGORY_PRODUCT_ERROR,
                payload: validation
            })
            return;
        }

        let branch = JSON.parse(localStorage.getItem("branch"))

        const _form = new FormData();
        _form.append("id", form.id);
        _form.append("name", form.name);
        _form.append("priority", form.priority);
        _form.append("status", form.status || true);
        _form.append("branch_id", branch.id);
        _form.append("type_category", form.type_category);

        form.branch_id = branch.id
        // form.append("image", param.image);

        axios.post(`${API_URL}/api/category-product`, form, {
            headers: getHeaders()
        }).then(resp => {
            dispatch({
                type: CREATE_CATEGORY_PRODUCT_SUCCESS,
                payload: resp.data.results,

            });

        }).catch(error => {
            let err = {}

            for (const [key, value] of Object.entries(error.response.data.errors)) {
                if ('title' === key) {
                    err.title = value[0]
                }
                if ('description' === key) {
                    err.description = value[0]
                }
            }

            // dispatch({
            //     type: CATEGORY_ERROR,
            //     payload: err,
            //     form: form
            // })
        });
    } else {
        console.log("no Ingreso");
    }
}

export let onUpdateCategoryProduct = (form) => (dispatch, getState) => {

    dispatch({
        type: UPDATE_CATEGORY_PRODUCT
    })

    let rules = [
        { field: "name", msg: "El nombre es requerido" },
    ]

    let validation = validateInput(form, rules);


    if (validation !== null) {
        dispatch({
            type: CREATE_CATEGORY_PRODUCT_ERROR,
            payload: validation
        })
        return;
    }


    if (getState().user.loggedIn) {
        let url = `${API_URL}/api/category-product/${form.id}`
        console.log('url', url);

        axios.put(url, form, {
            headers: getHeaders()
        }).then(resp => {
            dispatch({
                type: UPDATE_CATEGORY_PRODUCT_SUCCESS
            });

        }).catch(error => {

        });
    } else {
        console.log("no Ingreso");
    }
}

export let deleteCategoryProduct = (form) => (dispatch, getState) => {

    dispatch({
        type: DELETE_CATEGORY_PRODUCT
    })

    if (getState().user.loggedIn) {
        axios.delete(`${API_URL}/api/category-product/${form.id}`, {
            headers: getHeaders()
        }).then(resp => {
            dispatch({
                type: DELETE_CATEGORY_PRODUCT_SUCCESS
            });

        }).catch(error => {

        });
    } else {
        console.log("no Ingreso");
    }
}