import React from 'react'

const ItemTableProduct = ({ item, onEdit }) => {
    let { category, tax } = item

    let title = (category !== undefined) ? category.description : ''
    let is_percent = item.type_value === true ? '%' : ''
    let is_value = item.type_value === false ? '$' : ''
    let percent = (item.type_value === true) ? item.percent : Math.round((item.percent / 100) * item.price);

    let is_percent_night = item.type_value_night === true ? '%' : ''
    let is_value_night = item.type_value_night === false ? '$' : ''
    let percent_night = (item.type_value_night === true) ? item.percent_night : Math.round((item.percent_night / 100) * item.price_night);

    return (
        <tr>
            <td>{title}</td>
            <td align='right'>{item.price_formated}</td>
            <td align='right'> {is_value} {percent} {is_percent}</td>
            <td align='right'>{item.price_night_formated}</td>
            <td align='right'> {is_value_night} {percent_night} {is_percent_night}</td>
            <td align='right'> {tax !== undefined && tax.description}</td>
        </tr>
    )
}

export default ItemTableProduct;