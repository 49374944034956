import React, { useEffect, useState } from 'react'
import TemplateMenu from '../../template/menu.template'
import { Card, Col, Row, Table } from 'react-bootstrap';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { connect } from 'react-redux';
import { getCategoryById, onCreateOrUpdatePrice, deleteCategoryPrice } from '../../Redux/categoryDuck'
import { getParkingType } from '../../Redux/parkingDuck'
import ModalParkingPrice from './modal.parking.price';
import { formatNumber } from '../../utils';
import { MdClose, MdEdit, MdModeEdit } from 'react-icons/md';


const EditConfigParking = ({ getCategoryById, fetching_by_id, row, onCreateOrUpdatePrice,
    deleteCategoryPrice, fetching_delete, fetching_price, getParkingType, fetching_type, data_type }) => {
    let { id } = useParams();
    const [modal, setModal] = useState(false)
    const [data, setData] = useState({})
    const [list, setList] = useState([])
    const [listType, setListType] = useState([])
    const [formEdit, setFormEdit] = useState({ id: 0, price_type: false, is_recurrent: false, additional_price: false, price: '' })

    useEffect(() => {
        if (fetching_by_id === 2) {
            setData(row)
            setList(row.category_price)
        }
    }, [fetching_by_id])

    useEffect(() => {
        if (fetching_type === 2) {
            setListType(data_type)
        }
    }, [fetching_type])

    useEffect(() => {
        if (fetching_price === 2) {
            setModal(false)
            getCategoryById(id)

        }
    }, [fetching_price])

    useEffect(() => {
        if (fetching_delete === 2) {
            getCategoryById(id)
        }
    }, [fetching_delete])

    useEffect(() => {
        getCategoryById(id)
        getParkingType()
    }, [id])


    const onCreate = (form) => {
        let _data = data;
        data.category_price = form
        onCreateOrUpdatePrice(_data)
    }

    const onEdit = (form) => {
        form.category_id = data.id
        onCreateOrUpdatePrice(form)
    }

    const onDelete = (id) => {
        if (window.confirm(`¿Deseas Eliminar el Regsitro?`)) {
            deleteCategoryPrice(id)
        }
    }

    const onEditRow = (row) => {
        setFormEdit(row)
        setModal(true)
    }

    return (
        <TemplateMenu>
            <Row className='d-flex justify-content-center mt-2'>
                <Col lg={8}>
                    <Card>
                        <Card.Body>
                            <Card.Title>{data.title}</Card.Title>
                            <p>{data.description}</p>
                            <Row className='my-3'>
                                <Col lg={3}><b>Precios</b></Col>
                                <Col>
                                    <button className='btn btn-sm btn-info' onClick={() => setModal(true)}>Agregar</button>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Table className='table table-sm table-bordered'>
                                        <thead className='thead-dark'>
                                            <tr>
                                                <th>TIPO</th>
                                                <th>TARIFA</th>
                                                <th>TARIFA ADICIONAL</th>
                                                <th>RECURRENTE</th>
                                                <th>PRECIO</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {list.map((a, k) => <tr key={k}>
                                                <td>{a.parking_type.description}</td>
                                                <td>{a.price_type === true ? 'Fragmento' : 'Completa'}</td>
                                                <td align='right'>{a.additional_price.description}</td>
                                                <td>{a.is_recurrent === true ? 'SI' : 'NO'}</td>
                                                <td align='right'>{a.price_formated}</td>
                                                <td align='right'>
                                                    <button className='btn btn-sm btn-info mx-2' onClick={() => onEditRow(a)}><MdEdit /> </button>
                                                    <button className='btn btn-sm btn-danger' onClick={() => onDelete(a.id)}>X</button>
                                                </td>
                                            </tr>)}
                                        </tbody>
                                    </Table>

                                </Col>
                            </Row>

                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <ModalParkingPrice
                formEdit={formEdit}
                show={modal}
                onHide={() => setModal(false)}
                onCreate={onCreate}
                onEdit={onEdit}
                listType={listType}
                list_current={list}
            />
        </TemplateMenu>
    )
}

const mapsState = (props) => {
    let { category, parking } = props

    return {
        fetching_by_id: category.fetching_by_id,
        fetching_delete: category.fetching_delete,
        fetching_price: category.fetching_price,
        row: category.row,
        fetching_type: parking.fetching_type,
        data_type: parking.data_type,
    }
}
export default connect(mapsState, {
    getCategoryById, onCreateOrUpdatePrice, deleteCategoryPrice,
    getParkingType
})(EditConfigParking);