import React, { useContext, useEffect, useRef, useState } from 'react'
import { Badge, Card, Col, Container, Row } from 'react-bootstrap';
import { SocketContext } from '../../context/SocketContext';
import ModalPlate from './modal.plate'
import { getListOrdersActive } from '../../Redux/orderDuck'
import { connect } from 'react-redux';
import { formatNumber } from '../../utils';
import './index.css'
import ItemVdsActive from './item.vds.active';

const VDSPage = ({ getListOrdersActive, fetching_active, row, fetching_list, data, status_id, user }) => {
    const { socket, online } = useContext(SocketContext)
    const [info, setInfo] = useState({ plate: '' })
    const [show, setShow] = useState(false)
    const [listProcess, setListProcess] = useState([])
    const [listFinish, setListFinish] = useState([])
    const buttonRef = useRef(null);
    const [turn, setTurn] = useState({ id: 0 })

    const [initialized, setInitialized] = useState(false);
    const [voices, setVoices] = useState([]);
    const [min, setMin] = useState(0);

    useEffect(() => {
        if (fetching_list === 2) {
            if (status_id === 1) {
                setListProcess(data)
                getListOrdersActive(2, row.id)
            } else {
                setListFinish(data)
            }
        }

    }, [fetching_list])

    useEffect(() => {
        if (fetching_active === 2) {
            if (row !== null) {
                setTurn(row)
                getListOrdersActive(1, row.id)
            }

        }

    }, [fetching_active])

    // Cargar voces al iniciar
    useEffect(() => {
        const loadVoices = () => {
            const availableVoices = window.speechSynthesis.getVoices();
            if (availableVoices.length > 0) {
                setVoices(availableVoices);
            }
        };

        loadVoices();
        window.speechSynthesis.onvoiceschanged = () => {
            console.log("Las voces han cambiado.");
            loadVoices();
        };
    }, []);


    useEffect(() => {
        if (initialized) {
            speakText("La voz ya está activada y lista para usarse.");
        }
    }, [initialized]);

    const initializeSpeech = () => {
        const availableVoices = window.speechSynthesis.getVoices();

        if (availableVoices.length === 0) {
            alert("No hay voces disponibles. Recarga la página e intenta nuevamente.");
            return;
        }

        setVoices(availableVoices);
        setInitialized(true);

        const speech = new SpeechSynthesisUtterance("Voz activada.");
        speech.voice = availableVoices.find(v => v.lang.startsWith("es")) || availableVoices[0];
        window.speechSynthesis.speak(speech);
    };


    const speakText = (text) => {
        if (!window.speechSynthesis) {
            alert("Tu navegador no soporta síntesis de voz.");
            return;
        }

        let synth = window.speechSynthesis;
        let utterance = new SpeechSynthesisUtterance(text);

        utterance.lang = "es-ES";
        utterance.voice = synth.getVoices().find(voice => voice.lang === "es-ES" || voice.lang === "es-MX");

        if (!utterance.voice) {
            console.warn("No se encontró una voz en español.");
        }

        window.speechSynthesis.speak(utterance);

    };

    useEffect(() => {
        if (socket) {

            socket.emit('joinRoom', user.business.id);

            socket.on("created_order", (data) => {
                setListProcess(prevList => [...prevList, { ...data }]);
            });

            socket.on("payment_order", (data) => {
                setListFinish(prevList => prevList.filter(d => d.id !== data.id));
            })

            socket.on("finish_order", (data) => {
                setInfo(data)
                setShow(true)

                if (buttonRef.current) {
                    console.log('llego hacer click');
                    buttonRef.current.click();
                }
                const timer = setTimeout(() => {
                    setShow(false);
                    setListProcess(prevList => prevList.filter(d => d.id !== data.id));
                    setListFinish(prevList => [...prevList, { ...data }]);

                }, 5000);

                return () => clearTimeout(timer);
            });

        }

        return () => {
            if (socket) {
                socket.off("payment_order");
                socket.off("created_order");
                socket.off("finish_order");
            }
        };
    }, [socket]);

    const showAlert = () => {
        console.log('hizo clikc');
        let text = `El vehículo de placa ${info.plate}, esta listo para entregar`;
        console.log('text', text);

        speakText(text);
        // const options = {
        //     body: `Pedido Nuevo. #`,
        //     // icon: logo_patos,
        //     // badge: logo_patos,
        // }
        // const notification = new Notification("Al Agua Patos", options)
        // notification.addEventListener('close', () => console.log('close'))
    }

    // const onFullScreen = (order) => {

    // }

    return (
        <Container fluid>

            <Row>
                <Col>
                    {online ? <span className='text-success'>Conectado</span> : 'Desconectado'}
                </Col>
            </Row>
            <Row className='d-flex justify-content-center my-3'>
                <Col lg={10}>
                    <Row>
                        <Col>
                            <Card border="primary" className='min-height-vds'>
                                <Card.Body>
                                    <Card.Title className='text-center'>En proceso <Badge variant="primary" >{listProcess.length}</Badge></Card.Title>
                                    <Row>
                                        <Col>

                                            {listProcess.length === 0 ?
                                                <Row className='my-3'>
                                                    <Col>
                                                        <h3 className='text-center'>No hay ordenes en Proceso</h3>
                                                    </Col>
                                                </Row>

                                                : listProcess.map((a, b) => <ItemVdsActive key={b} item={a} />)}
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col>
                            <Card border="success" className='min-height-vds'>
                                <Card.Body>
                                    <Card.Title className='text-center'>Listo para Entregar <Badge variant="success" >{listFinish.length}</Badge></Card.Title>
                                    <Row>
                                        <Col>
                                            {listFinish.map((a, b) => <Card key={b}>
                                                <Card.Body>
                                                    <Card.Title>
                                                        <Row>
                                                            <Col>Placa ({a.plate})</Col>
                                                            <Col>{a.created_at}</Col>
                                                        </Row>
                                                    </Card.Title>
                                                    <Row>
                                                        <Col>Dueño</Col>
                                                        <Col>{a.client}</Col>
                                                    </Row>

                                                </Card.Body>
                                            </Card>)}
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Col>

            </Row>
            {!initialized && (
                <button onClick={() => initializeSpeech()}>🔊 Activar voz</button>
            )}

            <button className='btn btn-sm btn-info d-none' ref={buttonRef} onClick={showAlert}>Test Sounds</button>
            {/* <button className='btn btn-sm btn-info mx-3' onClick={onFullScreen}>Fullscreen</button> */}
            <ModalPlate show={show} plate={info.plate} />
        </Container>

    )
}

const mapsState = (props) => {

    let { order, turn, user } = props

    return {
        fetching_active: turn.fetching_active,
        row: turn.row,
        fetching_list: order.fetching_list,
        data: order.data,
        status_id: order.status_id,
        user: user.user
    }
}

export default connect(mapsState, { getListOrdersActive })(VDSPage);
