import React, { useEffect, useState } from 'react'
import TemplateMenu from '../../template/menu.template'
import { Col, Row, Table } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Link, useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { getListInvoice, onCreatePdfFE } from '../../Redux/fetDuck'
import { toogleAlert } from '../../Redux/alertDuck'
import { formatNumber } from '../../utils';
import moment from 'moment';

const API_URL = process.env.REACT_APP_API_URL


const FePage = ({ getListInvoice, fetching_invoice, data_invoice, business, toogleAlert, onCreatePdfFE }) => {
    const history = useHistory()
    const [list, setList] = useState([])
    const [show, setShow] = useState(false)

    useEffect(() => {
        if (fetching_invoice === 2) {
            setList(data_invoice)
            toogleAlert(false)
        }
    }, [fetching_invoice])

    useEffect(() => {
        toogleAlert(true)
        getListInvoice()
    }, [])

    const goToCreate = () => {
        history.push("/fe-create")
    }

    const onCreatePDF = (pdf) => {
        console.log('pdf', pdf);
        onCreatePdfFE(pdf)
    }

    return (
        <TemplateMenu>
            <Row>
                <Col lg={2}><p>Facturación Electronica</p></Col>
                <Col lg={2}>
                    <button className='btn btn-sm btn-success' onClick={goToCreate}>Crear FE - X2</button>
                </Col>
                <Col>
                    <Link className='btn btn-sm btn-info' to={`/fe-manual`}>Crear FE Manual</Link>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Table className='table table-sm table-bordered'>
                        <thead className='thead-dark'>
                            <tr>
                                <th>Documento Cliente</th>
                                <th>Fecha</th>
                                <th>Cliente</th>
                                <th>Prefijo</th>
                                <th>Número</th>
                                <th>Subtotal</th>
                                <th>Total</th>
                                <th>Estado</th>
                                <th>Entorno</th>
                                <th>Archivos</th>
                            </tr>
                        </thead>
                        <tbody>
                            {list.map((d, i) => <tr key={i} className={`${d.state_document_id === 1 ? 'table-success' : 'table-danger'}`}>
                                <td>{d.identification_number}</td>
                                <td>{moment(d.date_issue).format("YYYY-MM-DD h:m A")}</td>
                                <td>{d.customer}</td>
                                <td>{d.prefix}</td>
                                <td>{d.number}</td>
                                <td className='text-right'>{formatNumber(d.subtotal)}</td>
                                <td className='text-right'>{formatNumber(d.total)}</td>
                                <td className='text-right'>{d.state_document_id === 1 ? 'Aceptado' : 'No acepta por por la DIAN'}</td>
                                <td className='text-right'>{d.ambient_id === 2 ? 'Pruebas' : 'Producción'}</td>
                                <td>
                                    <a className='btn btn-sm btn-info mx-2'
                                        target="_blank" href={`http://186.116.33.117:9002/storage/${business.document}/${d.pdf}`}>PDF</a>
                                    {d.type_document_id === 1 &&
                                        <Link className="btn btn-sm btn-warning" to={`/fe-credit-note/${d.id}`}>NC</Link>}
                                    {/* <button className='btn btn-sm btn-danger '>XML</button> */}

                                    {/* <button className='btn btn-sm btn-warning mx-2' onClick={() => onCreatePDF(`${API_URL}/api/fe-document/${d.pdf.replace(".pdf", '')}`)}>
                                        <IoDocumentsSharp />
                                    </button> */}
                                </td>
                            </tr>)}

                        </tbody>
                    </Table>

                </Col>
            </Row>
        </TemplateMenu>
    )
}

const mapsState = (props) => {
    let { fe, user } = props

    return {
        data_invoice: fe.data_invoice,
        fetching_invoice: fe.fetching_invoice,
        business: user.data.business
    }
}
export default connect(mapsState, { getListInvoice, toogleAlert, onCreatePdfFE })(FePage);