import React, { useState } from 'react'
import { Card, Col, Row } from 'react-bootstrap';
import InputField from '../../Components/Widgets/InputField';
import chooseimage from "../../../Images/no_available.png";

const LogoInfoPage = ({ formBusiness, storeLogo, image, updateImage }) => {

    return (
        <Row className='d-flex justify-content-center'>
            <Col lg={5}>
                <Card>
                    <Card.Header>
                        <Row>
                            <Col><Card.Title>Logo</Card.Title></Col>
                            <Col className='d-flex justify-content-end'>

                                {formBusiness.api_token !== '' &&
                                    <button className='btn btn-sm btn-success' onClick={storeLogo}>Guardar</button>}
                            </Col>
                        </Row>
                    </Card.Header>
                    <Card.Body>

                        <Row>
                            <Col className='d-flex justify-content-center'>
                                <div className="form-group">
                                    <label htmlFor="chooseimage" className="uploadfile">
                                        {image !== "" ? (
                                            <img src={image} className="img-fluid" />
                                        ) : (
                                            <img src={chooseimage} className="img-fluid" />
                                        )}
                                    </label>
                                    <input
                                        type="file"
                                        name="photo"
                                        className="form-control"
                                        onChange={updateImage}
                                        id="chooseimage"
                                    />
                                </div>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
            </Col>
        </Row>
    )
}

export default LogoInfoPage;