import React, { useEffect, useState } from 'react'
import TemplateMenu from '../../template/menu.template'
import { Card, Col, Row, Table } from 'react-bootstrap';
import InputField from '../Components/Widgets/InputField';
import { getAllClients } from '../../Redux/clientDuck'
import { getServices } from '../../Redux/serviceDuck'
import { getDataCreate, onCreateInvoiceManual } from '../../Redux/fetDuck'
import { toogleLoader } from '../../Redux/alertDuck'
import { connect } from 'react-redux';
import ModalItemFe from './modal.items';
import { formatNumber, validateInput } from '../../utils';
import { useToasts } from 'react-toast-notifications';
import moment from 'moment';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import Select from 'react-select'
import { MdClose, MdEdit, MdModeEdit } from 'react-icons/md';
import ModalClientFe from '../Payroll/modal.client.fe';

const customStyles = {
    control: (provided) => ({
        ...provided,
        minHeight: "32px",
        fontSize: "14px",
        borderRadius: "4px",
    }),
    valueContainer: (provided) => ({
        ...provided,
        padding: "0 8px",
    }),
    input: (provided) => ({
        ...provided,
        margin: "0",
    }),
};

const FeManualPage = ({ getAllClients, fetching_data_create, data_create, fetching_client, data_clients, getServices,
    fetching_list_services, data_services, getDataCreate, toogleLoader, onCreateInvoiceManual,
    fetching_create_invoice }) => {

    const [form, setForm] = useState({
        'invoice_number': '',
        'prefix': '',
        "date": moment().format("YYYY-MM-DD"),
        "hour": moment().format("hh:mm:ss"),
        "payment_form_id": 2,
        "payment_method_id": 30,
        "customer_id": 0,
        "notes": '',
        "customer": {}
    })

    const [client, setClient] = useState('')
    const [listMunicipalies, setListMunicipalities] = useState([])
    const [listDepartment, setListDepartment] = useState([])
    const [listTypeLiabilites, setListTypeLiabilities] = useState([])
    const [listRegimes, setListRegimes] = useState([])
    const [listTypeOrganizations, setListTypeOrganizations] = useState([])
    const [listTypeDocumentIdentification, setListTypeDocumentIdentification] = useState([])
    const [listPaymentForm, setListPaymentForm] = useState([])
    const [listPaymentMethod, setListPaymentMethod] = useState([])
    const [listClients, setListClients] = useState([])
    const [listOrders, setListOrders] = useState([])
    const [listServices, setListServices] = useState([])
    const [show, setShow] = useState(false)
    const [showClient, setShowClient] = useState(false)
    const [showQuantity, setShowQuantity] = useState(false)
    const [quantity, setQuantity] = useState('')
    const { addToast } = useToasts();
    const history = useHistory();

    useEffect(() => {
        getAllClients()
        getServices({ category_id: 0, allow: 0 })
        getDataCreate()
    }, [])

    useEffect(() => {
        if (fetching_data_create === 2) {
            let { payment_forms, payment_methods, invoice, type_regimes, type_organizations,
                type_document_identifications, prefix,
                departments, type_liabilities } = data_create
            setListPaymentForm(payment_forms)
            setListPaymentMethod(payment_methods)
            setListRegimes(type_regimes)
            setListTypeOrganizations(type_organizations)
            setListTypeDocumentIdentification(type_document_identifications)
            setListDepartment(departments)
            setListTypeLiabilities(type_liabilities)
            setForm({
                ...form,
                "invoice_number": invoice,
                "prefix": prefix
            })
        }
    }, [fetching_data_create])

    useEffect(() => {
        if (fetching_create_invoice === 2) {
            toogleLoader(false)
            history.goBack()
        }

        if (fetching_create_invoice === 3) {
            toogleLoader(false)
        }
    }, [fetching_create_invoice])

    useEffect(() => {
        if (fetching_list_services === 2) {
            setListServices(data_services)

        }
    }, [fetching_list_services])

    useEffect(() => {
        if (fetching_client === 2) {
            setListClients(data_clients)
        }
    }, [fetching_client])

    const onConfirmSelected = (listSelected) => {
        setShow(false)
        setListOrders(listSelected)
    }

    const onCreateInvoiceManualForm = () => {
        let _form = form;
        toogleLoader(true)
        _form.customer = client;
        _form.orders = listOrders
        onCreateInvoiceManual(_form)
    }

    const updateInput = (e) => {
        setForm({
            ...form,
            [e.target.name]: e.target.value
        })
    }

    const onDelete = (item) => {
        let _list = listOrders.filter(d => d.uuid !== item.uuid)
        setListOrders(_list)
    }

    const onSearchSelect = (value) => {

        if (value === null) {
            setClient('')
            return;
        }
        setClient(value)

        let clientSelected = listClients.find(client => client.id === value.id)

        const rules = [
            // { field: "type_organization_id", msg: "Tipo de organización es requerido" },
            // { field: "type_regime_id", msg: "Regimen es requerido" },
            // { field: "municipality_id", msg: "Municipio es requerido" },
            // { field: "type_liability_id", msg: "Responsabilidad es requerido" },
            // { field: "type_document_identification_id", msg: "Tipo de documento es requerido" },
            // { field: "address", msg: "Dirección es requerido" },
            { field: "phone", msg: "Telefono es requerido" },
            { field: "email", msg: "Email es requerido" },
            { field: "dv", msg: "DV es requerido" },
            { field: "document", msg: "Documento es requerido" },
            { field: "name", msg: "Nombres es requerido" },
        ]

        let validate = validateInput(clientSelected, rules)
        if (validate !== null) {
            setShowClient(true)
            return
        }
    }

    const btnEditClient = () => {
        let dep = listDepartment.find(dep => dep.municipalities.find(m => m.id === client.municipality_id))
        setListMunicipalities(dep.municipalities)
        setClient({
            ...client,
            "department_id": dep.id
        })
        setShowClient(true)
    }

    const onEditQuantity = (row) => {
        setShowQuantity(true)
        setQuantity(row.quantity)
    }

    const onSaveRow = (row) => {
        if (quantity > 0) {


            let _list = listOrders.map(d => {
                if (d.uid === row.uid) {
                    d.quantity = quantity;
                    d.line_extension_amount = parseFloat(d.quantity) * parseFloat(d.price);
                    d.taxable_amount = parseFloat(d.quantity) * parseFloat(d.price);

                }
                return d
            })
            setListOrders(_list)
            setShowQuantity(false)
            setQuantity('')
        } else {
            addToast('Cantidad no valida', { appearance: 'error', autoDismiss: true });
        }
    }

    return (
        <TemplateMenu>
            <Row className='d-flex justify-content-center'>
                <Col lg={8}>
                    <Card>
                        <Card.Body>
                            <Card.Title className='text-right'>Factura #{form.prefix} {form.invoice_number}</Card.Title>
                            <Row>
                                <Col>
                                    <div className="form-group">
                                        <label>Cliente</label>
                                        <Select
                                            styles={customStyles}
                                            classNamePrefix="custom"
                                            isClearable={true}
                                            value={client}
                                            onChange={onSearchSelect}
                                            options={listClients}
                                            placeholder="Buscar Cliente" />
                                    </div>
                                </Col>

                                {client !== '' ? <Col>
                                    <Card>
                                        <Card.Body>
                                            <Card.Title>{client.name} <MdModeEdit className='cursor-pointer' onClick={btnEditClient} /> </Card.Title>
                                            <Row>
                                                <Col>Documento</Col>
                                                <Col>{client.document}</Col>
                                            </Row>
                                            <Row>
                                                <Col>Email</Col>
                                                <Col>{client.email}</Col>
                                            </Row>
                                        </Card.Body>
                                    </Card></Col> :
                                    <Col className='d-flex align-items-center justify-content-center'>
                                        <Row >
                                            <Col>
                                                <button className='btn btn-sm btn-info'
                                                    onClick={() => setShowClient(true)}>
                                                    Crear Cliente
                                                </button>
                                            </Col>
                                        </Row>
                                    </Col>}
                            </Row>
                            <Row>
                                <Col>
                                    <div className="form-group">
                                        <label>Forma de pago</label>
                                        <select className='form-control' name="payment_form_id" value={form.payment_form_id} onChange={updateInput}>
                                            <option value={0}>Seleccione</option>
                                            {listPaymentForm.map((a, b) => <option key={b} value={a.id}>{a.name}</option>)}
                                        </select>
                                    </div>
                                </Col>
                                <Col>
                                    <div className="form-group">
                                        <label>Método de Pago</label>
                                        <select className='form-control' name="payment_method_id" value={form.payment_method_id} onChange={updateInput}>
                                            <option value={0}>Seleccione</option>
                                            {listPaymentMethod.map((a, b) => <option key={b} value={a.id}>{a.name}</option>)}
                                        </select>
                                    </div>
                                </Col>
                            </Row>

                            <Row className='mt-4'>
                                <Col>
                                    Agregar Servicios, Productos o Parqueo
                                </Col>
                                <Col className='d-flex justify-content-end'>
                                    <button className='btn btn-sm btn-info' onClick={() => setShow(true)}>Agregar </button>
                                </Col>
                            </Row>

                            <Row className='mt-1'>
                                <Col>
                                    <Table className='table table-sm table-bordered'>
                                        <thead className='thead-dark'>
                                            <tr>
                                                <th>Categoria</th>
                                                <th>Servicio</th>
                                                <th>Unit</th>
                                                <th>Precio</th>
                                                <th>Imp</th>
                                                <th>Desc</th>
                                                <th>Total</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {listOrders.length === 0 ?
                                                <tr>
                                                    <td colSpan={8} className='text-center'>No tienes Registros</td>
                                                </tr> :
                                                listOrders.map((d, k) => <tr key={k}>
                                                    <td>{d.category}</td>
                                                    <td>{d.service}</td>
                                                    <td>
                                                        {showQuantity === true ? <>
                                                            <input
                                                                type='number'
                                                                value={quantity}
                                                                className='form-control'
                                                                onChange={(e) => setQuantity(e.target.value)} />
                                                        </> : d.quantity}

                                                    </td>
                                                    <td align='right'>{d.item.price_formated}</td>
                                                    <td align='right'>{d.tax_value}</td>
                                                    <td align='right'>{0}</td>
                                                    <td align='right'>{formatNumber(parseFloat(d.quantity) * parseFloat(d.item.price) + (parseFloat(d.price) * parseFloat(d.tax_value)))}</td>
                                                    <td>
                                                        {showQuantity === true ? <>
                                                            <button className='btn btn-sm btn-success mx-1' onClick={() => onSaveRow(d)}>Guardar</button>
                                                            <button className='btn btn-sm btn-danger' onClick={() => setShowQuantity(false)}>Cancelar</button>
                                                        </> : <>
                                                            <MdEdit className='mx-2 cursor-pointer' onClick={() => onEditQuantity(d)} />
                                                            <MdClose className='cursor-pointer' onClick={() => onDelete(d)} /></>}

                                                    </td>
                                                </tr>)}
                                        </tbody>
                                        {listOrders.length > 0 &&
                                            <tfoot>
                                                <tr>
                                                    <td rowSpan={4} colSpan={5}>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Subtotal</td>
                                                    <td className='text-right'>{formatNumber(listOrders.reduce((a, b) => a + (parseFloat(b.quantity) * parseFloat(b.price)), 0))}</td>
                                                </tr>
                                                <tr>
                                                    <td>Descuentos</td>
                                                    <td className='text-right'>{0}</td>
                                                </tr>
                                                <tr>
                                                    <th>TOTAL</th>
                                                    <th className='text-right'>{formatNumber(listOrders.reduce((a, b) => a + (parseFloat(b.price) * parseFloat(b.quantity)), 0))}</th>
                                                </tr>
                                            </tfoot>}
                                    </Table>
                                </Col>
                            </Row>

                            <Row>
                                <Col>
                                    <div className="form-group">
                                        <label>Nota</label>
                                        <textarea className='form-control' name='notes' onChange={updateInput} placeholder='Notas'></textarea>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col className='text-center'>
                                    <button className='btn btn-sm btn-success' onClick={onCreateInvoiceManualForm}
                                        disabled={listOrders.length === 0}>Facturar</button>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <ModalItemFe
                show={show}
                listServices={listServices}
                onHide={() => setShow(false)}
                onConfirmSelected={onConfirmSelected} />
            <ModalClientFe
                show={showClient}
                client={client}
                onHide={() => setShowClient(false)}
                onUpdateMunicipalities={(municipalities) => setListMunicipalities(municipalities)}
                listTypeDocumentIdentification={listTypeDocumentIdentification}
                listMunicipalies={listMunicipalies}
                listDepartment={listDepartment}
                listTypeLiabilites={listTypeLiabilites}
                listRegimes={listRegimes}
                listTypeOrganizations={listTypeOrganizations}
                onCancel={() => {
                    setClient('')
                    setShowClient(false)
                }}
                onSuccess={(value) => {
                    let _value = value
                    _value.label = `${value.name} (${value.phone})`
                    _value.value = value.id
                    setClient(_value)
                    setShowClient(false)
                }}
            />
        </TemplateMenu>
    )
}

const mapsState = (props) => {
    let { fe, client, service } = props


    return {
        fetching_data_create: fe.fetching_data_create,
        data_create: fe.data_create,
        fetching_client: client.fetching_client,
        data_clients: client.clients,
        fetching_order: fe.fetching_order,
        data_order: fe.data_order,
        fetching_create_invoice: fe.fetching_create_invoice,
        fe_errors: fe.errors,
        fetching_list_services: service.fetching_list,
        data_services: service.data
    }
}
export default connect(mapsState, {
    getAllClients, getServices, getDataCreate, toogleLoader,
    onCreateInvoiceManual
})(FeManualPage);