import React from 'react'
import { Card, Col, Container, Row } from 'react-bootstrap';
import { Bar } from 'react-chartjs-2';
import { formatNumber } from '../../../utils';



const options = {
    plugins: {
        legend: {
            display: true, // Muestra la leyenda
            position: 'top'
        },
        datalabels: {
            formatter: (value, context) => {
                const total = context.dataset.data.reduce((acc, val) => acc + val, 0);
                const percentage = ((value / total) * 100).toFixed(1) + '%';
                return percentage; // Muestra el porcentaje en cada sector
            },
            color: '#5b5b5c', // Color del texto
            font: {
                size: 16,
                weight: 'bold'
            }
        }
    }
};

const ReviewTotalExpense = ({ graph, total_expense, total_payroll }) => {
    return (
        <Container fluid>
            <Row>

                <Col>
                    {graph.labels.length > 0 ? (
                        <Bar data={graph} options={options} />
                    ) : (
                        <p>Cargando datos...</p>
                    )}

                </Col>
                <Col>
                    <Row className='mt-5 pt-5'>
                        <Col>
                            <Card className='bg-warning-custom' >
                                <Card.Body>
                                    <Card.Title>TOTAL GASTOS OPERATIVOS</Card.Title>
                                    <h4>{formatNumber(total_expense)}</h4>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col>
                            <Card className='bg-primary-custom'>
                                <Card.Body>
                                    <Card.Title>TOTAL NOMINA</Card.Title>
                                    <h4>{formatNumber(total_payroll)}</h4>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                    <Row className='mt-3'>
                        <Col>
                            <Card className='bg-danger-custom'>
                                <Card.Body>
                                    <Card.Title>TOTAL GASTOS</Card.Title>
                                    <h4>{formatNumber(total_payroll + total_expense)}</h4>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Container>
    )
}

export default ReviewTotalExpense;